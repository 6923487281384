import { Box, GRID_CHECKBOX_SELECTION_COL_DEF, Grid } from "@component/UIComponents"
import { useArchivePurchase, useGetFilterPurchases, useGetPurchases, usePurchaseMenu, useSelectionPurchase } from "./hooks/usePurchase"
import { useTranslation } from "react-i18next"
import { usePanel } from "@hook/usePanel"
import { currencyFormat } from "@util/currencyFormat"
import ActionsList from "@component/ActionsList"
import { useURLParams } from "@hook/useURLParams"
import { useMemo, useState } from "react"
import PurchasesList from "./components/PurchasesList"
import Filters from "./components/Filters"
import ListRendering from "@component/ListRendering"
import PurchasePaymentWindow from "./components/PurchasePaymentWindow"
import Invoice from "./components/Invoice"
import PaymentManagement from "./components/PaymentManagement"
import PaymentSchedule from "../PaymentSchedule"
import UpdateDueDate from "./components/UpdateDueDate"
import { usePurchaseController } from "./hooks/usePurchaseController"

const Purchases = () => {
    const { purchaseReportFilters, setPurchaseReportFilters, getQueryFilter, getConfigurationFilters } = usePurchaseController()
    const { groupBy } = getConfigurationFilters
    const [showPaymentSchedulingWindow, setShowPaymentSchedulingWindow] = useState(false)
    const [updateExpirationDate, setUpdateExpirationDate] = useState(null)
    const [invoiceSelected, setInvoiceSelected] = useState(null)
    const [selectedInvoiceToViewPayments, setSelectedInvoiceToViewPayments] = useState(null)

    const { getDateFormats } = usePanel()
    const { delQueries } = useURLParams()
    const { t } = useTranslation()
    const { modal } = useGetFilterPurchases()
    const { purchases, isFetching, groupResults } = useGetPurchases({...getQueryFilter})
    const { archivePurchase } = useArchivePurchase()
    const archivedPurchase = (props) => archivePurchase({archived: !(props?.isArchived??false), purchases: [(props?._id??null)]})

    const { actionsPurchase, invoiceActions } = usePurchaseMenu()
    const { selectionHandler, populatedSelectedInvoicesForProvider } = useSelectionPurchase()

    const Buttons = (props={}) => <ActionsList
        componentProps={{
            menuMobile: {
                props: {
                    menuTitle: `${(props?.provider?.name??"")} - #C${(props?.consecutive??0)}`,
                }
            }
        }}
        variant="mobileMenu"
        options={invoiceActions(props, {setInvoiceSelected, archivedPurchase, viewInvoicePayments: (purchaseId) => setSelectedInvoiceToViewPayments(purchaseId) })}
    />
    
    const columns = useMemo(() => [
        {
            flex: 1,
            minWidth: 150,
            field: "provider",
            headerName: "Proveedor",
            renderCell: ({row}) => <Box display={'flex'} alignItems={'center'} gap={1} >
                <Buttons {...row} />
                {`${row?.provider?.name??""}`}
            </Box>
        },
        {maxWidth: 80, minWidth: 80, field: "consecutive", headerName: "#C"},
        {maxWidth: 80, minWidth: 80, field: "invoiceNumber", headerName: "F#", renderCell: ({row}) => `${row?.invoiceNumber??""}` },
        {maxWidth: 80, minWidth: 80, field: "typePayment", headerName: "Tip. de pago", renderCell: ({row}) => `${t((row?.typePayment??""))}` },
        {maxWidth: 100, minWidth: 100, field: "dateReceived", headerName: "Fec. Recibido", renderCell: ({row}) => `${getDateFormats((row?.dateReceived??"")).ddmmyyyy}` },
        {maxWidth: 100, minWidth: 100, field: "dueDate", headerName: "Fec. Vencimiento", renderCell: ({row}) => `${getDateFormats((row?.dueDate??"")).ddmmyyyy}` },
        {maxWidth: 150, minWidth: 150, field: "subiva", headerName: "Sub+IVA", renderCell: ({row}) => `${currencyFormat(((row?.subTotal??0)+(row?.tax??0)))}` },
        {maxWidth: 120, minWidth: 120, field: "reteFuente", headerName: "Retención en la fuente", renderCell: ({row}) => `${currencyFormat((row?.reteFuente??0))}` },
        {maxWidth: 150, minWidth: 150, field: "total", headerName: "Total", renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
        {maxWidth: 150, minWidth: 150, field: "balance", headerName: "Por pagar", renderCell: ({row}) => `${currencyFormat((row?.balance??0))}`},
        {maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`},
        {...GRID_CHECKBOX_SELECTION_COL_DEF},
    ], [getDateFormats, t])

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        componentProps={{
                            filter: {
                                active: true,
                                props: {
                                    component: () => <Filters {...purchaseReportFilters} setPurchaseReportFilters={setPurchaseReportFilters} />,
                                    filters: purchaseReportFilters,
                                }
                            }
                        }}
                        options={actionsPurchase({
                            onGeneratePaymentSchedule: () => setShowPaymentSchedulingWindow(true)
                        })}
                    />
                </Grid>
                <ListRendering
                    elements={ Boolean(groupBy) ? groupResults.map((n) => (n?.results??[])) : [purchases]}
                    component={(_, index) => {
                        let list = Boolean(groupBy) ? (groupResults[index]?.results??[]) : purchases
                        return(
                            <Grid xs={12} >
                                <PurchasesList
                                    onRowSelectionChange={(api) => selectionHandler(api, index)}
                                    title={(groupResults[index]?.groupName??"")}
                                    columns={columns}
                                    list={list}
                                    loading={isFetching}
                                />
                            </Grid>
                        )
                    }}
                />
                { Boolean((modal === "paymentWindow")) &&
                    <PurchasePaymentWindow open={Boolean((modal === "paymentWindow"))} onClose={() => delQueries(["modal"])} list={populatedSelectedInvoicesForProvider} />
                }
                <Invoice onUpdateDueDate={(purchaseId) => setUpdateExpirationDate(purchaseId)} viewInvoicePayments={(purchaseId) => setSelectedInvoiceToViewPayments(purchaseId)} open={Boolean(invoiceSelected)} purchaseId={invoiceSelected} onClose={() => setInvoiceSelected(null)} />
                <PaymentManagement open={Boolean(selectedInvoiceToViewPayments)} onClose={() => setSelectedInvoiceToViewPayments(null)} purchaseId={selectedInvoiceToViewPayments} />
                <PaymentSchedule
                    purchaseFilters={getQueryFilter}
                    open={showPaymentSchedulingWindow}
                    onClose={() => setShowPaymentSchedulingWindow(false)}
                />
                <UpdateDueDate purchaseId={updateExpirationDate} open={Boolean(updateExpirationDate)} onClose={() => setUpdateExpirationDate(null)} />
            </Grid>
        </>
    )
}

export default Purchases