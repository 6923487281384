import { useCallback, useState } from "react"

export const useControllerCategories = () => {
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [showCreateCategoryForm, setShowCreateCategoryForm] = useState({ category: null, open: false })

    const closeCategoryRegistrationForm = useCallback(() => {
        setShowCreateCategoryForm({ category: null, open: false })
    }, [])

    return {
        selectedCategory,
        setSelectedCategory,
        showCreateCategoryForm,
        setShowCreateCategoryForm,
        closeCategoryRegistrationForm
    }
}