import ActionsList from "@component/ActionsList"
import { Grid, Typography } from "@component/UIComponents"
import SuperDataGrid from "@component/SuperDataGrid"
import { usePanel } from "@hook/usePanel"
import { useTranslation } from "react-i18next"
import { currencyFormat } from "@util/currencyFormat"
import { useMovements, useMovementsMenus } from "@hook/useMovements"
import CashBalance from "./CashBalance"
import dayjs from "dayjs"
import { useState, useRef } from "react"
import PayFixedExpense from "@feature/AccountsPayable/FixedCosts/components/PayFixedExpense"
import { useGetCashMovements } from "../hooks/useMasterBox"
import BoxesForm from "@feature/Boxes/components/BoxesForm"
import BankForm from "@feature/Banks/components/BankForm"
import MovementReceipt from "@component/MovementReceipt"

const BoxesTemplate = ({ variant="mainBox", refId=undefined, boxName="", boxBalance=0 }) => {
    const [ showFixedExpensePaymentWindow, setShowFixedExpensePaymentWindow ] = useState(false)
    const [ showPocketsForm, setShowPocketsForm ] = useState(false)
    const [ showBankForm, setShowBankForm ] = useState(false)
    const { getDateFormats, getStartDateAndEndDate } = usePanel()
    const { startDate, endDate } = getStartDateAndEndDate
    const receiptRef = useRef()

    const { cashMovements, quickList, isFetching } = useGetCashMovements({ startDate, endDate, ref: refId })

    const { t } = useTranslation()
    const { balance } = useMovements({ variant, filters: { startDate: endDate, box: refId } })
    const { primaryMenu, secondaryMenu } = useMovementsMenus({ variant, receiptRef })

    const columns = [
        {
            maxWidth: 50,
            minWidth: 50,
            field: " ",
            headerName: " ",
            renderCell: ({ row }) => <ActionsList variant="mobileMenu" options={secondaryMenu(row)} />
        },
        {
            maxWidth: 140,
            minWidth: 140,
            field: "user",
            headerName: "Registrado por",
            renderCell: ({row}) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}`
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "from",
            headerName:
            "Desde",
            renderCell: ({ row }) => t((row?.from??row?.box??""))},

        {
            maxWidth: 150,
            minWidth: 150,
            field: "for",
            headerName: "Destino",
            renderCell: ({ row }) => t(`${(row?.for??row?.movement??"")}`)
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "amount",
            headerName: "Monto",
            renderCell: ({ row }) => <Typography fontSize={'.830rem'} sx={{ color: (theme) => (row?.financialState??"") === "asset" ? theme.palette.success.main : theme.palette.error.main }} > {currencyFormat((row?.amount??0))} </Typography>
        },
        {
            flex: 1,
            minWidth: 250,
            field: "note",
            headerName: "Nota",
            renderCell: ({ row }) => `${t((row?.note??""))}`
        },
        {
            maxWidth: 140,
            minWidth: 140,
            field: "createdAt",
            headerName: "Fecha de registro",
            renderCell: ({ row }) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`
        },
    ]

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={primaryMenu({
                            onPayFixedExpenses: () => setShowFixedExpensePaymentWindow(true),
                            onCreatePocket: () => setShowPocketsForm(true),
                            onCreateBank: () => setShowBankForm(true)
                        }, {
                            variantBox: variant,
                            fixedBox: refId,
                            boxBalance,
                        })} />
                </Grid>
                <Grid xs={12} >
                    <CashBalance primaryBalance={(balance?.prevAmount??0)} primaryDate={dayjs(endDate).subtract(1, "day").toDate()} secondaryBalance={(balance?.amount??0)} secondaryDate={dayjs(startDate).toDate()} />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        onQuickList={quickList}
                        sd={dayjs(startDate)}
                        ed={dayjs(endDate)}
                        dateRange
                        title={'Movimientos'}
                        columns={columns}
                        rows={cashMovements}
                        loading={isFetching}
                        filterBy={(param) => `${t((param?.box??""))} ${t((param?.from??""))} ${t((param?.for??""))} ${t((param?.movement??""))} ${(param?.amount??0)} ${(param?.note??"")} `}
                    />
                </Grid>
            </Grid>

            <PayFixedExpense
                open={showFixedExpensePaymentWindow}
                onClose={() => setShowFixedExpensePaymentWindow(false)}
                boxName={boxName}
                boxRef={refId}
                isBank={variant === "bank" ? "bank" : ""}
                maxPayment={boxBalance}
            />
            <BoxesForm
                open={showPocketsForm}
                onClose={() => setShowPocketsForm(false)}
            />
            <BankForm
                open={showBankForm}
                onClose={() => setShowBankForm(false)}
            />
            <MovementReceipt
                ref={receiptRef}
            />
        </>
    )
}

export default BoxesTemplate