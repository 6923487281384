import { Card, CardContent, Container, Stack } from '@component/UIComponents'
import AuthFormHeaderText from './AuthFormHeaderText'
import { useEffect } from 'react'

const AuthContainerForm = ({ children, title=undefined, subTitle="",  }) => {
    useEffect(() => {
        const elem = document.getElementById('root')
        if( Boolean(elem) ){
            elem.style.backgroundColor = "#F5F5F5"
        }
        return () => {
            if( Boolean(elem) ){
                elem.style.backgroundColor = "#FFFFFF"
            }
        }
    }, [])
    return(
        <Container maxWidth='sm' sx={{ width: '100%', height: '100%' }} >
            <Stack width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} >
                <Card sx={{ width: '100%' }} variant="outlined" >
                    <CardContent>
                        <AuthFormHeaderText title={title} subTitle={subTitle} />
                        {children}
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    )
}

export default AuthContainerForm