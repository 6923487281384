import { preventAction } from "@util/helpers"
import { routes } from "@util/routes"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

export const useControllerOrderList = (props={}) => {
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const [orderIdentifier , setOrderIdentifier] = useState("")
    const { ordersToSuppliers, enterPurchase } = routes
    const navigate = useNavigate()

    const openMenu = useCallback((target, order) => {
        setMenuAnchorEl(target)
        setSelectedOrder(order)
    }, [])

    const closeMenu = useCallback(() => {
        setMenuAnchorEl(null)
        setSelectedOrder(null)
    }, [])

    const selectOrderIdentifier = useCallback(() => {
        if( Boolean((selectedOrder?._id)) ){
            setOrderIdentifier((selectedOrder?._id))
            closeMenu()
        }
    }, [selectedOrder, closeMenu])

    const deselectOrderIdentifier = useCallback(() => {
        setOrderIdentifier("")
    }, [])

    const editOrderOrder = useCallback(() => {
        if( Boolean(selectedOrder?._id) ){
            navigate(ordersToSuppliers({ orderPurchaseId: (selectedOrder?._id??"") }))
            closeMenu()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, selectedOrder, closeMenu])

    const convertOrderToPurchase = useCallback(() => {
        if( Boolean(selectedOrder?._id) ){
            let payload = {...selectedOrder}
            payload.new = true
            payload.orderId = payload._id
            delete payload._id

            preventAction({
                icon: "warning",
                text: `¿Esta seguro que desea convertir el pedido #P${(selectedOrder?.consecutive??"")} realizado a ${(selectedOrder?.provider?.name??"")} en una compra?`,
                onSubmit: () => navigate(enterPurchase(""), {state: { order: payload }})
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrder, navigate])

    return {
        openMenu,
        selectedOrder,
        menuAnchorEl,
        closeMenu,
        selectOrderIdentifier,
        deselectOrderIdentifier,
        orderIdentifier,
        editOrderOrder,
        convertOrderToPurchase
    }
}