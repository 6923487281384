import { Box, Card, CardContent, Divider, Grid, Tooltip, TrendingDownRoundedIcon, TrendingUpRoundedIcon, Typography } from '@component/UIComponents'
import { useBusinessBalance } from '@feature/Business/hooks/useBusiness'
import { currencyFormat } from '@util/currencyFormat'
import { useTranslation } from 'react-i18next'

const CardBalance = ({ label="", icon=() => <></>, amount=0, palette="success", details=[] }) => {
    const color = () => ({ color: (theme) => theme.palette[palette].main })
    const { t } = useTranslation()

    const Details = ({ value=[] }) => {
        return(
            <>
                <Box fontSize={'1rem'} >Detalles: </Box>
                {value.map((item, index) => {
                    return(
                        <Box key={index} fontSize={'1rem'} >
                            <Box> {t((item?._id??""))}: {currencyFormat((item?.total??0))} </Box>
                        </Box>
                    )
                })}
            </>
        )
    }

    const DetailsTooltip = ({ value=[], children }) => {
        if( value.length >= 1 ){
            return(
                <Tooltip title={<Details value={value} />} arrow >
                    {children}
                </Tooltip>
            )
        }else{
            return(
                <>
                    {children}
                </>
            )
        }
    }

    return(
        <DetailsTooltip value={details} >
            <Card sx={{ width: '100%', cursor: "pointer" }} variant='outlined' elevation={0} >
                <CardContent>
                    <Grid container >
                        <Grid xs={12}>
                            <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} > {label} </Typography>
                            <Divider />
                        </Grid>
                        <Grid xs={12}>
                            <Box display={'flex'} alignItems={'center'} gap={1} mt={2} justifyContent={'center'} >
                                {icon({...color()})}
                                <Typography fontWeight={'400'} fontSize={'1.7rem'} sx={{ ...color() }} > { currencyFormat(amount) } </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </DetailsTooltip>
    )
}

const FinancialState = () => {
    const { assets, liabilities, balance, liabilitiesDetails, assetsDetails } = useBusinessBalance()
    return(
        <>
            <Box display={'flex'} width={'100%'} gap={2} justifyContent={'center'} >
                <CardBalance
                    label='Activos'
                    icon={ (props) => <TrendingUpRoundedIcon sx={{...props}} />}
                    amount={assets}
                    details={assetsDetails}
                />
                <CardBalance
                    palette='primary'
                    label='Balance general'
                    amount={balance}
                />
                <CardBalance
                    palette='error'
                    label='Pasivos'
                    icon={ (props) => <TrendingDownRoundedIcon sx={{...props}} />}
                    amount={liabilities}
                    details={liabilitiesDetails}
                />
            </Box>
        </>
    )
}

export default FinancialState