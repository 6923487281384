import { useMemo, useState } from "react"
import RegisterForm from "../components/RegisterForm"
import BusinessForm from "@feature/Business/components/BusinessForm"
import EndingRegistration from "../components/EndingRegistration"

export const useRegisterController = () => {
    const [ activeStep, setActiveStep ] = useState(0)
    const [ formInformation, setFormInformation ] = useState({ user: null, business: null })

    const steps = useMemo(() => ([
        {
            label: "Registrar Información del usuario",
            component: <RegisterForm 
                isNew={true}
                getFormInformation={(val)=>{
                    setFormInformation((prevState) => ({...prevState, user: val}))
                    setActiveStep((prevState) => (prevState+1))
                }}
                defaultValues={(formInformation?.user??null)}
            />
        },
        {
            label: "Registrar Información del negocio",
            component: <BusinessForm
                isNew={true}
                getFormInformation={(val)=>{
                    setFormInformation((prevState) => ({...prevState, business: val}))
                    setActiveStep((prevState) => (prevState+1))
                }}
                defaultValues={(formInformation?.business??null)}
                onClickSecondaryButton={() => setActiveStep((prevState) => (prevState-1))}
            />
        },
        {
            label: "Finalizando registro",
            component: <EndingRegistration
                payload={formInformation}
                onGoBack={() => setActiveStep((prevState) => (prevState-1)) }
                clearData={() => setFormInformation({ user: null, business: null }) }
            />
        },
    ]), [setActiveStep, formInformation])

    const stepValue = useMemo(() => (steps[activeStep]), [steps, activeStep])

    return {
        steps,
        activeStep,
        setActiveStep,
        stepValue,
    }
}