import { api, mutationConfig } from "../splitApi";
import { BoxTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerNewPocket: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/new/pocket", body };
            },
            invalidatesTags: [...BoxTag]
        }),
        registerCashTransfer: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/cash/transfer", body };
            },
            invalidatesTags: [...BoxTag]
        }),
        registerNewBank: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/new/bank", body };
            },
            invalidatesTags: [...BoxTag]
        }),
        getMasterBox: build.query({
            query: (params=undefined) => ({
                url: `/get/master/box`,
                params
            }),
            providesTags: BoxTag
        }),
        getDynamicBoxes: build.query({
            query: (params=undefined) => ({
                url: `/get/dynamic/boxes`,
                params
            }),
            providesTags: BoxTag
        }),
        getBanks: build.query({
            query: (params=undefined) => ({
                url: `/get/banks`,
                params
            }),
            providesTags: BoxTag
        }),
        getCashMovements: build.query({
            query: (params=undefined) => ({
                url: `/get/cash/movements`,
                params
            }),
            providesTags: BoxTag
        }),
        getCashBalances: build.query({
            query: (params=undefined) => ({
                url: `/get/cash/balances`,
                params
            }),
            providesTags: BoxTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetMasterBoxQuery,
    useGetCashMovementsQuery,
    useGetDynamicBoxesQuery,
    useGetCashBalancesQuery,
    useGetBanksQuery,
    useRegisterCashTransferMutation,
    useRegisterNewPocketMutation,
    useRegisterNewBankMutation
} = Endpoints;
