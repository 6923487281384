import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, EditIconRounded, SettingsRoundedIcon } from "@component/UIComponents"
import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useArchiveEmployeeMutation, useGetCashiersEmployedQuery, useGetEmployeeQuery, useGetEmployeesQuery, useRegisterEmployeeMutation, useUpdateEmployeeSettingsMutation } from "@query/Employees"
import { asynchronousResponseHandler, preventAction } from "@util/helpers"
import { routes } from "@util/routes"
import { useMemo } from "react"
import { useCallback } from "react"

export const useGetEmployees = () => {
    const { data, isFetching } = useGetEmployeesQuery()
    const employees = (data?.payload??[])

    return {
        employees,
        isFetching
    }
}

export const useGetEmployee = (filter={}, config={}) => {
    const { data, isFetching } = useGetEmployeeQuery(filter, config)
    let employee = (data?.payload??null)
    employee = (filter?.employeeId??null) ? employee : null
    return {
        employee,
        isFetching
    }
}

export const useEmployeesMenu = () => {
    const primaryMenu = useCallback((props={}, config={}) => {
        const { _id=null, isArchived=false, name="", lastName="" } = props
        const { onEditEmployee=()=>null, onArchivedEmployee=()=>null, onEmployeeSettings=()=>null } = config

        return [
            {
                title: "Editar",
                onClick: () => onEditEmployee(_id),
                icon: <EditIconRounded />
            },
            {
                title: isArchived ? "Recuperar" : "Archivar",
                onClick: () => preventAction({
                    text: isArchived ? `¿Desea recuperar el empleado ${name} ${lastName}?` : `¿Desea archivar el empleado ${name} ${lastName}?`,
                    onSubmit: () => onArchivedEmployee({employeeId: _id, archived: !isArchived})
                }),
                icon: <ArchiveRoundedIcon />
            },
            // {
            //     title: "Ajustes de nomina",
            //     onClick: () => null,
            //     icon: <PaymentsRoundedIcon />
            // },
            {
                title: "Ajustes del empleado",
                onClick: () => onEmployeeSettings(_id),
                icon: <SettingsRoundedIcon />
            },
        ]
    }, [])

    const secondaryMenu = useCallback((props={}) => {
        const { onCreateEmployee=()=>null } = props
        return [
            {
                title: "Crear nuevo empleado",
                onClick: () => onCreateEmployee(),
                icon: <AddCircleOutlineIconRounded />
            },
            // {
            //     title: "Nomina",
            //     onClick: () => null,
            //     icon: <></>
            // },
            // {
            //     title: "Caja",
            //     onClick: () => null,
            //     icon: <></>
            // },
        ]
    }, [])

    return {
        primaryMenu,
        secondaryMenu
    }
}

export const useEmployeeOptions = () => {
    
    const homePages = useCallback(() => {
        return [
            {name: "Panel", value: routes.home()},
            {name: "Ventas", value: routes.sales()},
            {name: "Reporte de compras", value: routes.purchaseReport()},
            {name: "Clientes", value: routes.customers()},
            {name: "Proveedores", value: routes.providers()},
            {name: "Equipo", value: routes.employees()},
            {name: "Inventario", value: routes.inventory()},
            {name: "Informes", value: routes.report("sale")},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])

    return {
        homePages
    }
}

export const useEmployeesFormData = (props={}) => {
    const { defaultValues=undefined } = props
    const initialValues = useMemo(() => ({ name: "", lastName: "", numberPhone: "", email: "" }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del empleado es requerido."),
            email: yup.string().email("El correo electrónico no tiene un formato valido."),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useEmployeesSettingFormData = (props={}) => {
    const { defaultValues=undefined } = props
    const initialValues = useMemo(() => ({ cashier: false, status: "locked", rol: null, password: "", homePage: "/" }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            status: yup.string().oneOf(['locked', 'active']).required("El estado del empleado es requerido."),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useRegisterEmployee = () => {
    const [registerEmployeeMutation, { isLoading }] = useRegisterEmployeeMutation()

    const registerEmployee = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerEmployeeMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean(payload?._id??null) ?
                    "El empleado se actualizo exitosamente."
                    :
                    "El empleado se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerEmployee,
        isLoading
    }
}

export const useUpdateEmployeeSettings = () => {
    const [updateEmployeeSettingsMutation, { isLoading }] = useUpdateEmployeeSettingsMutation()

    const updateEmployeeSettings = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await updateEmployeeSettingsMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "Los ajustes del empleado se actualizaron exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updateEmployeeSettings,
        isLoading
    }
}

export const useArchiveEmployee = () => {
    const [archiveEmployeeMutation, { isLoading }] = useArchiveEmployeeMutation()

    const archiveEmployee = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await archiveEmployeeMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: !Boolean(payload?.archived??null) ?
                    "El empleado se recupero exitosamente."
                    :
                    "El empleado se archivo exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archiveEmployee,
        isLoading
    }
}

export const useGetCashiersEmployed = () => {
    const { data, isFetching } = useGetCashiersEmployedQuery()
    const cashiers = (data?.payload??[])
    return {
        cashiers,
        isFetching
    }
}