import { useGetUserSessionInformationQuery } from "@query/Users"
import { Toast } from "@util/swal"

export const useGetUserSessionInformation = () => {
    const { data, isFetching, isSuccess } = useGetUserSessionInformationQuery()

    let userData = (data?.payload??null)
    const balance = (userData?.balance??0)
    const email = (userData?.email??"")
    const lastConnection = (userData?.lastConnection??"")
    const fullName = `${(userData?.name??"")} ${(userData?.lastName??"")}`
    const rolName = (userData?.rol?.name??"")
    const rolPermissions = (userData?.rol?.permissions??"")
    const permissions = (userData?.rol?.permissions??[])

    if( ((userData?.dictionaryKey??"") === "expiredToken") && (userData?.type??"") === "customError" ){
        Toast.fire({
            icon: "error",
            text: "Su sesión expiro vuelva a iniciar sesión en la app",
        })
        localStorage.removeItem("access_token")
        setTimeout(() => {
            window.location.reload(true)
        }, 3000)
    }

    return {
        userData,
        isFetching,
        balance,
        email,
        fullName,
        rolName,
        permissions,
        lastConnection,
        rolPermissions,
        isSuccess
    }
}