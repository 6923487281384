import { Box, DataGrid, Stack, styled, Divider, Portal } from '@component/UIComponents'
import { GRID_DEFAULT_LOCALE_TEXT } from '@util/localeDataGrid'
import Title from './components/Title'
import DateRange from './components/DateRange'
import SearchBar from './components/SearchBar'
import ActionWithData from './components/ActionWithData'
import { useSearchDataSuperDataGrid, useSuperDataGrid, useDateRange } from './hooks/useSuperDataGrid'
import FieldSelection from './components/FieldSelection'
import { memo, useMemo, useState } from 'react'
import QuickList from '@component/QuickList'
import ActionsList from '@component/ActionsList'

const DataGridStyled = styled(DataGrid, {
    shouldForwardProp: (prop) => prop !== "fixedCells" && prop !== "hideHeader"
})(({ theme, fixedCells, hideHeader }) => ({
    borderRight: "none",
    borderLeft: "none",
    borderRadius: "0px",
    ...(hideHeader ? {
        '& .MuiDataGrid-columnHeaders': {
            display: "none"
        }
    } : {
        '& .MuiDataGrid-columnHeaders': {
            minHeight: "35px !important",
            maxHeight: "35px !important"
        },
    }),
    '& .MuiDataGrid-cell': {
        fontSize: '.790rem'
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-row:hover .MuiDataGrid-cell:focus': {
        outline: 'none',
        border: 'none',
    },
    ...(fixedCells ? {
        '& .MuiDataGrid-cell': {
            '&:first-of-type': {
                position: 'sticky',
                left: 0,
                zIndex: 1,
                backgroundColor: theme.palette.background.paper
            },
        }
    } : {}),
    '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey.A100,
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: theme.palette.background.paper,
    },
}))

const SuperDataGrid = ({ portalId=undefined, quickListPosition="top", onDateRangeChange=undefined, onDownloadBook=undefined, hideHeader=false, columnBuffer=2, disableVirtualization=undefined, menu=()=>([]), offspring=()=><></>, onQuickList=()=>null, processRowUpdate=undefined, dataGridRef=undefined, rowSelectionModel=undefined, onRowSelectionModelChange=undefined, fixedCells=false, onRowsDataMutation=()=>null, pageSize=undefined, sd = undefined, ed = undefined, loading = undefined, sx = undefined, getCellClassName = undefined, onCellEditStart = undefined, onCellEditStop = undefined, checkboxSelection = undefined, height = undefined, dateRange = undefined, disableColumnMenu = true, title = undefined, autoHeight = true, downloadData = false, uploadData=false, fileName = undefined, excludeFieldsToFile = [], rowHeight = 30, rows = [], columns = [], toolbar = () => <></>, useSearchBar = true, filterBy = () => "", columnVisibilityModel = undefined }) => {
    const [windowsStatus, setWindowsStatus] = useState({ downloadBook: false })
    const { downloadBook } = windowsStatus
    const { currentRows, setTextMatch, textMatch } = useSearchDataSuperDataGrid({ rows, filterBy, onRowsDataMutation  })
    const { currentColumns=[], numRows, pageSizeOptions, tableHeight, externalHeight } = useSuperDataGrid({ columns, pageSize, rowHeight, autoHeight, height, title: Boolean(title) })
    const { handleDateRangeChange, setLinkDate, linkDate } = useDateRange({onDateRangeChange})

    const quickList = useMemo(() => {
        if( Boolean(onQuickList) ){
            return onQuickList(currentRows)
        }else{
            return []
        }
    }, [onQuickList, currentRows])

    const Toolbar = () => {
        return(
            <Stack>
                <Box px={2} pt={1} gap={1} >
                    { Boolean(portalId) ?
                        <Portal container={() => document.getElementById(portalId)}>
                            <ActionWithData
                                uploadData={uploadData}
                                downloadData={downloadData}
                                onDownloadInformation={() => setWindowsStatus((prevState) => ({...prevState, downloadBook: true}))}
                            />
                        </Portal>
                        :
                        <ActionWithData
                            uploadData={uploadData}
                            downloadData={downloadData}
                            onDownloadInformation={() => setWindowsStatus((prevState) => ({...prevState, downloadBook: true}))}
                        />
                    }
                    {toolbar()}
                </Box>
            </Stack>
        )
    }

    return (
        <>
        <Box height={externalHeight} >
            <Box position="relative" width={'100%'} {...tableHeight} >
                <Divider />
                {title !== undefined && <Title title={title} />}

                <Box display={'flex'} gap={1} alignItems={'center'} my={1} >
                    {Boolean(dateRange) && <DateRange ed={ed} sd={sd} linkDate={linkDate} setLinkDate={setLinkDate} onChange={handleDateRangeChange} />}
                    {useSearchBar && <SearchBar value={textMatch} setTextMatch={(value) => setTextMatch(value)} />}
                    <Box width={'100%'} />
                    {((menu()?.length??0) >= 1) && <ActionsList variant="mobileMenu" options={(menu()??[])} /> }
                </Box>
                <Box display={'flex'} flexDirection={quickListPosition === "top" ? 'column' : 'row'} >
                    {quickListPosition === "top" &&
                        <>
                            { (quickList?.length??0) >= 1 && <QuickList list={quickList} />}
                        </>
                    }
                    <DataGridStyled
                        hideHeader={hideHeader}
                        columnBuffer={columnBuffer}
                        disableVirtualization={disableVirtualization}
                        processRowUpdate={(a) => {
                            if( Boolean(processRowUpdate) ){
                                processRowUpdate(a)
                                return a
                            }
                            return a
                        }}
                        onProcessRowUpdateError={() => null}
                        onCellEditStop={onCellEditStop}
                        editMode='cell'
                        pageSizeOptions={pageSizeOptions}
                        apiRef={dataGridRef}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        disableRowSelectionOnClick
                        fixedCells={fixedCells}
                        loading={loading}
                        localeText={GRID_DEFAULT_LOCALE_TEXT}
                        sx={{ background: "#FFFFFF", ...sx }}
                        getCellClassName={getCellClassName}
                        onCellEditStart={onCellEditStart}
                        checkboxSelection={checkboxSelection}
                        disableColumnMenu={disableColumnMenu}
                        hideFooter={(currentRows.length <= numRows)}
                        autoHeight={autoHeight}
                        columnVisibilityModel={columnVisibilityModel}
                        getRowId={params => params._id}
                        slots={{ toolbar: () => <Toolbar /> }}
                        rowHeight={rowHeight}
                        rows={currentRows}
                        columns={currentColumns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: numRows } },
                        }}
                    />
                    {quickListPosition === "right" &&
                        <>
                            { (quickList?.length??0) >= 1 && <QuickList list={quickList} sx={{ flexDirection: "column", justifyContent: "normal", ml: 3, '& .MuiListItem-root': { mb: 1 } }} />}
                        </>
                    }
                </Box>
            </Box>
        </Box>
            {downloadData && <FieldSelection onDownloadBook={onDownloadBook} open={downloadBook} onClose={() => setWindowsStatus((prevState) => ({...prevState, downloadBook: false}))} columns={columns} rows={currentRows} excludeFieldsToFile={excludeFieldsToFile} fileName={fileName} />}
            { offspring(currentRows) }
        </>
    )

}

export default memo(SuperDataGrid)