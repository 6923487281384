import { Menu as MenuMUI } from '@component/UIComponents'

const Menu = ({ anchorEl=undefined, open=undefined, onClose=undefined, onClick=undefined, children, positionArrow="left" }) => {
    return (
        <>
            <MenuMUI anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClick}
                slotProps={{
                    paper:{
                        elevation: 0,
                        sx : {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                ...(positionArrow === "left" ? {left: 12} : {right: 12}),
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {children}
            </MenuMUI>
        </>
    )
}

export default Menu