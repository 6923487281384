import { useSavePurchaseOrderMutation } from "@query/PurchasesOrder"
import { asynchronousResponseHandler } from "@util/helpers"

export const useSavePurchaseOrder = () => {
    const [ savePurchaseOrderMutation, { isLoading } ] = useSavePurchaseOrderMutation()
    const savePurchaseOrder = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await savePurchaseOrderMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id??null)) ? "El pedido se actualizo exitosamente." : "El pedido se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        savePurchaseOrder,
        isLoadingSavePurchaseOrder: isLoading
    }
}