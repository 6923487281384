import { DatePicker } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import dayjs from 'dayjs'

const Credit = ({ onChange, value=dayjs().format("MM-DD-YYYY") }) => {
    const { getDateFormats } = usePanel()
    const handleChange = (val) => {
        if( Boolean(onChange) ){
            onChange({ value: dayjs(val).format("MM-DD-YYYY") })
        }
    }

    return(
        <>
            <DatePicker
                onChange={(date) => handleChange(date)}
                value={dayjs(value)}
                format='DD/MM/YYYY'
                autoFocus
                label="Fecha de vencimiento"
                slotProps={{
                    textField: {
                        fullWidth: true,
                        helperText: `Vence el ${getDateFormats(value).fullDate}, dentro de ${getDateFormats(value).missingDays} días`
                    }
                }}
            />
        </>
    )
}

export default Credit