import { LocalPrintshopRoundedIcon } from "@component/UIComponents"
import { useURLParams } from "@hook/useURLParams"
import { useMemo } from "react"
import { useCallback } from "react"
import { useReactToPrint } from "react-to-print"

export const usePaymentScheduleMenu = (props={}) => {
    const { programmingRef=null } = props
    const { getQuery } = useURLParams()
    let organizeProgrammingBy = getQuery("organizeProgrammingBy")
    organizeProgrammingBy = Boolean(organizeProgrammingBy) ? parseInt(organizeProgrammingBy) : null

    const handlePrint = useReactToPrint({
        content: () => programmingRef.current,
    });

    const primaryMenu = useCallback((props={}) => {
        return [
            { name: "Número de factura más alto", value: "highestInvoiceNumber" },
            { name: "Número de factura más bajo", value: "lowestInvoiceNumber" },
            { name: "Fecha de vencimiento mas antigua", value: "oldestExpirationDate" },
            { name: "Fecha de vencimiento mas reciente", value: "mostRecentExpirationDate" },
            { name: "Total mas alto", value: "highestTotal" },
            { name: "Total mas bajo", value: "lowestTotal" },
            { name: "Retención en la fuente mas alta (RT)", value: "withholdingAtTheHighestSource" },
            { name: "Retención en la fuente mas baja (RT)", value: "withholdingAtTheLowestSource" },
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])

    const secondaryMenu = useCallback((props={}) => {
        return [
            {
                title: "Imprimir",
                icon: <LocalPrintshopRoundedIcon />,
                onClick: () => handlePrint(),
            },
        ]
    }, [handlePrint])

    const getFilterValueSortBy = useCallback((id=null) => {
        let resp = {}
        const currentOptions = [...primaryMenu()]
        const index = currentOptions.findIndex((n) => (n?._id??"") === id )
        if( index >= 0 ){
            resp = currentOptions[index]
        }else{
            resp = null
        }
        return resp
    }, [primaryMenu])

    const value = useMemo(() => {
        let r = getFilterValueSortBy(organizeProgrammingBy)
        return r
    }, [getFilterValueSortBy, organizeProgrammingBy])

    return {
        primaryMenu,
        getFilterValueSortBy,
        value,
        secondaryMenu,
    }
}