import { AccountBalanceRoundedIcon, LocalAtmRoundedIcon, LocalPrintshopRoundedIcon, WalletRoundedIcon } from "@component/UIComponents"
import { useGetCashBalances } from "@feature/Boxes/hooks/useBoxes"
import { useCallback, useMemo } from "react"
import { useManageFormData } from "./usePanel"
import { yup } from "./useFormik"
import { useRegisterCashTransferMutation } from "@query/Movements"
import { asynchronousResponseHandler } from "@util/helpers"
import { useReactToPrint } from "react-to-print"
import VariableExpense from "@feature/VariableExpense"
import CashTransfer from "@feature/CashTransfer"

export const useMovements = ({ filters={} }) => {
    let balance = {}
    const currentBalance = useGetCashBalances(filters)
    let n = {...currentBalance, ...(currentBalance?.resp??{})}
    delete n.resp
    balance = n
    return {
        balance,
    }
}

export const useMovementsMenus = ({ variant="mainBox", receiptRef=null }) => {
    const handlePrint = useReactToPrint({ content: () => (receiptRef?.current??undefined) });
    let primaryMenu = useCallback((props, params={}) => {
        const { onCreatePocket=()=>null, onCreateBank=()=>null, onPayFixedExpenses=()=>null } = props
        const { variantBox="", fixedBox=undefined, boxBalance=0 } = params
        let r = [
            {
                button: <VariableExpense variantBox={variantBox} fixedBox={fixedBox} />
            },
            {
                button: <CashTransfer boxBalance={boxBalance} boxRef={fixedBox} />
            },
            {
                title: "Pagar gastos fijos",
                onClick: () => onPayFixedExpenses(),
                icon: <LocalAtmRoundedIcon />,
            },
        ]
        if( variant === "pocket" ) r.unshift({
            title: "Crear bolsillo",
            onClick: () => onCreatePocket(),
            icon: <WalletRoundedIcon />,
        })
        if( variant === "bank" ) r.unshift({
            title: "Crear banco",
            onClick: () => onCreateBank(),
            icon: <AccountBalanceRoundedIcon />,
        },)
        return r
    }, [variant])
    
    let secondaryMenu = useCallback((props) => {
        // const {  } = props
        let r = [
            {
                title: "Imprimir recibo",
                onClick: () => handlePrint(),
                icon: <LocalPrintshopRoundedIcon />,
            },
        ]
        return r
    }, [handlePrint])

    return {
        primaryMenu,
        secondaryMenu,
    }
}

export const useCashTransferFormData = (config={}) => {
    const { defaultValues=undefined, openModal=false, } = config

    const initialValues = useMemo(() => ({ observation: "", outputRef: "", payments: [] }), [])
    const schema = {
        observation: yup.string().required("La observación es requerida"),
        outputRef: yup.string().required("La referencia de la caja de salida no se cargo correctamente, cierre la ventana de traslado he intente nuevamente"),
        payments: yup.array().min(1, "Añada al menos un pago para continuar con el traslado.").max(3, "Puede realizar un máximo de 3 traslados consecutivos.").required("Añada al menos un pago para continuar."),
    }
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema,
        openModal,
    })

    return { values, setFieldValue, resetValues, validateValues, schema }
}


export const useRegisterCashTransfer = () => {
    const [ registerCashTransferMutation, {isLoading} ] = useRegisterCashTransferMutation()
    const registerCashTransfer = async (payload=null, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await registerCashTransferMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El traslado se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerCashTransfer,
        isLoading
    }
}