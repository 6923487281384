import { Alert, Box, ClearIconRounded, Typography, styled } from "@component/UIComponents"
import { useGetHomeDelivery } from "@feature/Deliveries/hooks/useGetHomeDelivery"
import { useGetUserSessionInformation } from "@feature/Users"
import { currencyFormat } from "@util/currencyFormat"
import { preventAction } from "@util/helpers"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useRemoveHomeDeliveryFromTheInvoice } from "../hooks/useRemoveHomeDeliveryFromTheInvoice"

const Container = styled(Box)(() => ({
    '& .delDelivery' : {
        display: 'none',
        cursor: 'pointer',
    },
    '&:hover': {
        '& .delDelivery' : {
            display: 'flex',
        },
    }
}))

const DeliveryInformation = ({ invoiceRef=null }) => {
    const { fullName, userData } = useGetUserSessionInformation()
    const { t } = useTranslation()
    const { delivery } = useGetHomeDelivery({ invoiceRef }, { skip: !Boolean(invoiceRef) })
    const { removeHomeDeliveryFromTheInvoice } = useRemoveHomeDeliveryFromTheInvoice()

    const onRemoveDelivery = useCallback(() => {
        preventAction({
            text: ((delivery?.deliveryPaymentMethod??"") === "inBox") ?
                `¿Desea remover la información del domicilio de la factura?, el valor del domicilio sera re integrado a la caja ${fullName}`
                :
                "¿Desea remover la información del domicilio de la factura?",
            onSubmit: () => removeHomeDeliveryFromTheInvoice({ deliveryRef: (delivery?._id??null), returnBox: (userData?._id??null) })
        })
    }, [delivery, fullName, userData, removeHomeDeliveryFromTheInvoice])

    return(
        <Box>
            <Typography sx={{ textDecoration: "underline" }} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.875rem'} >Información del domicilio: </Typography>
            { Boolean(delivery?._id) ?
                <Container display={'flex'} gap={1} >
                    <Box onClick={onRemoveDelivery} className="delDelivery" width={24} sx={{ bgcolor: (theme) => theme.palette.error.main }} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                        <ClearIconRounded sx={{ color: "#FFFFFF" }} />
                    </Box>
                    <Box width={'100%'} >
                        <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            Domiciliario:
                            <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} >
                                {(delivery?.delivery?.name??"")}
                            </Box>
                        </Typography>
                        <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            Forma de pago del domicilio:
                            <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} >
                                {t((delivery?.deliveryPaymentMethod??""))}
                            </Box>
                        </Typography>
                        <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={'.875rem'} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                            Valor del domicilio:
                            <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} >
                                {currencyFormat((delivery?.deliveryAmount??""))}
                            </Box>
                        </Typography>
                    </Box>
                </Container>
                :
                <Alert severity="info" sx={{ my: 1 }} >
                    No se registro información de domicilio.
                </Alert>
            }
        </Box>
    )
}

export default DeliveryInformation