import { Fragment, memo, useMemo } from "react"
import FixedSizeList from "./FixedSizeList"

const ListRendering = ({ component=()=><></>, elements=[], useVirtualization=false, addHeight=0 }) => {
    const numRows = useMemo(() => (Math.floor(((window.innerHeight-320)/50))), [])
    const rowHeight = 50

    if( useVirtualization ){
        return(
            <>
                <FixedSizeList
                    list={elements}
                    itemSize={ numRows >= 100 ? ((100*rowHeight)+226.5) : ((numRows*rowHeight)+246.5) }
                    render={(list, index) => component(list, index)}
                />
            </>
        )
    }else{
        return(
            <>
                { elements.map((item, index) => {
                    return(
                        <Fragment key={index} >
                            {component(item, index)}
                        </Fragment>
                    )
                }) }
            </>
        )
    }
}

export default memo(ListRendering)