import { useRegisterProductCategoryMutation } from "@query/Products"
import { asynchronousResponseHandler } from "@util/helpers"

export const useRegisterProductCategory = () => {
    const [registerProductCategoryMutation, { isLoading }] = useRegisterProductCategoryMutation()

    const registerProductCategory = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerProductCategoryMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id??null)) ? "La categoría se actualizo exitosamente." : "La categoría se registro.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerProductCategory,
        isLoadingRegisterProductCategory: isLoading
    }
}