import { usePanel } from "@hook/usePanel"
import { useURLParams } from "@hook/useURLParams"
import { currencyFormat } from "@util/currencyFormat"
import { arrayReducer, generatePropsQuickList } from "@util/helpers"
import dayjs from "dayjs"
import { useCallback, useState } from "react"
import { useExpensesMenus } from "./useExpensesMenus"
import { useTranslation } from "react-i18next"

export const useExpensesController = () => {
    const { getQueries } = useURLParams()
    const { groupArrangement } = usePanel()
    const { primaryOptions } = useExpensesMenus()
    const { t } = useTranslation()

    const [filters, setFilters] = useState({
        startDate: dayjs().format("MM-DD-YYYY"),
        endDate: dayjs().format("MM-DD-YYYY"),
        organizeBy: "",
    })

    const getFiltersQuery = useCallback(() => {
        const queries = ["startDate", "endDate"]
        let query = getQueries(queries)
        return query
    }, [getQueries])

    const setFilterData = useCallback((key, val) => setFilters((prevState) => ({...prevState, [key]: val})), [])

    const organizeByExpenseResponsibility = useCallback((expenses=[]) => {
        let r = groupArrangement({
            results: expenses,
            groupOuting:(n) => (n?.user?._id??""),
            firstExit: (n) => ({
                groupName: (n?.user?._id??""),
                user: `${(n?.user?.name??"")} ${(n?.user?.lastName??"")}`,
                total: (n?.amount??0),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].results.push(n)
                group[index].total = (n?.amount??0)
                if( !Boolean(group[index]?.user) ){
                    group[index].user = `${(n?.user?.name??"")} ${(n?.user?.lastName??"")}`
                }
            }
        })
        return r
    }, [groupArrangement])

    const groupExpensesBy = useCallback(({ group="", list=[] }) => {
        const by = {
            user: (item) => (item?.user?._id??""),
            box: (item) => (item?.box??""),
            concept: (item) => (item?.concept??""),
        }
        let r = []
        if( Boolean(group) ) {
            r = groupArrangement({
                results: list,
                groupOuting:(n) => by[group](n),
                firstExit: (n) => ({
                    groupName: by[group](n),
                    user: `${(n?.user?.name??"")} ${(n?.user?.lastName??"")}`,
                    box: `${(n?.box??"")}`,
                    concept: `${(n?.concept??"")}`,
                    total: (n?.amount??0),
                    results: [n]
                }),
                secondExit: (group, index, n) => {
                    group[index].results.push(n)
                    group[index].total = (n?.amount??0)
                    if( !Boolean(group[index]?.user) ){
                        group[index].user = `${(n?.user?.name??"")} ${(n?.user?.lastName??"")}`
                    }
                }
            })
        }
        return r
    }, [groupArrangement])

    const totalizeExpenses = useCallback((currentExpenses=[]) => {
        let r = [
            {name: "Total", total: arrayReducer({ list: currentExpenses, reducer: (val) => (val?.amount??0) }) }
        ]
        return r
    }, [])

    const totalizeExpenseTable = useCallback((list=[]) => {
        const totalize = totalizeExpenses(list)
        return generatePropsQuickList({ list: totalize, onClick: () => null, primaryText: (item) => currencyFormat((item?.total??0)), secondaryText: (item) => (item?.name??"") })
    }, [totalizeExpenses])

    const obtainGroupBy = useCallback(() => {
        const { organizeBy="" } = getQueries(["organizeBy"])
        const n = primaryOptions()
        let r = (n.find((x) => x._id === parseInt(organizeBy)) ?? null)
        return r
    }, [primaryOptions, getQueries])

    const tableTitle = useCallback((dataList={}) => {
        const groupBy = obtainGroupBy()
        if( Boolean(groupBy) ){
            return t(dataList[(groupBy?.value??"")])
        }else{
            return (dataList?.groupName??"")
        }
    }, [obtainGroupBy, t])

    return {
        getFiltersQuery,
        filters,
        setFilterData,
        organizeByExpenseResponsibility,
        totalizeExpenseTable,
        groupExpensesBy,
        obtainGroupBy,
        tableTitle,
    }
}