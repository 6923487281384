import TabAppContext from "@component/TabAppContext"
import { useTranslation } from "react-i18next"
import UndefinedSales from "./UndefinedSales"
import MySalesToday from "./MySalesToday"
import { useEffect, useMemo, useState } from "react"
import { getCookie } from "@util/helpers"
import SavedSales from "./SavedSales"
import { useValidatePermission } from "@hook/useValidatePermission"

const CashierLists = ({ onSetSelectPendingInvoice=()=>null, onRecoverSavedInvoice=()=>null, selectPendingInvoice=null }) => {
    const { validateArrayByPermissionOrder } = useValidatePermission()
    const [tab, setTab] = useState("")
    const { t } = useTranslation()
    const savedProductList = getCookie("savedProductList", { convertJSONstringify: true })
    const tabs = useMemo(() => {
        let r = [
            { label: t('Pendientes'), value: "undefinedSale", component: <UndefinedSales refId={selectPendingInvoice} onChangeTab={setTab} onSetSelectPendingInvoice={onSetSelectPendingInvoice} /> },
            { label: t('Mis ventas'), value: "mySales", component: <MySalesToday onChangeTab={setTab} /> },
            { label: t(`Guardadas (${(savedProductList??[]).length}) `), value: "saved", component: <SavedSales onChangeTab={setTab} onRecoverSavedInvoice={onRecoverSavedInvoice} /> },
        ]
        r = validateArrayByPermissionOrder(["show_undefined_sales_list", "my_sales", "temporarily_save_the_sale"], r)
        return r
    }, [onSetSelectPendingInvoice, onRecoverSavedInvoice, savedProductList, t, selectPendingInvoice, validateArrayByPermissionOrder])

    useEffect(() => {
        if( !Boolean(tab) ){
            setTab(tabs[0]?.value)
        }
    }, [tabs, tab])

    return(
        <>
            {((tabs.length >= 1) && Boolean(tab)) &&
                <TabAppContext
                    tab={tab}
                    tabs={tabs}
                    onChange={(_, newTab) => setTab(newTab)}
                />
            }
        </>
    )
}

export default CashierLists