import { Box, Toolbar } from '@component/UIComponents'
import Copyright from './Copyright'

const Main = ({ children }) => {
    return (
        <Box component="main" sx={{ backgroundColor: (theme) => theme.palette.background.paper, flexGrow: 1, height: '100vh', overflow: 'auto', }} >
            <Toolbar />
            <Box width={'100%'} height={'100%'} >
                {children}
                <Copyright sx={{ pt: 4 }} />
            </Box>
        </Box>
    )
}

export default Main