import { Box, Divider, ReceiptLongRoundedIcon, Typography, createFilterOptions } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useGetSales } from "@feature/Reports/Sales/hooks/useSales"
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '@util/currencyFormat'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

const UndefinedSales = ({ onChangeTab=()=>null, onSetSelectPendingInvoice=()=>null, refId=undefined }) => {
    const { t } = useTranslation()
    const toDay = dayjs().format("MM-DD-YYYY")
    const { results, isFetching, } = useGetSales({ variant: "undefinedSale", startDate: toDay, endDate: toDay })
    const filterOptions = createFilterOptions({
        stringify: (option) => `${t((option?.customer?.name??""))} ${(option?.seller?.name??"")} ${option.total}`,
    });

    const handleKeyDown = (event) => {
        const { code } = event
        if( code === "Tab" ){
            event.preventDefault()
            onChangeTab("mySales")
        }
    }

    const val = useMemo(() => {
        let r = null
        if( Boolean(refId) ){
            r = (results.find((n) => n?._id === refId)??null)
        }
        return r
    }, [refId, results])

    const onSetSelectPendingInvoiceMemo = useCallback((val) => {
        onSetSelectPendingInvoice(val)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onSetSelectPendingInvoice, val])

    return(
        <>
        <Box mt={1} >
            <Autocomplete
                value={val}
                onChange={(_, param) => onSetSelectPendingInvoiceMemo((param?._id??null))}
                // onChange={(_, param) => console.log(param)}
                onKeyDown={handleKeyDown}
                open={true}
                slotProps={
                    {paper: {
                        elevation: 0
                    }}
                }
                filterOptions={filterOptions}
                loading={isFetching}
                placeholder="Pendientes"
                options={results}
                renderOption={(props, option) => {
                    return(
                        <li {...props} key={option._id} >
                            <Box width={'100%'} >
                                <Box display={'flex'} alignItems={'center'} >
                                    <Box width={'100%'} >
                                        <Typography sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.1} fontSize={'.875rem'} fontWeight={'bold'} > Cliente: <Box component={'span'} sx={{ color: "#000" }} > {t((option?.customer?.name??""))} </Box> </Typography>
                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} > #FAC: <Box component={'span'} sx={{ color: "#000" }} > {(option?.consecutive??0)} </Box> </Typography>
                                        <Typography lineHeight={1.3} fontSize={'.875rem'} fontWeight={'bold'} > Vendedor: <Box component={'span'} sx={{ color: "#000" }} > {(option?.seller?.name??"")} {(option?.seller?.lastName??"")} </Box> </Typography>
                                        <Typography sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.1} fontSize={'.875rem'} fontWeight={'bold'} > Total: <Box component={'span'} sx={{ color: "#000" }} > {currencyFormat((option?.total??0))} </Box> </Typography>
                                    </Box>
                                    <Box>
                                        <ReceiptLongRoundedIcon />
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                        </li>
                    )
                }}
            />
        </Box>
        </>
    )
}

export default UndefinedSales