import { api, mutationConfig } from "../splitApi";
import { ProviderTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerProvider: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/provider", body };
            },
            invalidatesTags: ProviderTag
        }),
        archivedProvider: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/provider", body };
            },
            invalidatesTags: ProviderTag
        }),
        registerAdvanceToSupplier: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/advance/to/supplier", body };
            },
            invalidatesTags: ProviderTag
        }),
        getProviders: build.query({
            query: (params=undefined) => ({
                url: `/get/providers`,
                params
            }),
            providesTags: ProviderTag
        }),
        getProvider: build.query({
            query: (params=undefined) => ({
                url: `/get/provider`,
                params
            }),
            providesTags: ProviderTag
        }),
        getProviderAdvanceHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/provider/advance/history`,
                params
            }),
            providesTags: ProviderTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useRegisterAdvanceToSupplierMutation,
    useGetProvidersQuery,
    useGetProviderQuery,
    useLazyGetProviderQuery,
    useRegisterProviderMutation,
    useArchivedProviderMutation,
    useGetProviderAdvanceHistoryQuery,
} = Endpoints;