import { createContext, useContext, useState } from 'react';
import { io } from 'socket.io-client';
import { domain, api } from '../api/splitApi';
import { store } from '../utils/store';
import { useGetBusinessInformation } from '@feature/Settings/BusinessInformation/hooks/useBusiness';
import { useGetUserSessionInformation } from '@feature/Users';
import { useEffect } from 'react';

const dispatch = (event) => store.dispatch(event);

const createTagArray = (tags = "") => tags.split("-");

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [socketIO, setSocketIO] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const { business, isSuccess: isSuccessBusinessInformation } = useGetBusinessInformation();
    const { userData, isSuccess: isSuccessUserInformation } = useGetUserSessionInformation();

    const openSocketIOConnection = (queries) => {
        const newSocketIO = io(`${domain}/`, {
            transports: ['websocket'],
            query: queries,
        });

        newSocketIO.on('connect', () => {
            console.log('bidirectional connection with socket open');
            newSocketIO.on("InvalidateTag", (tags) => {
                console.log(createTagArray(tags));
                dispatch(api.util.invalidateTags(createTagArray(tags)))
            });

            newSocketIO.on('error', (error) => console.error(error) )
        });

        setSocketIO(newSocketIO);
    };

    useEffect(() => {
        if (isSuccessUserInformation && isSuccessBusinessInformation) {
            setIsReady(true);
        }
    }, [isSuccessUserInformation, isSuccessBusinessInformation]);

    useEffect(() => {
        if (isReady) {
            const queries = { userId: (userData?._id ?? null), businessId: (business?._id ?? null), };
            openSocketIOConnection(queries)
        }
    }, [isReady, business, userData]);

    useEffect(() => {
        return () => {
            if( Boolean(socketIO) ){
                socketIO.disconnect()
                console.log("bidirectional connection with socket closed")
            }
        }
    }, [socketIO])

    return (
        <SocketContext.Provider value={{ socketIO, openSocketIOConnection }}>
            {children}
        </SocketContext.Provider>
    );
};
