import { HelpOutlineRoundedIcon, InputAdornment, Tooltip, Typography, styled, tooltipClasses } from '@component/UIComponents'

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const ActionInformation = ({ useAdornment = true, information="" }) => {
    const Adornment = ({ children }) => {
        if (useAdornment) {
            return (
                <InputAdornment position='end' >
                    {children}
                </InputAdornment>
            )
        } else {
            return (
                <>
                    {children}
                </>
            )
        }
    }
    return (
        <Adornment >
            <HtmlTooltip
                arrow
                placement='right'
                title={
                    <>
                        <Typography fontWeight={'bold'} fontSize={'.800rem'} > Información: </Typography>
                        <Typography fontSize={'.875rem'} >
                            {information}
                        </Typography>
                    </>
                }
            >
                <HelpOutlineRoundedIcon sx={{ color: (theme) => theme.palette.warning.light }} />
            </HtmlTooltip>
        </Adornment>
    )
}

export default ActionInformation