import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

export const useRegisterFormController = ({ defaultValues=null, onSubmit=()=>null }) => {
    const { t } = useTranslation()
    const initialValues = useMemo(() => ({ name: "", lastName: "", email: "", numberPhone: "", password: "", repeatPassword: "" }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required(t('nameIsRequired')),
            email: yup.string().email(t('emailIsRequired')).required(t('emailIsRequired')),
            numberPhone: yup.string().required(t('phoneNumberRequired')),
            password: yup.string().required(t('passwordRequired')),
            repeatPassword: yup.string().oneOf([yup.ref('password'), null], t('passwordsDoNotMatch')).required(t('passwordRequired')),
        }
    })

    const submitValues = useCallback(() => {
        const { type } = validateValues(values)
        if(type === "errors") return
        onSubmit(values)
    }, [onSubmit, validateValues, values])

    return { values, setFieldValue, resetValues, validateValues, submitValues }
}