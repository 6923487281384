import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, CurrencyExchangeRoundedIcon, EditIconRounded, ReplayRoundedIcon, RestoreRoundedIcon } from "@component/UIComponents"
import { preventAction } from "@util/helpers"
import { useCallback } from "react"
import AdvancePaymentHistory from "../components/AdvancePaymentHistory"

export const useCustomerListMenu = () => {
    const primaryMenu = useCallback((props={}, config={}) => {
        const { onEdit=()=>null, onArchived=()=>null, onAdvance=()=>null, onReturnAdvance=()=>null } = config
        const DismissButton = (props?.isArchived??false)
        return [
            ...(DismissButton ? [] : [{
                title: "Editar",
                onClick: () => onEdit(),
                icon: <EditIconRounded />,
            }]),
            {
                title: (props?.isArchived??false) ? "Recuperar" : "Archivar",
                onClick: () => preventAction({
                    text: (props?.isArchived??false) ? `Desea recuperar el cliente, ${(props?.name??"")}` : `Desea archivar el cliente, ${(props?.name??"")}`,
                    onSubmit: () => onArchived()
                }),
                icon: <ArchiveRoundedIcon />
            },
            ...(DismissButton ? [] : [{
                title: "Generar anticipo",
                onClick: () => onAdvance(),
                icon: <CurrencyExchangeRoundedIcon />,
            }]),
            {
                title: "Historial de anticipos",
                onClick: () => null,
                icon: <RestoreRoundedIcon />,
                variantClick: "popover",
                popoverContainer: <AdvancePaymentHistory {...props} />
            },
            ...(DismissButton ? [] : [{
                title: "Retornar anticipo",
                onClick: () => onReturnAdvance(),
                icon: <ReplayRoundedIcon />,
            }]),
        ]
    }, [])
    const secondaryMenu = useCallback((config={}) => {
        const { onCreate=()=>null } = config
        return [
            {
                title: "Crear cliente",
                onClick: () => onCreate(),
                icon: <AddCircleOutlineIconRounded />
            },
        ]
    }, [])

    return {
        primaryMenu,
        secondaryMenu,
    }
}