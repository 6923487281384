import { useMemo } from 'react'
import { Chip, Stack, styled } from '../UIComponents'
import dayjs from 'dayjs'

const QuickAccessDates = ({ startDate, endDate, onChange=()=>null }) => {
    const ChipStyled = styled(Chip)(() => ({
        fontWeight: "bold",
        cursor: "pointer"
    }))

    const { toDay, yesterday, thisWeek, thisMonth } = useMemo(() => {
        const date = dayjs()
        const toDay = (dayjs(startDate).format("DD-MM-YYYY") === date.format("DD-MM-YYYY")) && (dayjs(endDate).format("DD-MM-YYYY") === date.format("DD-MM-YYYY"))
        const yesterday = (dayjs(startDate).format("DD-MM-YYYY") === date.subtract(1, "day").format("DD-MM-YYYY")) && (dayjs(endDate).format("DD-MM-YYYY") === date.subtract(1, "day").format("DD-MM-YYYY"))
        const thisWeek = (dayjs(startDate).format("DD-MM-YYYY") === date.startOf("week").format("DD-MM-YYYY")) && (dayjs(endDate).format("DD-MM-YYYY") === date.endOf("week").format("DD-MM-YYYY"))
        const thisMonth = (dayjs(startDate).format("DD-MM-YYYY") === date.startOf("month").format("DD-MM-YYYY")) && (dayjs(endDate).format("DD-MM-YYYY") === date.endOf("month").format("DD-MM-YYYY"))
        
        return { toDay, yesterday, thisWeek, thisMonth }
    }, [startDate, endDate])

    const handleChange = (field="") => {
        const date = dayjs()
        let resp = null
        if( field === "toDay" ){
            resp = { startDate: date.format("MM-DD-YYYY"), endDate: date.format("MM-DD-YYYY") }
        }
        if( field === "yesterday" ){
            resp = { startDate: date.subtract(1, "day").format("MM-DD-YYYY"), endDate: date.subtract(1, "day").format("MM-DD-YYYY") }
        }
        if( field === "thisWeek" ){
            resp = { startDate: date.startOf("week").format("MM-DD-YYYY"), endDate: date.endOf("week").format("MM-DD-YYYY") }
        }
        if( field === "thisMonth" ){
            resp = { startDate: date.startOf("month").format("MM-DD-YYYY"), endDate: date.endOf("month").format("MM-DD-YYYY") }
        }
        onChange(resp)
    }

    return(
        <Stack flexDirection={'row'} gap={1} justifyContent={'center'} >
            <ChipStyled label="Hoy" size='small' variant={ toDay ? 'filled' : 'outlined'} color='primary' onClick={() => handleChange("toDay")} />
            <ChipStyled label="Ayer" size='small' variant={ yesterday ? 'filled' : 'outlined'} color='primary' onClick={() => handleChange("yesterday")} />
            <ChipStyled label="Esta semana" size='small' variant={ thisWeek ? 'filled' : 'outlined'} color='primary' onClick={() => handleChange("thisWeek")} />
            <ChipStyled label="Este mes" size='small' variant={ thisMonth ? 'filled' : 'outlined'} color='primary' onClick={() => handleChange("thisMonth")} />
        </Stack>
    )
}

export default QuickAccessDates