import { Box } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'

const ProgrammingTotal = ({totalize=0}) => {
    return(
        <>
            <Box pr={7} fontSize={'.750rem'} display={'flex'} justifyContent={'space-between'} width={'100%'} fontWeight={'bold'} textTransform={'uppercase'} >
                <Box>
                    Total a pagar
                </Box>
                <Box>
                    {currencyFormat(totalize)}
                </Box>
            </Box>
        </>
    )
}

export default ProgrammingTotal