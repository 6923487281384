import { useCallback } from "react"

export const useExpensesMenus = () => {
    const primaryOptions = useCallback(() => {
        let r = [
            {name: "Gasto", value: "concept"},
            {name: "Caja de salida", value: "box"},
            {name: "Responsable", value: "user"},
        ].map((b, index) => ({...b, _id: (index+1)}))
        return r
    }, [])

    return {
        primaryOptions
    }
}