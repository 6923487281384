import { AttachMoneyRoundedIcon, DeleteIconRounded, ListRoundedIcon, SaveRoundedIcon } from "@component/UIComponents"
import { formErrorList } from "@hook/useFormTools"
import { yup } from "@hook/useFormik"
import { asynchronousResponseHandler, calculateSubtotal, calculateTax, calculateTotal, calculateTotalFreight, calculateWithholdingTax, preventAction, roundDecimals } from "@util/helpers"
import dayjs from "dayjs"
import { useEffect, useMemo, useCallback, useState } from "react"
import ProductFamilyCosts from "../components/ProductFamilyCosts"
import SupplierDailyShoppingList from "../components/SupplierDailyShoppingList"
import { useRecordPurchaseMutation } from "@query/Purchases"
import { Swal, Toast } from "@util/swal"
import { useManageFormData } from "@hook/usePanel"
import JSZip from "jszip"
import { XMLParser } from 'fast-xml-parser'
import { useGetProductsByType } from "@feature/Inventory/Products/hooks/useProducts"
import { useGetProviders } from "@feature/Providers/hooks/useProviders"
import { useGetTaxes } from "@feature/Business/hooks/useBusiness"
import { useGetPurchase } from "@feature/PurchaseReport/Purchases/hooks/usePurchase"
import { useLocation, useParams } from "react-router-dom"
import { useAutomaticSavingPurchaseInvoices } from "./useAutomaticSavingPurchaseInvoices"

export const useEnterPurchaseDefaultValue = () => {
    const { order=null } = (useLocation()?.state??{})
    const { purchase:purchaseRef=null } = useParams()
    const { purchase } = useGetPurchase({ ref: purchaseRef })

    const defaultValues = useMemo(() => {
        return Boolean(purchase) ? purchase : Boolean(order) ? order : null
    }, [order, purchase])

    return defaultValues
}

export const useEnterPurchaseFormData = (params = {}) => {
    const { defaultValues = undefined, totalAmountOfPurchasesOfTheDay = 0 } = params
    const [purchaseTotals, setPurchaseTotals] = useState([])
    const [purchaseTaxes, setPurchaseTaxes] = useState([])
    const initialValues = useMemo(() => ({ provider: null, billingDate: dayjs().format("MM-DD-YYYY"), dateReceived: dayjs().format("MM-DD-YYYY"), dueDate: dayjs().add(1, "day").format("MM-DD-YYYY"), whoReceives: "", invoiceNumber: "", haulier: null, products: [], subTotal: 0, total: 0, freight: 0, iva: 0, reteFuente: 0, isCredit: false, payments: [] }), [])

    const schema = {
        provider: yup.object().shape({
            _id: yup.string().required("El proveedor es requerido.")
        }).required("El proveedor es requerido."),
        billingDate: yup.date().required("La fecha de facturación es requerida."),
        dateReceived: yup.date().required("La fecha de recepción es requerida."),
        whoReceives: yup.string().required("La persona que recibe es requerida."),
        invoiceNumber: yup.string(),
        products: yup.array().min(1, "Añada al menos un producto a la lista.").of(yup.object().shape({
            new: yup.boolean().test('is-true',  "Registrar los productos que no hayan sido cargados exitosamente antes de continuar.", (val) => Boolean(val) === false),
        })).required("Añada al menos un producto a la lista."),
        subTotal: yup.number().min(0, "El sub total es requerido.").required("El sub total es requerido."),
        total: yup.number().min(0, "El total es requerido.").required("El total es requerido."),
        freight: yup.number().min(0, "El flete es requerido."),
        iva: yup.number().min(0, "El iva es requerido.").required("El iva es requerido."),
        reteFuente: yup.number().min(0, "La retención en la fuente es requerida.").required("La retención en la fuente es requerida."),
    }

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema
    })

    const { products, provider } = values

    const calculatePurchase = useCallback(() => {
        let currentProducts = [...products]
        let totalize = currentProducts.reduce((acc, param) => {
            let currentAcc = { ...acc }
            currentAcc.totalFreight += calculateTotalFreight(param)
            currentAcc.totalIva += calculateTax({ ...param, tax: (param?.tax?.tax ?? 0)})
            currentAcc.subTotal += calculateSubtotal({...param})
            currentAcc.total += calculateTotal({ ...param, tax: (param?.tax?.tax ?? 0) })
            if ((provider?.tax?.name ?? "") === "retefuente") {
                if (((currentAcc?.subTotal ?? 0) + totalAmountOfPurchasesOfTheDay) >= (provider?.tax?.minimumAmount ?? "")) {
                    currentAcc.reteFuente = calculateWithholdingTax({
                        amount: (currentAcc?.subTotal ?? 0),
                        percentageToRetain: (provider?.tax?.tax ?? 0),
                    })
                    currentAcc.total = (currentAcc.total - currentAcc.reteFuente)
                }
            }
            return currentAcc
        }, { totalFreight: 0, totalIva: 0, subTotal: 0, total: 0, reteFuente: 0 })
        let { subTotal, total, totalFreight, totalIva, reteFuente } = totalize
        subTotal = roundDecimals(subTotal)
        totalFreight = roundDecimals(totalFreight)
        totalIva = roundDecimals(totalIva)
        reteFuente = roundDecimals(reteFuente)
        total = ((subTotal-reteFuente)+totalIva)

        setFieldValue("subTotal", subTotal)
        setFieldValue("total", total)
        setFieldValue("freight", totalFreight)
        setFieldValue("iva", totalIva)
        setFieldValue("reteFuente", reteFuente)

        setPurchaseTotals([
            { name: "Sub total", amount: subTotal },
            { name: "Iva", amount: (totalIva >= 1) ? totalIva : "N/A" },
            { name: "Retención en la fuente", amount: (reteFuente >= 1) ? reteFuente : "N/A" },
            { name: "Total", amount: total },
            { name: "Flete", amount: (totalFreight >= 1) ? totalFreight : "N/A" },
        ])
        const groupedByTax = {};
        currentProducts.forEach(item => {
            const taxId = (item?.tax?._id ?? "");
            if (Boolean(taxId)) {
                if (!groupedByTax[taxId]) {
                    groupedByTax[taxId] = {
                        _id: taxId,
                        name: `${item?.tax?.name ?? ""} ${item?.tax?.tax ?? ""}%`,
                        base: 0,
                        total: 0,
                    }
                }
                groupedByTax[taxId].base += calculateSubtotal({...item})
                groupedByTax[taxId].total += calculateTax({ ...item, tax: (item?.tax?.tax ?? 0) })
            }
        });
        let result = Object.values(groupedByTax);
        result = result.map((b) => ({...b, base: roundDecimals((b?.base??0)), total: roundDecimals((b?.total??0)) }))
        setPurchaseTaxes(result)
    }, [products, provider, totalAmountOfPurchasesOfTheDay, setFieldValue])

    const removeProductFromList = (productId = "") => {
        let currentProducts = [...products]
        let productIndex = currentProducts.findIndex((n) => (n?.productId ?? "") === productId)
        if (productIndex >= 0) {
            currentProducts.splice(productIndex, 1)
        }
        setFieldValue("products", currentProducts)
    }

    useEffect(() => {
        calculatePurchase()
    }, [calculatePurchase])

    return { values, setFieldValue, resetValues, validateValues, purchaseTotals, removeProductFromList, purchaseTaxes }
    // return { values, setFieldValue, resetValues, validateValues, purchaseTotals, removeProductFromList, purchaseTaxes }

}

export const useEnterPurchasePreSelectionProductFormData = (params = {}) => {
    const { defaultValue = null, selectedProduct = null, useArrangementScheme=false } = params
    const baseSchema = yup.object().shape({
        quantity: yup.number().min(1, "La cantidad del producto es requerida  y debe ser mayor a 0").required("La cantidad del producto es requerida  y debe ser mayor a 0"),
        purchaseCost: yup.number().min(1, "El costo de compra es requerido y debe ser mayor a 0").required("El costo de compra es requerido  y debe ser mayor a 0"),
        freight: yup.number().min(0, "El flete es un valor requerido").required("El flete es un valor requerido"),
        packaging: yup.object().shape({
            _id: yup.string().required("El embalaje de compra es requerido")
        }).required("El embalaje de compra es requerido"),
        tax: yup.object().nullable(),
        productId: yup.string().required("El identificador del producto no se cargo correctamente, cierre la ventana he intente nuevamente."),
        name: yup.string().required("El identificador del producto no se cargo correctamente, cierre la ventana he intente nuevamente."),
    })
    const arraySchema = yup.array().of(baseSchema)
    let schema = useArrangementScheme ? arraySchema : baseSchema
    let val = { name: "", quantity: 1, purchaseCost: 0, freight: 0, packaging: null, tax: null, productId: "" }
    const [values, setValue] = useState({ ...val })

    const resetValues = () => setValue({ ...val })

    const validateValues = (params = {}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            console.log(error)
            formErrorList((error?.errors ?? []))
            return {
                type: "errors",
                errors: (error?.errors ?? [])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    const loadDefaultValues = useCallback(() => {
        if (Boolean(defaultValue?.productId)) {
            Object.keys(defaultValue).forEach(key => {
                setFieldValue(key, (defaultValue[key]))
            })
        } else {
            if (Boolean(selectedProduct?._id)) {
                setFieldValue("name", (selectedProduct?.name ?? ""))
                setFieldValue("packaging", (selectedProduct?.packaging ?? null))
                setFieldValue("tax", (selectedProduct?.tax ?? null))
                setFieldValue("productId", (selectedProduct?._id ?? ""))
                setFieldValue("purchaseCost", (selectedProduct?.cost ?? ""))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProduct, defaultValue])

    useEffect(() => {
        loadDefaultValues()
    }, [loadDefaultValues])

    return { values, setFieldValue, resetValues, validateValues }
}

export const useGenerateAdvanceFormData = () => {
    const schema = yup.object().shape({
        providerId: yup.string().required("Ocurrió un problema al cargar el proveedor, cierre la ventana he intente nuevamente."),
    })
    let defaultValues = {
        providerId: "",
        payments: [],
    }
    const [values, setValue] = useState({ ...defaultValues })

    const resetValues = () => setValue({ ...defaultValues })

    const validateValues = (params = {}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            console.log(error)
            formErrorList((error?.errors ?? []))
            return {
                type: "errors",
                errors: (error?.errors ?? [])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    return { values, setFieldValue, resetValues, validateValues }
}

export const useEnterPurchaseActions = () => {
    const { temporarilySavePurchase } = useAutomaticSavingPurchaseInvoices()

    const actions = (props = {}, config = {}) => {
        const { purchases = [], loading = false, billInformation=null } = props
        const { disabled = false, clearForm=()=>null } = config
        return [
            {
                title: "Ver compras de hoy",
                onClick: () => null,
                icon: <ListRoundedIcon />,
                variantClick: "popover",
                popoverContainer: <SupplierDailyShoppingList list={purchases} loading={loading} />,
                buttonProps: {
                    disabled
                }
            },
            {
                title: "Guardar compra temporalmente",
                onClick: () => preventAction({
                    text: `La compra se almacenara temporalmente, al actualizar el navegador con el comando CTRL+F5 o cualquier otro comando que limpie la memoria cache del navegador la lista de compras se borrara automáticamente. Las compras almacenadas temporalmente serán limpiadas automáticamente el ${dayjs().format("DD-MM-YYYY")} a las ${dayjs().endOf("day").format("hh:mm A")}`,
                    onSubmit: () => temporarilySavePurchase(billInformation, { clearForm })
                }),
                icon: <SaveRoundedIcon />,
                buttonProps: {
                    disabled
                }
            },
        ]
    }
    const dataGridActions = (props = {}, callback = {}) => {
        const { deleteItemList = () => null } = callback
        return [
            {
                title: "Remover",
                onClick: () => deleteItemList(props),
                icon: <DeleteIconRounded sx={{ color: (theme) => theme.palette.error.main }} />,
            },
            {
                title: "Costos",
                onClick: () => null,
                icon: <AttachMoneyRoundedIcon />,
                variantClick: "popover",
                popoverContainer: <ProductFamilyCosts {...props} />
            },
        ]
    }

    return {
        actions,
        dataGridActions
    }
}

export const useRecordPurchase = () => {
    const [recordPurchaseMutation, { isLoading }] = useRecordPurchaseMutation()

    const recordPurchase = async (payload = {}, callback = {}) => {
        try {
            if (isLoading === false) {
                const { clearForm = () => null, closeForm = () => null } = callback
                const resp = await recordPurchaseMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id??null)) ? "La compra se actualizo exitosamente." : "La compra se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordPurchase,
        isLoading
    }
}

export const useLoadElectronicInvoice = (props = {}) => {
    const { files = [] } = props

    const { results:productList, isFetching } = useGetProductsByType({ variant: "purchases" })
    const { isFetching:isLoadingProviders, providers:providerLis } = useGetProviders({ variant: "purchases" })
    const { taxes:taxesList, isFetching:isLoadingTax } = useGetTaxes({ name: "IVA" })
    let prepared = ((productList.length <= 0) || isFetching || isLoadingProviders || isLoadingTax)
    const currentFile = useMemo(() => (files[0]??null), [files])

    const readElectronicInvoice = useCallback((callback=()=>null) => {
        let file = currentFile
        if (Boolean(file)) {
            const reader = new FileReader();

            reader.onload = async ({ target }) => {
                const zip = new JSZip();
                const zipData = await zip.loadAsync(target.result);

                zipData.forEach(async (relativePath, file) => {
                    const content = await file.async('string');
                    const extension = relativePath.split(".")
                    if ((extension[1] ?? "") === "xml") {
                        const parse = new XMLParser()
                        const parentXMLobject = parse.parse(content)
                        const CompanyID = (parentXMLobject?.['AttachedDocument']?.['cac:SenderParty']?.['cac:PartyTaxScheme']?.['cbc:CompanyID']??"")
                        const productsXML = parentXMLobject?.AttachedDocument['cac:Attachment']['cac:ExternalReference']['cbc:Description']
                        const electronicInvoiceData = ((parse.parse(productsXML))?.['Invoice']??null)
                        const invoiceNumber = (electronicInvoiceData?.['cbc:ID']??"")
                        const x = (electronicInvoiceData?.['cac:InvoiceLine']??[])
                        if( !Array.isArray(x) && x.length <= 0 && Object.keys(x).length <= 0 ){
                            Toast.fire({
                                icon: "warning",
                                text: "No se encontraron productos en el archivo."
                            })
                            return
                        }

                        let invoiceProductsData = []
                        const provider = (providerLis?.find((n) => {
                            let nits = (n?.nit??"").split("-")
                            let nitIndex = nits.findIndex(x => x.length >= 2)
                            let companyNit = nits[nitIndex]
                            companyNit = Boolean(companyNit) ? parseInt(companyNit) : 0
                            return (companyNit === CompanyID)
                        })) || null
                        if(!Boolean(provider)) {
                            Swal.fire({
                                icon: "error",
                                text: "El proveedor no se encuentra registrado."
                            })
                            return
                        }
                        if( Array.isArray(x) && x.length >= 1 ){
                            let items = x.map((n) => {
                                let ItemIdentification = ""
                                const SellersItemIdentification = (n?.['cac:Item']?.['cac:SellersItemIdentification']?.['cbc:ID']??"")
                                const StandardItemIdentification = (n?.['cac:Item']?.['cac:StandardItemIdentification']?.['cbc:ID']??"")
                                ItemIdentification = Boolean(SellersItemIdentification) ? SellersItemIdentification : StandardItemIdentification
                                return {
                                    'Description': (n?.['cac:Item']?.['cbc:Description']??""),
                                    'TaxPercent': (n?.['cac:TaxTotal']?.['cac:TaxSubtotal']?.['cac:TaxCategory']?.['cbc:Percent']??""),
                                    'TaxSchemeName': (n?.['cac:TaxTotal']?.['cac:TaxSubtotal']?.['cac:TaxCategory']?.['cac:TaxScheme']?.['cbc:Name']??""),
                                    'SellersItemIdentification': ItemIdentification,
                                    'BaseQuantity': (n?.['cac:Price']?.['cbc:BaseQuantity']??""),
                                    'PriceAmount': (n?.['cac:Price']?.['cbc:PriceAmount']??""),
                                }
                            })
                            invoiceProductsData = items
                        }else if( Object.keys(x).length >= 1 ){
                            let ItemIdentification = ""
                            const SellersItemIdentification = (x?.['cac:Item']?.['cac:SellersItemIdentification']?.['cbc:ID']??"")
                            const StandardItemIdentification = (x?.['cac:Item']?.['cac:StandardItemIdentification']?.['cbc:ID']??"")
                            ItemIdentification = Boolean(SellersItemIdentification) ? SellersItemIdentification : StandardItemIdentification
                            let items = [{
                                'Description': (x?.['cac:Item']?.['cbc:Description']??""),
                                'TaxPercent': (x?.['cac:TaxTotal']?.['cac:TaxSubtotal']?.['cac:TaxCategory']?.['cbc:Percent']??""),
                                'TaxSchemeName': (x?.['cac:TaxTotal']?.['cac:TaxSubtotal']?.['cac:TaxCategory']?.['cac:TaxScheme']?.['cbc:Name']??""),
                                'SellersItemIdentification': ItemIdentification,
                                'BaseQuantity': (x?.['cac:Price']?.['cbc:BaseQuantity']??""),
                                'PriceAmount': (x?.['cac:Price']?.['cbc:PriceAmount']??""),
                            }]
                            invoiceProductsData = items
                        }
                        let productCrossover = invoiceProductsData.map((n ,index) => {
                            let result = null
                            let currentProduct = productList.find((x) => (x?.purchaseCodes??[]).map((f) => {
                                const code = Number.isInteger(parseInt(f)) ? parseInt(f) : f
                                return code
                            }).includes( (n?.SellersItemIdentification??0) ) )
                            
                            if( Boolean(currentProduct?._id) ){
                                result = currentProduct
                            }else{
                                result = {
                                    new: true,
                                    _id: (index+1),
                                    name: (n?.['Description']??""),
                                    packaging: null,
                                    tax: taxesList.map((x) => ({ _id: x?._id, name: x?.name, tax: x?.tax, })).find((z) => (z?.name??"") === (n?.['TaxSchemeName']??null) && (z?.tax??0) === (n?.['TaxPercent']??null)) || null,
                                    cost: (n?.['PriceAmount']??""),
                                    quantity: (n?.['BaseQuantity']??""),
                                    purchaseCodes: [`${(n?.['SellersItemIdentification']??"")}`],
                                }
                            }

                            return result
                        })

                        productList.filter((n) => (n?.purchaseCodes??[]).some((x) => invoiceProductsData.map((z) => (z?.SellersItemIdentification??null)).includes(parseInt(x)) ) )
                        productCrossover = productCrossover.map((n) => {
                            let purchaseInformation = invoiceProductsData.find((b) => (n?.purchaseCodes??[]).some((x) => parseInt(x) === (b?.SellersItemIdentification??"")) )
                            return {
                                freight: 0,
                                name: (n?.name??""),
                                packaging: (n?.packaging??null),
                                productId: (n?._id??""),
                                purchaseCost: (purchaseInformation?.PriceAmount??n?.cost??0),
                                quantity: (purchaseInformation?.BaseQuantity??n?.quantity??0),
                                tax: (n?.tax??null),
                                new: (n?.new??false),
                                codes: (n?.purchaseCodes??[]),
                            }
                        })
                        callback(provider, productCrossover, invoiceNumber)
                    }
                });
            };

            reader.readAsArrayBuffer(file);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFile, productList, providerLis, taxesList])

    return {
        readElectronicInvoice,
        prepared
    }
}