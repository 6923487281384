import Form from "@component/Form"
import TextField from "@component/TextField"
import { Button, Grid, Stack } from "@component/UIComponents"
import { useEffect, useState } from "react"
import { useRecoverPassword, useRecoverPasswordFormValues } from "../hooks/useRecoverPassword"
import { useParams } from "react-router-dom"
import { getErrorsParams } from "@hook/useFormik"

const RecoverPasswordForm = () => {
    const { tk=null } = useParams()
    const [type, setType] = useState("password")
    const { isLoadingRecoverAdministratorUserPasswordMutation, recoverAdministratorUserPassword } = useRecoverPassword()
    const { values, setFieldValue, handleSubmit, errors, touched } = useRecoverPasswordFormValues({ onSubmit: recoverAdministratorUserPassword })
    const { password } = values
    const error = (name) => getErrorsParams({ errors, field: name, touched })

    useEffect(() => {
        setFieldValue("token", tk)
    }, [tk, setFieldValue])

    return(
        <>
            <Form onSubmit={() => handleSubmit()} >
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <TextField
                            value={password}
                            InputFontWeight={'500'}
                            fullWidth
                            showPassword
                            type={type}
                            label="Nueva contraseña"
                            onViewPassword={() => setType((n) => n === "password" ? "text" : "password")}
                            onChange={({target}) => setFieldValue("password", (target?.value??""))}
                            {...error("password")}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <Stack direction={'row'} justifyContent={'end'} >
                            <Button variant="contained" disableElevation type="submit" loading={isLoadingRecoverAdministratorUserPasswordMutation} >
                                Guardar cambio
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
}

export default RecoverPasswordForm