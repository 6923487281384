import SuperDataGrid from "@component/SuperDataGrid"
import { Box, Grid } from "@component/UIComponents"
import { useGetDeliveries, useDeliveriesMenu } from "./hooks/useDeliveries"
import { usePanel } from "@hook/usePanel"
import ActionsList from "@component/ActionsList"
import motorcycleDelivery from '@style/images/motorcycle-delivery.svg'
import Img from '@component/Img'
import HistoryOfDeliveriesRealized from "./components/HistoryOfDeliveriesRealized"
import { useState } from "react"

const Deliveries = () => {
    const [showWindow, setShowWindow] = useState({ showHistoryOfDeliveryRealized: "" })
    const { getDateFormats } = usePanel()
    const { deliveries, isFetching } = useGetDeliveries()
    const { primaryMenu, secondaryMenu } = useDeliveriesMenu()

    const Buttons = (props) => <ActionsList
        title={(props?.name??"")}
        variant="mobileMenu"
        options={primaryMenu({
            onDeliveryRealized: () => setShowWindow((prevState) => ({...prevState, showHistoryOfDeliveryRealized: (props?._id??"")}))
        })}
    />

    const columns = [
        {
            flex: 1,
            minWidth: 250,
            field: "name",
            headerName: "Domiciliario",
            renderCell: ({row}) => {
                return(
                    <>
                        <Box display={'flex'} alignItems={'center'} gap={1} >
                            <Img src={motorcycleDelivery} style={{ maxWidth: 20 }} />
                            <Buttons {...row} />
                            {row.name}
                        </Box>
                    </>
                )
            }
        },
        {maxWidth: 150, minWidth: 150, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm },
    ]

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={secondaryMenu()}
                    />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        columns={columns}
                        rows={deliveries}
                        loading={isFetching}
                        filterBy={(param) => `${(param?.name??"")}`}
                    />
                </Grid>
            </Grid>
            <HistoryOfDeliveriesRealized
                refId={showWindow.showHistoryOfDeliveryRealized}
                open={Boolean(showWindow.showHistoryOfDeliveryRealized)}
                onClose={() => setShowWindow((prevState) => ({...prevState, showHistoryOfDeliveryRealized: ""}))}
            />
        </>
    )
}

export default Deliveries