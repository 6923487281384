import Dashboard from "@feature/Dashboard";
import { useAccessRedirect } from "@hook/usePanel";
import { useEffect } from "react";

const App = () => {
    const { accessRedirect } = useAccessRedirect()

    useEffect(() => {
        accessRedirect()
    }, [accessRedirect])

    return (
        <>
            <Dashboard />
        </>
    );
}

export default App;
