import { api, mutationConfig } from "../splitApi";
import { BoxTag, VariableExpensesTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        recordVariableExpense: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/record/variable/expense", body };
            },
            invalidatesTags: [...VariableExpensesTag, ...BoxTag]
        }),
        getExpenses: build.query({
            query: (params=undefined) => ({
                url: `/get/expenses`,
                params
            }),
            providesTags: VariableExpensesTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useRecordVariableExpenseMutation,
    useGetExpensesQuery,
} = Endpoints;