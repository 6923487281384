import { DeleteIconRounded, EditIconRounded } from "@component/UIComponents"
import { useCallback } from "react"

export const useCreditNoteOptions = () => {

    const primaryMenu = useCallback((props={}) => {
        const { onEdit=() => null, onDelete=()=>null } = props

        let r = [
            { title: "Editar", onClick: () => onEdit(), icon: <EditIconRounded />, },
            { title: "Remover", onClick: () => onDelete(), icon: <DeleteIconRounded />, buttonProps: { color: "error", size: "small" } },
        ].map((n, index) => ({...n, _id: index}))
        return r
    }, [])

    const secondaryMenu = useCallback((props={}) => {
        const { onDelete=()=>null } = props

        let r = [
            { title: "Remover", onClick: () => onDelete(), icon: <DeleteIconRounded />, buttonProps: { color: "error", size: "small" } },
        ].map((n, index) => ({...n, _id: index}))
        return r
    }, [])

    return {
        primaryMenu,
        secondaryMenu
    }
}