import DetailsWindow from "@component/DetailsWindow"
import SelectionOfPaymentMethod from "@component/SelectionOfPaymentMethod"
import { Box, Button, CurrencyExchangeRoundedIcon, IconButton, ListItemIcon, MenuItem, Tooltip, Typography } from "@component/UIComponents"
import { useCashTransferFormData, useRegisterCashTransfer } from "@hook/useMovements"
import { useEffect, useCallback, useState } from "react"

const CashTransfer = ({ boxBalance=0, boxRef=undefined, variantListButton="button" }) => {
    const [ showTransferWindowBetweenBoxes, setShowTransferWindowBetweenBoxes ] = useState(false)
    const [ showTransferDetailWindow, setShowTransferDetailWindow ] = useState(false)

    const { values:cashTransfer, setFieldValue:setFieldValueCashTransfer, validateValues:validateValuesCashTransfer } = useCashTransferFormData({ openModal: showTransferWindowBetweenBoxes })
    const { payments:paymentsCashTransfer, observation:observationCashTransfer, outputRef } = cashTransfer
    const { registerCashTransfer } = useRegisterCashTransfer()
console.log(outputRef)
    const handleSubmitCashTransfer = () => {
        const { type } = validateValuesCashTransfer(cashTransfer)
        if(type === "errors") return
        registerCashTransfer(cashTransfer, {
            closeForm: () => setShowTransferWindowBetweenBoxes(false)
        })
    }

    const openDetailsVariableExpense = () => {
        setShowTransferDetailWindow(true)
        setFieldValueCashTransfer("outputRef", boxRef)
    }

    const handleCreateVariableExpense = (value) => {
        setShowTransferWindowBetweenBoxes(true)
        setFieldValueCashTransfer("observation", value)
    }

    const buttonText = "Generar traslado de caja"

    const loadOutPutRef = useCallback(() => {
        setFieldValueCashTransfer("outputRef", boxRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxRef])

    useEffect(() => {
        loadOutPutRef()
    }, [loadOutPutRef])

    return(
        <>
            {variantListButton === "button" &&
                <Button startIcon={<CurrencyExchangeRoundedIcon />} onClick={openDetailsVariableExpense} >
                    {buttonText}
                </Button>
            }
            {variantListButton === "iconButton" &&
                <Tooltip title={buttonText} arrow >
                    <IconButton onClick={openDetailsVariableExpense} >
                        <CurrencyExchangeRoundedIcon />
                    </IconButton>
                </Tooltip>
            }
            {variantListButton === "mobileMenu" &&
                <MenuItem onClick={openDetailsVariableExpense} >
                    <ListItemIcon>
                        <CurrencyExchangeRoundedIcon />
                    </ListItemIcon>
                    {buttonText}
                </MenuItem>
            }
            <SelectionOfPaymentMethod
                getOptionDisabled={false}
                skipOptions={Boolean(boxRef) ? [boxRef] : undefined}
                title={`Traslado de caja`}
                open={showTransferWindowBetweenBoxes}
                onClose={() => setShowTransferWindowBetweenBoxes(false)}
                hideTabs
                noPayoutLimit
                maxAmount={boxBalance}
                paymentMethodsAvailable={{ advance: false, credit: false, cashier: true }}
                onChange={(n, x, list) => setFieldValueCashTransfer("payments", list)}
                onDeletePayment={(n, x, list) => setFieldValueCashTransfer("payments", list)}
                payments={paymentsCashTransfer}
                onSubmit={handleSubmitCashTransfer}
                topComponent={<>
                    <Typography fontSize={'.875rem'} fontWeight={'bold'} > Observación: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {observationCashTransfer}. </Box> </Typography>
                </>}
            />
            <DetailsWindow
                title="Ingresa detalles del traslado antes de continuar"
                open={showTransferDetailWindow}
                onClose={() => setShowTransferDetailWindow(false)}
                onSubmit={handleCreateVariableExpense}
            />
        </>
    )
}

export default CashTransfer