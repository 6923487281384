import { Box, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import { useMemo } from 'react'

const BoxContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: "space-between",
    width: "100%",
    fontSize: ".850rem",
    fontWeight: "bold",
    '& .leftText': {
        display: "flex",
        gap: theme.spacing(1),
    },
    '& .rightText': {
        display: "flex",
        gap: theme.spacing(1),
    },
}))

const PurchaseInformationRow = ({ consecutive, invoiceNumber, dueDate, subTotal=0, tax=0, reteFuente=0, total=0, balance=0, payments=[] }) => {
    const { getDateFormats } = usePanel()
    const transformPayments = useMemo(() => {
        let p = [...payments]
        p = p.map((n) => currencyFormat(n?.amount??0))
        p = p.join(",")
        return p
    }, [payments])
    return(
        <>
            <BoxContainer pr={7} >
                <Box className='leftText' >
                    {Boolean(invoiceNumber) ?
                        <Box> #FAC {invoiceNumber} </Box>
                        :
                        <Box> #C {consecutive} </Box>
                    }
                    <Box> (FV {getDateFormats(dueDate).ddmmyyyy}) </Box>

                    <Box> {Boolean(transformPayments) ? `(AB ${transformPayments})` : ""} </Box>
                </Box>
                <Box className='rightText' >
                    <Box> (Valor { currencyFormat(subTotal+tax) }) </Box>
                    { reteFuente >= 1 &&
                        <Box> (RT { currencyFormat(reteFuente) }) </Box>
                    }
                    <Box> (Total { currencyFormat(balance) }) </Box>
                </Box>
            </BoxContainer>
        </>
    )
}

export default PurchaseInformationRow