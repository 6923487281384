import { Box, Dialog, Typography } from '@component/UIComponents'
import { useGetProductSalesHistory } from "../Products/hooks/useProducts"
import { currencyFormat } from "@util/currencyFormat"
import ActionsList from '@component/ActionsList'
import { useProductsSaleHistoryMenu } from './hooks/useProductsSaleHistory'
import DialogInvoice from '@feature/Reports/Invoice/components/DialogInvoice'
import { useState } from 'react'
import SuperDataGrid from '@component/SuperDataGrid'
import { usePanel } from '@hook/usePanel'
import dayjs from 'dayjs'
import { useCallback } from 'react'

const ProductSalesHistory = ({ productId, open=false, onClose=() => null }) => {
    const [date, setDate] = useState({
        startDate: dayjs().startOf("week"),
        endDate: dayjs().endOf("day"),
    })
    const { getDateFormats } = usePanel()
    const [selection, setSelection] = useState({ viewInvoice: null })
    const { viewInvoice } = selection
    const { history, isFetching } = useGetProductSalesHistory({ ref: productId, ...date })
    const { primaryMenu } = useProductsSaleHistoryMenu()
    const Buttons = (props={}) => <ActionsList variant='iconButton' options={primaryMenu(props, {
        onViewInvoice: (saleId) => setSelection((prevState) => ({...prevState, viewInvoice: saleId}))
    })} />
    const columns = [
        {minWidth: 140, maxWidth: 140, field: "createdAt", headerName: "Fecha", renderCell: ({ row }) => getDateFormats(`${row?.createdAt}`).ddmmyyyyhhmm},
        {flex: 1, minWidth: 200, field: "name", headerName: "Producto"},
        {flex: 1, minWidth: 150, field: "customer", headerName: "#FAC", renderCell: ({row}) => ` ${(row?.customer?.name??"VARIOS")} `},
        {minWidth: 60, maxWidth: 60, field: "quantity", headerName: "Cant.", renderCell: ({ row }) => `x${(row?.quantity??0)}`},
        {minWidth: 120, maxWidth: 120, field: "costPrice", headerName: "Precio de venta", renderCell: ({ row }) => ` ${currencyFormat((row?.costPrice??0))} `},
        {minWidth: 60, maxWidth: 60, field: "consecutive", headerName: "#FAC", renderCell: ({row}) => ` ${(row?.consecutive??0)} `},
        {minWidth: 50, maxWidth: 50, field: " ", headerName: " ", renderCell: ({row}) => <Buttons {...row} />},
    ]
    const handleChangeDateRange = (fields=[], values=[]) => {
        if( fields.length === 2 ){
            setDate((prevState) => ({...prevState,
                startDate: dayjs((values[0]??"")),
                endDate: dayjs((values[1]??"")),
            }))
            return
        }
        if( fields.length === 1 ){
            let field = (fields[0] === "sd") ? "startDate" : (fields[0] === "ed") ? "endDate" : undefined
            setDate((prevState) => ({...prevState,
                [field]: dayjs((values[0]??""))
            }))
            return
        }
    }
    const quickList = useCallback((list) => {
        let currentHistory = [...list]
        let quantityOfProductSold = currentHistory.reduce((acc, val) => ((val?.quantity??0)+acc),0)
        let totalProductSales = currentHistory.reduce((acc, val) => (((val?.costPrice??0)*(val?.quantity??0))+acc),0)

        return [
            {primary: `x${quantityOfProductSold}`, secondary: "Cantidad de producto vendido"},
            {primary: currencyFormat((totalProductSales)), secondary: "Total venta del producto"},
        ]
    }, [])
    return(
        <>
            <Dialog maxWidth="md" PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Box px={2} py={1}  >
                    <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >Historial de venta del producto</Typography>
                </Box>
                <SuperDataGrid
                    onQuickList={quickList}
                    sd={date.startDate}
                    ed={date.endDate}
                    onDateRangeChange={handleChangeDateRange}
                    dateRange
                    loading={isFetching}
                    columns={columns}
                    rows={history}
                    filterBy={(param) => `${(param?.name??"")} ${(param?.consecutive??0)} ${(param?.customer?.name??"VARIOS")}`}
                />
            </Dialog>
            <DialogInvoice saleRef={viewInvoice} open={Boolean(viewInvoice)} onClose={() => setSelection((prevState) => ({...prevState, viewInvoice: null}))} />
        </>
    )
}

export default ProductSalesHistory