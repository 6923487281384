import { MonetizationOnRoundedIcon } from "@component/UIComponents"
import { preventAction } from "@util/helpers"
import dayjs from "dayjs"
import { useCallback } from "react"

export const useClosingDayActions = () => {

    const primaryActions = useCallback(() => {
        return [
            {
                title: "Generar cierre del dia",
                icon: <MonetizationOnRoundedIcon />,
                onClick: () => preventAction({
                    text: `¿Desea generar el cierre del dia en este momento?. Al realizar el cierre del dia se generara un resumen de la venta y los movimientos del dia, todas las ventas o movimientos realizados después de generar el cierre del dia serán registrados con fecha del ${dayjs().add(1, "day").format("DD-MM-YYYY")}`,
                    confirmButtonText: "Generar cierre",
                    cancelButtonText: "Cancelar"
                })
            }
        ]
    }, [])

    return {
        primaryActions
    }
}