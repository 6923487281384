import { Box, createFilterOptions } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useGetPurchases } from "../hooks/usePurchase"
import { currencyFormat } from '@util/currencyFormat'
import { useMemo } from 'react'

const PurchaseFinder = ({ filters={}, onChange=()=>null, ignoreOptions=[], onTheRight=()=><></>, fullWidth=true }) => {
    const { purchases, isFetching } = useGetPurchases({...filters, variant: "purchases"})
    const options = useMemo(() => {
        let currentPurchases = [...purchases]
        if( Array.isArray(ignoreOptions) && ignoreOptions.length >= 1 ){
            currentPurchases = currentPurchases.filter((n) => !ignoreOptions.some((b) => (b??"") === (n?._id??"")) )
        }
        return currentPurchases
    }, [purchases, ignoreOptions])
    const filterOptions = createFilterOptions({
        stringify: (option) => `${(option?.provider?.name??"")} ${(option?.invoiceNumber??"")} ${(option?.consecutive??"")}`,
      });
    return(
        <>
            <Box display={'flex'} width={'100%'} alignItems={'center'} >
                <Autocomplete
                    filterOptions={filterOptions}
                    fullWidth={fullWidth}
                    loading={isFetching}
                    label="Compras"
                    options={options}
                    onChange={onChange}
                    renderOption={(props, option) => {
                        return(
                            <li {...props} key={option._id} >
                                <Box width={'100%'} fontSize={'.875rem'} >
                                    <Box lineHeight={1} fontWeight={'bold'} >
                                        {(option?.provider?.name??"")} -
                                        { Boolean((option?.invoiceNumber??"")) ?
                                            <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main  }} > #FAC {(option?.invoiceNumber??0)} </Box>
                                            :
                                            <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main  }} > #C {(option?.consecutive??0)} </Box>
                                        }
                                    </Box>
                                    
                                    <Box fontWeight={'bold'} lineHeight={1} > {currencyFormat((option?.total??0))} </Box>
                                </Box>
                            </li>
                        )
                    }}
                />
                <Box>
                    {onTheRight(options)}
                </Box>
            </Box>
        </>
    )
}

export default PurchaseFinder