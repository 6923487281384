import { api, mutationConfig } from "../splitApi";
import { OrderPurchaseTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        savePurchaseOrder: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/save/purchase/order", body };
            },
            invalidatesTags: [...OrderPurchaseTag]
        }),
        getOrdersSuppliers: build.query({
            query: (params=undefined) => ({
                url: `/get/orders/suppliers`,
                params
            }),
            providesTags: [...OrderPurchaseTag]
        }),
        getOrderSupplier: build.query({
            query: (params=undefined) => ({
                url: `/get/orders/suppliers`,
                params
            }),
            providesTags: [...OrderPurchaseTag]
        }),
    }),
    overrideExisting: false,
});

export const {
    useSavePurchaseOrderMutation,
    useGetOrdersSuppliersQuery,
    useGetOrderSupplierQuery,
} = Endpoints;
