import { populateArrayFromQuery } from "@util/helpers"
import { useCallback } from "react"

export const useAutocompleteManipulateValue = (props={}) => {
    const { multiple=false, value=null, list=[], queryValue="", compare=(n) => (n?.name??"") } = props
    const getAutocompleteValue = useCallback(() => {
        if( multiple ){
            let defaultValue = Array.isArray(value) ? value : []
            if( Boolean(queryValue) ){
                defaultValue = populateArrayFromQuery(list, {
                    compare,
                    query: queryValue,
                    separator: ","
                })
            }
            return defaultValue
        }else{
            if( Boolean(queryValue) ){
                return list.find((n) => compare(n) === queryValue) ||  null
            }else{
                return value
            }
        }
    }, [multiple, value, list, queryValue, compare])

    return {getAutocompleteValue}
}