import Products from './Products'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '@util/routes'
import { useMemo } from 'react'
import TabAppContext from '@component/TabAppContext'
import Packaging from './Packaging'
import Categories from './Categories'
const { inventory } = routes

const Inventory = () => {
    const navigate = useNavigate()
    const { tab = "products" } = useParams()
    const handleChange = (event, newValue) => navigate(inventory(newValue))
    const tabs = useMemo(() => {
        let resp = []
        resp.push(
            { label: "Productos", value: "products", component: <Products /> },
            { label: "Categorías", value: "categories", component: <Categories /> },
            { label: "Embalajes", value: "packaging", component: <Packaging /> },
        )
        resp.map((n, index) => ({...n, _id: (index+1)}))
        return resp
    }, [])
    return (
        <>
            <TabAppContext
                tab={tab}
                tabs={tabs}
                onChange={handleChange}
            />
        </>
    )
}

export default Inventory