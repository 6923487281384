import { Button, FilterListRoundedIcon, IconButton, ListItemIcon, MenuItem, Tooltip } from "@component/UIComponents"
import { forwardRef } from "react"

const FilterButton = forwardRef(({ onClick=()=>null, title="Filtros", icon=<FilterListRoundedIcon />, variant="button" }, ref) => {

    switch (variant) {
        case "button":
        return(
            <>
                <Button ref={ref} startIcon={icon} onClick={onClick} >
                    {title}
                </Button>
            </>
        )
        case "iconButton":
        return(
            <>
                <Tooltip arrow title={title} >
                    <IconButton ref={ref} size="small" color="primary" onClick={onClick} >
                        {icon}
                    </IconButton>
                </Tooltip>
            </>
        )
        case "mobileMenu":
        return(
            <>
                <MenuItem ref={ref} onClick={onClick} >
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    {title}
                </MenuItem>
            </>
        )
        default:
        return <></>;
    }

})

export default FilterButton