import { CloudDownloadIconRounded, CloudUploadIconRounded, IconButton, Stack, Tooltip } from "@component/UIComponents"
import { readBook } from "@util/xlsx"

const ActionWithData = ({ downloadData=false, uploadData=false, onDownloadInformation=()=>null }) => {
    return(
        <>
        <Stack direction={'row'} justifyContent={'space-between'} >
            <Stack>
                {downloadData &&
                    <Tooltip arrow title="Descargar información" >
                        <IconButton color='primary' onClick={onDownloadInformation} >
                            <CloudDownloadIconRounded />
                        </IconButton>
                    </Tooltip>
                }
                {uploadData &&
                    <Tooltip arrow title="Cargar información desde excel" >
                        <>
                            <IconButton color='primary' onClick={() => document.getElementById("excelDataGrid").click()} >
                                <CloudUploadIconRounded />
                            </IconButton>
                            <input type="file" id="excelDataGrid" onChange={(event) => readBook(event)} style={{ display: 'none' }} />
                        </>
                    </Tooltip>
                }
            </Stack>
            <Stack>
            </Stack>
        </Stack>
        </>
    )
}

export default ActionWithData