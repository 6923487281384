import { Box, DataGrid, DeleteIconRounded, IconButton, Stack, Typography, styled } from "@component/UIComponents"
import { useURLParams } from "@hook/useURLParams"
import { calculateProfitMargin, currencyFormat } from "@util/currencyFormat"
import { usePOS } from "../hooks/usePOS"

const DataGridStyled = styled(DataGrid)(() => ({
    border: "none",
    '& .MuiDataGrid-columnHeaders': {
        display: "none"
    }
}))

const Item = ({ quantity, salePrice, costPrice, name, productId }) => {
    const { addQuery } = useURLParams()

    return(
        <>
        <Stack className="cell_dataContainer" width={'100%'} onClick={() => addQuery({ ref: productId })} >
            <Typography fontWeight={'bold'} > {name} </Typography>
            <Stack direction={'row'} width={'100%'} >
                <Stack direction={'row'} gap={2} width={'100%'} >
                    <Typography sx={{ color: (theme) => theme.palette.success.main, fontWeight: "bold" }} >
                        { currencyFormat(quantity, {applyCurrencyFormat: false}) } x
                    </Typography>
                    <Typography>
                        { currencyFormat(salePrice) }
                    </Typography>
                    <Typography>
                        { calculateProfitMargin(salePrice, (salePrice-costPrice)) } %
                    </Typography>
                </Stack>
                <Stack>
                    <Typography>
                        { currencyFormat((salePrice*quantity)) }
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}

const SaleList = ({ list=[], setFieldValue=undefined, apiRef=undefined }, ref) => {
    const { delItemList } = usePOS({ list })
    const columns = [
        {
            flex: 1,
            field: "name",
            headerName: " ",
            renderCell: ({ row }) => <Item {...row} />
        },
        {
            maxWidth: 50,
            minWidth: 50,
            align: "center",
            field: " ",
            headerName: " ",
            renderCell: ({ row }) => <>
                <IconButton color="error" onClick={() => delItemList((row?.productId??""), setFieldValue)} >
                    <DeleteIconRounded />
                </IconButton>
            </>
        },
    ]

    return(
        <Box mt={1} >
            <DataGridStyled
                apiRef={apiRef}
                rowHeight={60}
                getRowId={params => params.productId}
                localeText={{
                    noRowsLabel: "No hay productos seleccionados"
                }}
                autoHeight
                hideFooter
                rows={[...list].reverse()}
                columns={columns}
            />
        </Box>
    )
}

export default SaleList