import { useCallback, useMemo, useState } from "react"
import { useProductPreSelectionFormData, usePurchaseOrderFormData } from "./useControlPurchaseOrderForms"
import { useSavePurchaseOrder } from "./useSavePurchaseOrder"
import { useEffect } from "react"
import { routes } from "@util/routes"
import { useNavigate } from "react-router-dom"
const { ordersToSuppliers } = routes

const useControllerOrderEntry = (props={}) => {
    const { updateOrderForm=()=>null, resetForm=()=>null, editableOrder=null } = props
    const [listOfProductsForTheOrder, setListOfProductsForTheOrder] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const showPreSelectionWindow = useMemo(() => Boolean(selectedProduct?._id) ,[selectedProduct])
    const { validateValues } = usePurchaseOrderFormData()
    const { savePurchaseOrder:savePurchaseOrderMutation, isLoadingSavePurchaseOrder } = useSavePurchaseOrder()
    const navigate = useNavigate()

    const addProductToOrderList = useCallback((product=null) => {
        if( Boolean(product?.productId) ){
            const currentList = [...listOfProductsForTheOrder]
            const productIndex = currentList.findIndex((b) => (b?.productId??null) === (product?.productId??null))
            if( productIndex >= 0 ){
                currentList[productIndex] = product
            }else{
                currentList.push(product)
            }
            updateOrderForm("products", currentList)
            setListOfProductsForTheOrder(currentList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfProductsForTheOrder])
    
    const removeProductToOrderList = useCallback((productId=null) => {
        if( Boolean(productId) ){
            const currentList = [...listOfProductsForTheOrder]
            const productIndex = currentList.findIndex((b) => (b?.productId??null) === (productId??null))
            if( productIndex >= 0 ){
                currentList.splice(productIndex, 1)
            }
            updateOrderForm("products", currentList)
            setListOfProductsForTheOrder(currentList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfProductsForTheOrder])
    
    const totalizeProducts = useMemo(() => listOfProductsForTheOrder.reduce((acc, val) => (acc+((val?.purchaseCost??0)*(val?.quantity??0))), 0), [listOfProductsForTheOrder])

    const productsTable = useMemo(() => {
        const currentProductList = [...listOfProductsForTheOrder]
        currentProductList.push({ total: totalizeProducts, productId: (currentProductList?.length??0)+1 })
        updateOrderForm("total", totalizeProducts)
        return currentProductList
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfProductsForTheOrder, totalizeProducts])

    const savePurchaseOrder = useCallback((props={}) => {
        const { type } = validateValues(props)
        if(type === "errors") return
        savePurchaseOrderMutation(props, {
            closeForm: () => {
                setListOfProductsForTheOrder([])
                resetForm()
                navigate(ordersToSuppliers({ orderPurchaseId: "" }))
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetForm])

    const loadEditableOrder = useCallback(() => {
        if( Boolean(editableOrder?._id) ){
            updateOrderForm("_id", editableOrder._id)
            updateOrderForm("provider", editableOrder.provider)
            updateOrderForm("products", editableOrder.products)
            setListOfProductsForTheOrder(editableOrder.products)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableOrder])

    useEffect(() => {
        loadEditableOrder()
    }, [loadEditableOrder])

    return {
        selectedProduct,
        showPreSelectionWindow,
        setSelectedProduct,
        addProductToOrderList,
        listOfProductsForTheOrder,
        productsTable,
        totalizeProducts,
        removeProductToOrderList,
        savePurchaseOrder,
        isLoadingSavePurchaseOrder
    }
}

export const useControllerPreSelectedProduct = (props={}) => {
    const { onSubmitProduct=()=>null, values={}, currentProduct=null, closeForm=()=>null } = props
    const { validateValues } = useProductPreSelectionFormData()
    const currentValue = useMemo(() => {
        return {
            freight: 0,
            name: (currentProduct?.name??""),
            packaging: (values?.packaging??null),
            productId: (currentProduct?._id??""),
            purchaseCost: (values?.purchaseCost??0),
            quantity: (values?.quantity??1),
            tax: (currentProduct?.tax??null),
        }
    }, [values, currentProduct])
    
    const productShippingHandler = useCallback(() => {
        const { type } = validateValues(currentValue)
        if( type === "errors" ) return
        if( Boolean(onSubmitProduct) ){
            onSubmitProduct(currentValue)
            closeForm()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue, onSubmitProduct])

    return {
        productShippingHandler
    }
}

export default useControllerOrderEntry