import { Box } from '@component/UIComponents'
import { getToken } from "@util/helpers"
import { useCallback, useEffect, useMemo, useState } from "react"
import { routes } from "@util/routes"
import { useLocation, useNavigate } from "react-router-dom"
import LoadProgress from "./LoadProgress"
import { useGetBusinessInformation } from '@feature/Settings/BusinessInformation/hooks/useBusiness'
const { login, singIn, home } = routes

const SafeRedirect = ({ children }) => {
    const { business, isSuccess } = useGetBusinessInformation()
    const [showPreload, setShowPreload] = useState(true)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const tk = useMemo(() => getToken(), [])
    const redirect = useCallback(() => {
        if( !Boolean(tk) ){
            if( pathname !== login() && pathname !== singIn() ){
                navigate(login())
            }
            setShowPreload(false)
        }else{
            if( isSuccess ){
                if( Boolean(business?._id) ){
                    if( pathname === login() || pathname === singIn() ){
                        navigate(home())
                    }
                    setShowPreload(false)
                }
            }
        }
    }, [tk, pathname, navigate, business, isSuccess])

    useEffect(() => {
        redirect()
    }, [redirect])

    if( showPreload ){
        return(
            <Box width={'100vw'} height={'100vh'} display={'flex'} alignItems={'center'} >
                <LoadProgress />
            </Box>
        )
    }else{
        return(
            <>
                {children}
            </>
        )
    }
}

export default SafeRedirect