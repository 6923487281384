import { calculateProfitMarginSale } from "@util/helpers";
import dayjs from "dayjs";
import { useCallback } from "react"

export const useCalculateSales = () => {

    const total = useCallback((sale) => {
        const isCopy = (sale?.isCopy??false)
        const total = isCopy ? (sale?.diffTotal??0) : (sale?.total??0)
        const totalProfitCurrency = isCopy ? (sale?.diffCurrency??0) : (sale?.totalProfitCurrency??0)
        const totalProfitPercentage = isCopy ? calculateProfitMarginSale({ profit: totalProfitCurrency, sale: total }) : (sale?.totalProfitPercentage??0)
        return { total, totalProfitCurrency, totalProfitPercentage }
    }, [])

    const groupSalesByDays = useCallback((sales=[]) => {
        const summary = {};
        [...sales].forEach(sale => {
            const date = dayjs(sale.createdAt).format("MM-DD-YYYY");
            if (summary[date]) {
                summary[date].results.push(sale)
                summary[date].total += total(sale).total;
                summary[date].totalProfitCurrency += total(sale).totalProfitCurrency;
            } else {
                summary[date] = {
                    date,
                    results: [sale],
                    total: total(sale).total,
                    totalProfitCurrency: total(sale).totalProfitCurrency,
                };
            }
        });
        let result = Object.values(summary);
        result = result.map((n) => ({...n, totalProfitPercentage: calculateProfitMarginSale({ profit: n.totalProfitCurrency, sale: n.total })}))
        return result
    }, [total])

    const totalizeReport = useCallback((sales=[]) => {
        const groupedSales = groupSalesByDays(sales)
        groupedSales.forEach((n) => {
            delete n.results
        })
        return groupedSales
    }, [groupSalesByDays])

    return {
        totalizeReport,
        total
    }
}