import Form from '@component/Form'
import NumericFormat from '@component/NumericFormat'
import { Button, Dialog, DialogActions, DialogContent } from '@component/UIComponents'
import { useFixedCostGenerateAmountFormData, useGenerateNewExpenseAmount } from '../hooks/useFixedCosts'

const GenerateAmount = ({ fixedCost=null, onClose=()=>null }) => {
    const open = Boolean(fixedCost?._id)
    const { values, setFieldValue, validateValues, resetValues } = useFixedCostGenerateAmountFormData({ fixedCost })
    const { amount } = values
    const { generateNewExpenseAmount, isLoading } = useGenerateNewExpenseAmount()
    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        generateNewExpenseAmount(values, {
            clearForm: () => resetValues(),
            closeForm: () => onClose(),
        })
    }
    return(
        <Dialog maxWidth="xs" fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} open={open} onClose={onClose} >
            <Form onSubmit={handleSubmit} >
                <DialogContent>
                    <NumericFormat
                        onChange={({ floatValue }) => setFieldValue("amount", floatValue)}
                        value={amount}
                        helperText={'El monto sera añadido al valor acumulado del gasto fijo'}
                        fullWidth
                        placeholder="Monto"

                    />
                </DialogContent>
                <DialogActions>
                    <Button loading={isLoading} type='submit' > Guardar </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default GenerateAmount