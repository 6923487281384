import { useArchiveProductCategoryMutation } from "@query/Products"
import { asynchronousResponseHandler } from "@util/helpers"

export const useArchiveProductCategory = () => {
    const [ archiveProductCategoryMutation, { isLoading } ] = useArchiveProductCategoryMutation()

    const archiveProductCategory = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await archiveProductCategoryMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((resp?.payload?.isArchived??false)) ? "La categoría se archivo exitosamente." : "La categoría se recupero exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archiveProductCategory,
        isLoadingArchiveProductCategory: isLoading,
    }
}