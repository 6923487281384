import { Box, Divider, Skeleton } from '@component/UIComponents'

const SkeletonInvoice = () => {
    return(
        <>
            <Box  display={'flex'} flexDirection={'column'} alignItems={'center'} >
                <Skeleton variant="text" width={"40%"} height={30} />
                <Skeleton variant="text" width={"30%"} height={30} />
                <Skeleton variant="text" width={"50%"} height={30} />
            </Box>

            <Box  display={'flex'} justifyContent={'space-between'} mt={3} >
                <Box display={'flex'} flexDirection={'column'} >
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="text" width={150} height={30} />
                </Box>
                <Box display={'flex'} >
                    <Skeleton variant="text" width={200} height={30} />
                </Box>
            </Box>
            <Box mt={3} display={'flex'} flexDirection={'column'} >
                <Skeleton variant="text" width={250} height={30} />
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={280} height={30} />
                <Skeleton variant="text" width={240} height={30} />
            </Box>

            <Box mt={3} display={'flex'} flexDirection={'column'} >
                <Box>
                    <Skeleton variant="text" width={400} height={30} />
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} gap={1} >
                        <Skeleton variant="text" width={50} height={30} />
                        <Skeleton variant="text" width={150} height={30} />
                    </Box>
                    <Box >
                        <Skeleton variant="text" width={150} height={30} />
                    </Box>
                </Box>
                <Divider />
            </Box>
            <Box mt={3} display={'flex'} justifyContent={'end'} >
                <Skeleton variant="text" width={200} height={30} />
            </Box>
        </>
    )
}

export default SkeletonInvoice