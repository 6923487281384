import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useMemo } from "react"

export const useCategoriesControllerForm = (props={}) => {
    const { defaultValues = undefined } = props
    const initialValues = useMemo(() => ({ name: "" }), [])

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre de la categoría es requerido")
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}