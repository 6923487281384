import SuperDataGrid from '@component/SuperDataGrid'
import { Dialog, DialogContent, Typography } from '@component/UIComponents'
import { useGetSalesSummary } from '../hooks/useSales'
import { currencyFormat } from '@util/currencyFormat'

const ReportSummary = ({ open=true, onClose=()=>null, filters={} }) => {
    const { salesSummary, isFetching } = useGetSalesSummary({ variant: "saleSummaryTotals", ...filters }, {skip: open})
    const columns = [
        {flex: 1, field: "wallet", headerName: "", renderCell: ({ row }) => {
            return(
                <Typography fontWeight={'bold'} fontSize={'.875rem'} >
                    { (row?.wallet === "consignment" && row?.oldInvoicePayment === true) && "Consignaciones a ventas de días anteriores" }
                    { (row?.wallet === "consignment" && row?.oldInvoicePayment === false) && "Consignaciones a ventas del dia" }
                    { (row?.wallet === "cash" && row?.oldInvoicePayment === true) && "Pagos en efectivo a ventas de días anteriores" }
                    { (row?.wallet === "cash" && row?.oldInvoicePayment === false) && "Pagos en efectivo a ventas del dia" }
                    { (row?.wallet === "creditsOfTheDay") && "Créditos pendiente por cobrar del dia" }
                </Typography>
            )
        }},
        {maxWidth: 150, minWidth: 150, field: "total", headerName: "", renderCell: ({ row }) => {
            return(
                <Typography fontWeight={'bold'} fontSize={'.875rem'} sx={{ color: (theme) => theme.palette.primary.main }} >
                    {`${currencyFormat((row?.total??0))}`}
                </Typography>
            )
        }},
    ]
    return(
        <>
            <Dialog fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <DialogContent>
                    <SuperDataGrid
                        hideHeader
                        loading={isFetching}
                        useSearchBar={false}
                        columns={columns}
                        rows={salesSummary}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReportSummary