import { DataGrid, DeleteIconRounded, Dialog, DialogContent, Divider, Grid, IconButton, Typography, styled } from '@component/UIComponents'
import { useArchivingPaymentsPurchases, useGetPurchase } from '../hooks/usePurchase'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '@util/currencyFormat'
import { usePanel } from '@hook/usePanel'
import { preventAction } from '@util/helpers'

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-columnHeaders': {
        display: 'none'
    }
}))
const DataGridTotalizeStyled = styled(DataGrid)(() => ({
    border: "none",
    '& .MuiDataGrid-columnHeaders': {
        display: 'none'
    }
}))

const PaymentManagement = ({ open=false, onClose=()=>null, purchaseId=null, defaultPayments=[] }) => {
    const { getDateFormats } = usePanel()
    const { t }  = useTranslation()
    let { purchase, isFetching } = useGetPurchase({ ref: purchaseId })
    const { archivingPaymentsPurchases } = useArchivingPaymentsPurchases()
    const {payments, paymentsArchived} = useMemo(() => {
        let paymentsArchived = []
        let payments = []
        if( defaultPayments.length >= 1 ){
            payments = defaultPayments
        }else{
            if( Boolean((purchase?.payments??null)) ){
                payments = (purchase?.payments??[])
                paymentsArchived = payments.filter((n) => (n?.isArchived??false) === true)
                payments = payments.filter((n) => (n?.isArchived??false) === false)
            }
        }
        return { payments, paymentsArchived }
    }, [purchase, defaultPayments])

    const totalPayments = useMemo(() => payments.reduce((acc, payment) => (acc+(payment?.amount??0)),0), [payments])

    const totalizeRows = useMemo(() => {
        let r = [
            { field: "Total de la factura", amount: currencyFormat((purchase?.total??0)) },
            { field: "Pagos realizados", amount: currencyFormat(totalPayments) },
            { field: "Saldo de la factura", amount: currencyFormat(((purchase?.total??0)-totalPayments)) },
        ]
        r = r.map((n, index) => ({...n, _id: index+1}))
        return r
    }, [totalPayments, purchase])

    const deletePayment = (payment) => {
        preventAction({
            text: `¿Desea eliminar el pago por ${currencyFormat((payment?.amount??0))}?. El dinero sera reintegrado a ${t(payment?.box??"")}.`,
            onSubmit: () => archivingPaymentsPurchases({ payments: [(payment?._id??"")] })
        })
    }

    const columns = [
        {minWidth: 140, maxWidth: 140, field: "user", renderCell: ({ row }) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}` },
        {flex: 1, field: "box", renderCell: ({ row }) => `${t(row?.box)}` },
        {minWidth: 160, maxWidth: 160, field: "amount", renderCell: ({ row }) => `${currencyFormat((row?.amount??0))}`  },
        {minWidth: 160, maxWidth: 160, field: "createdAt", renderCell: ({ row }) => `${getDateFormats(row?.createdAt).ddmmyyyyhhmm}` },
        {minWidth: 40, maxWidth: 40, align: "center", field: " ", renderCell: ({ row }) => <>
            <IconButton color='error' onClick={() => deletePayment(row)} >
                <DeleteIconRounded />
            </IconButton>
        </> },
    ]
    const columns2 = [
        {flex: 1, field: "field"},
        {flex: 1, field: "amount"},
    ]
    const columns3 = [
        {minWidth: 140, maxWidth: 140, field: "user", renderCell: ({ row }) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}` },
        {flex: 1, field: "box", renderCell: ({ row }) => `${t(row?.box)}` },
        {minWidth: 160, maxWidth: 160, field: "amount", renderCell: ({ row }) => `${currencyFormat((row?.amount??0))}`  },
        {minWidth: 160, maxWidth: 160, field: "createdAt", renderCell: ({ row }) => `${getDateFormats(row?.createdAt).ddmmyyyyhhmm}` },
    ]

    return(
        <>
            <Dialog transitionDuration={0} PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} maxWidth="md" >
                <DialogContent>
                    <Grid container >
                        <Typography fontWeight={'bold'} fontSize={'.875rem'} > Disponibles: </Typography>
                        <Divider sx={{ mb: 1 }} />
                        <Grid xs={12} >
                            <DataGridStyled
                                rowHeight={25}
                                getRowId={(param) => param._id}
                                localeText={{
                                    noRowsLabel: "No se han realizado pagos a esta compra"
                                }}
                                hideFooter
                                autoHeight
                                columns={columns}
                                rows={payments}
                                loading={isFetching}
                            />
                        </Grid>
                        <Grid xs={8} />
                        <Grid xs={4} >
                            <DataGridTotalizeStyled
                                rowHeight={25}
                                getRowId={(param) => param._id}
                                localeText={{
                                    noRowsLabel: ""
                                }}
                                hideFooter
                                autoHeight
                                columns={columns2}
                                rows={totalizeRows}
                                loading={isFetching}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <Typography fontWeight={'bold'} fontSize={'.875rem'} > Eliminados: </Typography>
                            <Divider sx={{ mb: 1 }} />
                            <DataGridStyled
                                rowHeight={25}
                                getRowId={(param) => param._id}
                                localeText={{
                                    noRowsLabel: "No se han eliminado pagos de esta compra"
                                }}
                                hideFooter
                                autoHeight
                                columns={columns3}
                                rows={paymentsArchived}
                                loading={isFetching}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PaymentManagement