import { Grid } from "@component/UIComponents"
import SuperDataGrid from "@component/SuperDataGrid"
import { useGetSaleReportFilters, useGetSales } from "../Sales/hooks/useSales"
import { currencyFormat } from "@util/currencyFormat"
import { usePanel } from "@hook/usePanel"
import { useTranslation } from "react-i18next"
import ActionsList from "@component/ActionsList"
import Filters from "../Sales/components/Filters"
import ComponentTitle from "@component/ComponentTitle"

const Deliveries = () => {
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const filters = useGetSaleReportFilters()
    const { results, isFetching } = useGetSales({ variant: "deliveryReport", ...filters })

    const columns = [
        { flex: 1, minWidth: 220, field: "customer", headerName: t('name'), renderCell: ({row}) => `${t((row?.customer?.name??"some"))}` },
        { maxWidth: 160, minWidth: 160, field: "deliveryName", headerName: t('Domiciliario'), renderCell: ({row}) => `${(row?.delivery?.delivery?.name??"")}`},
        { maxWidth: 120, minWidth: 120, field: "deliveryAmount", headerName: t('Valor del domicilio'), renderCell: ({row}) => `${currencyFormat((row?.delivery?.deliveryAmount??0))}`},
        { maxWidth: 120, minWidth: 120, field: "deliveryPaymentMethod", headerName: t('Tipo de pago domicilio'), renderCell: ({row}) => `${t((row?.delivery?.deliveryPaymentMethod??0))}`},
        { maxWidth: 80, minWidth: 80, align: "center", field: "consecutive", headerName: t('#FAC'), },
        { maxWidth: 120, minWidth: 120, field: "total", headerName: t('sale'), renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
        { maxWidth: 120, minWidth: 120, field: "totalPayments", headerName: t('Pagos'), renderCell: ({row}) => `${currencyFormat((row?.totalPayments??0))}`},
        { maxWidth: 120, minWidth: 120, field: "balance", headerName: t('balance'), renderCell: ({row}) => `${currencyFormat((row?.balance??0))}`},
        { maxWidth: 130, minWidth: 130, field: "seller", headerName: t('seller'), renderCell: ({row}) => `${t((row?.seller?.name??""))}`},
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: t('billingDate'), renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`},
        // { maxWidth: 50, minWidth: 50, field: " ", headerName: ' ', renderCell: ({row}) => <Menu {...row} /> },
    ]

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Domicilios" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        filterButton={{
                            show: true,
                            component: () => <Filters />
                        }}
                    />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        downloadData
                        columns={columns}
                        rows={results}
                        loading={isFetching}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Deliveries