import { api, mutationConfig } from "../splitApi";
import { ProductTag, CategoriesTag, PackagingTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerProduct: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/product", body };
            },
            invalidatesTags: ProductTag
        }),
        registerProductCategory: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/product/category", body };
            },
            invalidatesTags: CategoriesTag
        }),
        registerProductPackaging: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/product/packaging", body };
            },
            invalidatesTags: PackagingTag
        }),
        archiveProductCategory: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/product/category", body };
            },
            invalidatesTags: CategoriesTag
        }),
        archiveProductPackaging: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/archive/product/packaging", body };
            },
            invalidatesTags: PackagingTag
        }),
        massLoadingOfProducts: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/mass/loading/of/products", body };
            },
            invalidatesTags: ProductTag
        }),
        getProducts: build.query({
            query: (params=undefined) => ({
                url: `/get/products`,
                params
            }),
            providesTags: ProductTag
        }),
        getProduct: build.query({
            query: (params=undefined) => ({
                url: `/get/product`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductsByType: build.query({
            query: (params=undefined) => ({
                url: `/get/products`,
                params
            }),
            providesTags: ProductTag
        }),
        getPackaging: build.query({
            query: (params=undefined) => ({
                url: `/get/packaging`,
                params
            }),
            providesTags: [...ProductTag, ...PackagingTag]
        }),
        getCategories: build.query({
            query: (params=undefined) => ({
                url: `/get/categories`,
                params
            }),
            providesTags: [...ProductTag, ...CategoriesTag]
        }),
        getProductFamily: build.query({
            query: (params=undefined) => ({
                url: `/get/product/family`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductSalesHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/product/sales/history`,
                params
            }),
            providesTags: ProductTag
        }),
        getProductPurchaseHistory: build.query({
            query: (params=undefined) => ({
                url: `/get/product/purchase/history`,
                params
            }),
            providesTags: ProductTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useRegisterProductMutation,
    useGetProductsQuery,
    useLazyGetProductsQuery,
    useGetPackagingQuery,
    useGetProductFamilyQuery,
    useGetProductSalesHistoryQuery,
    useGetProductPurchaseHistoryQuery,
    useGetProductsByTypeQuery,
    useGetCategoriesQuery,
    useGetProductQuery,
    useMassLoadingOfProductsMutation,
    useRegisterProductCategoryMutation,
    useArchiveProductCategoryMutation,
    useRegisterProductPackagingMutation,
    useArchiveProductPackagingMutation,
} = Endpoints;
