export const getInvoicePaymentType = (sale=null) => {
    let r = "Crédito"
    if( Boolean(sale?._id) ){
        const balance = (sale?.balance??0)
        let payments = (sale?.payments??[]).filter((payment) => (payment?.amount??0) !== 0)
        const typesOfPayments = new Set(payments.map(payment => payment.wallet));
        if( balance === 0 ){
            if( typesOfPayments.size === 1 ){
                if( typesOfPayments.has("cash") ){
                    r = "Efectivo"
                }else if( typesOfPayments.has("consignment") ){
                    r = "Consignación"
                }
            }else{
                r = "Parcial"
            }
        }else{
            r = "Crédito"
        }
    }
    return r
}