import { useReceiveSaleFromAnotherBusinessMutation } from "@query/Sales"
import { asynchronousResponseHandler } from "@util/helpers"

export const useReceiveSaleFromAnotherBusiness = () => {
    const [ receiveSaleFromAnotherBusinessMutation, { isLoading } ] = useReceiveSaleFromAnotherBusinessMutation()

    const receiveSaleFromAnotherBusiness = async (payload={}) => {
        if( isLoading === false ){
            const resp = await receiveSaleFromAnotherBusinessMutation(payload).unwrap()
            asynchronousResponseHandler(resp, {
                successMessage: "La factura se recibió exitosamente.",
            })
        }
    }

    return {
        receiveSaleFromAnotherBusiness,
        isLoading,
    }
}