import { useCallback } from "react"

export const useDeliveryOptions = () => {

    const deliveryPaymentMethods = useCallback(() => {
        let r = [
            { name: "En caja", value: "inBox" },
            { name: "Contra entrega", value: "uponDelivery" },
        ].map((a, index) => ({...a, _id: (index+1)}))
        return r
    }, [])

    return {
        deliveryPaymentMethods
    }
}