import { DataGrid, Typography, styled } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import { useMemo } from 'react'

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-columnHeaders':{
        display: "none"
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-row:hover .MuiDataGrid-cell:focus': {
        outline: 'none',
        border: 'none',
    },
}))

const Summary = ({ closeOfDay={} }) => {
    const { consignmentsReceivedOnTheDay=0, creditSale=0, cashReceivedOnTheDay=0, paymentsToCreditsFromPreviousDaysInCash=0, paymentsToCreditsFromPreviousDaysByConsignment=0, creditPaymentsFromPreviousDaysWithAdvance=0, totalPaymentsOnDelivery=0, totalAddressesPaidInCash=0, advancePaymentsOfTheDay=0, totalVariableExpensesForTheDay=0 } = (closeOfDay??{})
    const columns = [
        {
            flex: 1,
            minWidth: 250,
            field: "name",
            renderCell: ({ row }) => <Typography fontWeight={'bold'} fontSize={'.775rem'} textTransform={'uppercase'} > {(row?.name??"")} </Typography>
        },
        {
            minWidth: 150,
            field: "amount",
            align: "right",
            renderCell: ({row}) => <Typography fontWeight={'bold'} fontSize={'.875rem'} sx={{
                color: ((row?.variant??"") === "asset") ?
                (theme) => theme.palette.success.main
                :
                ((row?.variant??"") === "passive") ? (theme) => theme.palette.error.main
                :
                undefined
            }} > {currencyFormat((row?.amount??0))} </Typography>},
    ]
    const summary = useMemo(() => {
        return [
            {name: "Consignaciones del dia", amount: consignmentsReceivedOnTheDay, variant: "asset"},
            {name: "Abono por consignación a créditos de días anteriores", amount: paymentsToCreditsFromPreviousDaysByConsignment, variant: "asset"},
            {name: "Total consignaciones recibidas en el dia.", amount: (consignmentsReceivedOnTheDay+paymentsToCreditsFromPreviousDaysByConsignment),},
            {name: "", amount: "",},
            {name: "Venta a crédito", amount: creditSale, variant: "asset"},
            {name: "", amount: "",},
            {name: "Efectivo del dia", amount: cashReceivedOnTheDay, variant: "asset"},
            {name: "Abono en efectivo a créditos de días anteriores", amount: paymentsToCreditsFromPreviousDaysInCash, variant: "asset"},
            {name: "Total efectivo recibido en el dia.", amount: (paymentsToCreditsFromPreviousDaysInCash+cashReceivedOnTheDay),},
            {name: "", amount: "",},
            {name: "Pagos por anticipo", amount: advancePaymentsOfTheDay, variant: "asset"},
            {name: "Abono por anticipo a créditos de días anteriores", amount: creditPaymentsFromPreviousDaysWithAdvance, variant: "asset"},
            {name: "", amount: "",},
            {name: "Total pagos a domiciliarios en caja", amount: totalAddressesPaidInCash, variant: "passive"},
            {name: "Total pagos a domiciliarios contra entrega", amount: totalPaymentsOnDelivery,},
            {name: "", amount: "",},
            {name: "Gastos variables del dia", amount: totalVariableExpensesForTheDay, variant: "passive"},
            {name: "", amount: "",},
            {name: "Venta del dia", amount: ((paymentsToCreditsFromPreviousDaysInCash+cashReceivedOnTheDay)-totalVariableExpensesForTheDay),},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [consignmentsReceivedOnTheDay, creditSale, totalVariableExpensesForTheDay, cashReceivedOnTheDay, paymentsToCreditsFromPreviousDaysInCash, paymentsToCreditsFromPreviousDaysByConsignment, creditPaymentsFromPreviousDaysWithAdvance, totalPaymentsOnDelivery, totalAddressesPaidInCash, advancePaymentsOfTheDay])

    const List = () => {
        return(
            <DataGridStyled
                hideFooter
                autoHeight
                getRowId={(param) => (param?._id??null)}
                columns={columns}
                rows={summary}
                rowHeight={30}
            />
        )
    }
    return(
        <>
            <List />
        </>
    )
}

export default Summary