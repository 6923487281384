import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, CurrencyExchangeRoundedIcon, EditIconRounded, FilterListRoundedIcon, ManageSearchRoundedIcon, PaymentsRoundedIcon, RestoreRoundedIcon } from '@component/UIComponents'
import AccountsPayablePaymentHistory from '@feature/AccountsPayable/AccountsPayablePaymentHistory'
import FixedExpenseRecordHistory from '@feature/AccountsPayable/FixedExpenseRecordHistory'
import { formErrorList } from '@hook/useFormTools'
import { yup } from '@hook/useFormik'
import { usePanel } from '@hook/usePanel'
import { useURLParams } from '@hook/useURLParams'
import { useArchiveFixedExpensesMutation, useGenerateNewExpenseAmountMutation, useGetFixedExpensesQuery, useGetFixedExpensesToPaymentListQuery, useGetHistoryOfFixedExpenseRecordsQuery, useRecordPaymentToFixedCostMutation, useRegisterFixedExpenseMutation } from '@query/AccountsPayable'
import { currencyFormat } from '@util/currencyFormat'
import { populateArrayFromQuery, preventAction, removeArrayRepeats } from '@util/helpers'
import { Toast } from '@util/swal'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const generateQuickList = (data=[]) => {
    const scheduledOnOtherDates = data.filter((n) => ((n?.adjustCurrentMonth??false) === false) && ((n?.adjustBiweekly??false) === false) ).reduce((acc,params) => ((params?.amount??0)+acc),0)
    const totalBaseFixedCosts = data.reduce((acc,params) => ((params?.amount??0)+acc),0)
    const totalBalanceFixedCosts = data.reduce((acc,params) => ((params?.balance??0)+acc),0)
    const totalScheduledPerMonth = data.filter((n) => ((n?.adjustCurrentMonth??false) === true)).reduce((acc,params) => ((params?.amount??0)+acc),0)
    const totalProgrammedFortnightly = data.filter((n) => ((n?.adjustBiweekly??false) === true)).reduce((acc,params) => (((params?.amount??0)/2)+acc),0)

    return [
        { primary: currencyFormat(totalBaseFixedCosts), secondary: "Base total" },
        { primary: currencyFormat(scheduledOnOtherDates), secondary: "Programado en otras fechas" },
        { primary: currencyFormat(totalScheduledPerMonth), secondary: "Programado cada mes" },
        { primary: currencyFormat(totalProgrammedFortnightly), secondary: "Programado cada quincena" },
        { primary: currencyFormat(totalBalanceFixedCosts), secondary: "Total acumulado" },
    ]

}

export const useGetFixedExpensesToPaymentList = (filters, config) => {
    const { data, isFetching } = useGetFixedExpensesToPaymentListQuery(filters, config)
    const fixedExpenses = (data?.payload??[])
    return {
        fixedExpenses,
        isFetching
    }
}

export const useGetFixedExpenses = (filters={}) => {
    const { groupArrangement } = usePanel()
    const { getQuery } = useURLParams()
    const groupBy = getQuery("groupBy")
    const showOnly = getQuery("showOnly")
    const { data, isFetching } = useGetFixedExpensesQuery(filters)
    let fixedCosts = useMemo(() => (data?.payload??[]),[data])
    let group = []
    let quickList = []
    const tags = useMemo(() => removeArrayRepeats(fixedCosts.map((n) => n?.tag)), [fixedCosts])
    if( Boolean(showOnly) ){
        fixedCosts = populateArrayFromQuery(fixedCosts, { compare:(n) => (n?.tag?.name??""), query: showOnly })
    }

    if( Boolean(groupBy) ){
        let groupByTag = (n) => (n[groupBy]?.name??"")
        let groupByState = (n) => (n?.autoTracking??false) ? "Automático" : "Manual"
        let groupByScheduleDate = (n) => (n?.adjustBiweekly??false) ? "Cada quincena" : ((n?.adjustCurrentMonth??false) ? "Cada mes" : "Otras fechas")
        let applyGroup = (n) => {
            let r = ""
            if( groupBy === "tag" ) r = groupByTag(n)
            if( groupBy === "state" ) r = groupByState(n)
            if( groupBy === "scheduleDate" ) r = groupByScheduleDate(n)
            return r
        }
        group = groupArrangement({
            results: fixedCosts,
            groupOuting:(n) => applyGroup(n),
            firstExit: (n) => ({
                groupName: applyGroup(n),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].results.push(n)
            }
        })
    }
    group = group.map((n) => ({...n, quickList: generateQuickList((n?.results??[]))}))
    quickList = generateQuickList(fixedCosts)
    return {
        fixedCosts,
        isFetching,
        quickList,
        group,
        tags
    }
}

export const useGetHistoryOfFixedExpenseRecords = (filters={}, config={}) => {
    const { data, isFetching } = useGetHistoryOfFixedExpenseRecordsQuery(filters, config)
    let historyFixedCosts = (data?.payload??[])
    return {
        historyFixedCosts,
        isFetching
    }
}

export const useFixedCostsActions = () => {
    const { addQuery, getQuery } = useURLParams()
    const openFilters = (getQuery("panel") === "filters")

    const actions = [
        {
            title: "Crear gasto fijo",
            onClick: () => addQuery({ form: "record-fixed-expense" }),
            icon: <AddCircleOutlineIconRounded />
        },
        {
            title: "Filtros",
            onClick: () => addQuery({ panel: "filters" }),
            icon: <FilterListRoundedIcon />
        },
    ]

    const dataGridActions = (props={}, callback={}) => {
        const { setEditableFixedExpense=()=>null, setAddPaymentsFixedExpense=()=>null, setFixedExpenseGenerateAmount=()=>null, archiveFixedExpense=()=>null } = callback
        return [
            {
                title: "Editar",
                onClick: () => setEditableFixedExpense(props),
                icon: <EditIconRounded />,
                buttonProps : {
                    disabled: ((props?.isArchived??false) === true) ? true : false
                }
            },
            {
                title: "Generar pagos",
                onClick: () => setAddPaymentsFixedExpense(props),
                icon: <PaymentsRoundedIcon />,
                buttonProps : {
                    disabled: ((props?.balance??0) <= 0 || (props?.isArchived??false) === true) ? true : false
                }
            },
            {
                title: "Ver historial de registro",
                onClick: () => null,
                icon: <ManageSearchRoundedIcon />,
                variantClick: "popover",
                popoverContainer: <FixedExpenseRecordHistory {...props} />
            },
            {
                title: "Ver historial de pagos",
                onClick: () => null,
                icon: <RestoreRoundedIcon />,
                variantClick: "popover",
                popoverContainer: <AccountsPayablePaymentHistory {...props} />
            },
            {
                title: ((props?.isArchived??false) === true) ? "Recuperar" : "Archivar",
                onClick: () => preventAction({
                    text: ((props?.isArchived??false) === true) ? `¿Desea recuperar el gasto fijo ${(props?.name??"")}?.` : `¿Desea archivar el gasto fijo ${(props?.name??"")}?.`,
                    onSubmit: () => archiveFixedExpense(props)
                }),
                icon: <ArchiveRoundedIcon />,
            },
            {
                title: "Generar nuevo importe",
                onClick: () => setFixedExpenseGenerateAmount(props),
                icon: <CurrencyExchangeRoundedIcon />,
                buttonProps : {
                    disabled: ((props?.isArchived??false) === true) ? true : false
                }
            },
        ]
    }

    const panels = {
        leftPanelProps: {
            xs: openFilters ? 3 : undefined,
            sx: openFilters ? {} : { display: 'none' }
        },
        centerPanelProps: {
            xs: openFilters ? 9 : 12,
        },
    }
    return {
        actions,
        dataGridActions,
        panels
    }
}

export const useFixedCostsOptions = () => {
    let groupByOptions = [
        {
            name: "Etiqueta",
            value: "tag",
        },
        {
            name: "Fecha de programación",
            value: "scheduleDate",
        },
        {
            name: "Estado",
            value: "state",
        },
    ]
    groupByOptions = groupByOptions.map((n, index) => ({...n, _id: index+1}))
    return {
        groupByOptions,
    }
}

export const useFixedCostsFormData = (props={}) => {
    let dv = { autoTracking: false, adjustCurrentMonth: false, adjustBiweekly: false, name: "", amount: 0, each: 1, tag: null }
    const { defaultValues=null } = props
    const schema = yup.object().shape({
        name: yup.string().required("El nombre del Gasto es requerido."),
        amount: yup.number().min(1, "El monto del gasto es requerido y debe ser mayor a 0.").required("El monto del gasto es requerido y debe ser mayor a 0."),
        each: yup.number().min(1, "El valor vence cada es requerido.").required("El valor vence cada es requerido."),
        tag: yup.object().required("El valor de etiqueta es requerido."),
    })
    const [values, setValue] = useState({})

    let val = useMemo(() => {
        let r = { ...dv }
        if( Boolean(defaultValues?._id) ) r = {...r, ...defaultValues}
        return r
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultValues])

    const loadDefaultValues = useCallback(() => {
        setValue({...val})
    },[val])

    useEffect(() => {
        loadDefaultValues()
    }, [loadDefaultValues])

    const resetValues = () => setValue({...dv})

    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            console.log(error)
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    return { values, setFieldValue, resetValues, validateValues }
}

export const usePaymentFixedCostFormData = (props={}) => {
    const { fixedCost, useSchemaArray=false } = props
    const schemaObject = yup.object().shape({
        fixedCostId: yup.string().required("Ocurrió un problema al cargar el identificador, cierre la ventana he intente nuevamente."),
        payments: yup.array().min(1, "Debe seleccionar al menos un pago.").of(yup.object().shape({
            amount: yup.number().min(1, "Es necesario definir el monto del pago.").required("Es necesario definir el monto del pago."),
            box: yup.string().required("Es necesario definir la caja de donde saldrá el pago."),
            boxRef: yup.string().required("Es necesario definir la caja de donde saldrá el pago."),
        })).required("Debe seleccionar al menos un pago.")
    })
    const schemaArray = yup.array().min(1, "Añada al menos un pago para continuar").of(schemaObject).required("Añada al menos un pago para continuar")
    let schema = useMemo(() => {
        if(useSchemaArray){
            return schemaArray
        }else{
            return schemaObject
        }
    },[useSchemaArray, schemaObject, schemaArray])
    
    let defaultValues = {
        fixedCostId: "",
        payments: [],
    }

    const [values, setValue] = useState({...defaultValues})
    const resetValues = () => setValue({...defaultValues})
    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }
    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    useEffect(() => {
        if( Boolean((fixedCost?._id??"")) && !Boolean((values?.fixedCostId??"")) ){
            setFieldValue("fixedCostId", (fixedCost?._id??""))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixedCost])

    return { values, setFieldValue, resetValues, validateValues }
}

export const useRegisterFixedExpense = () => {
    const [ registerFixedExpenseMutation, { isLoading }] = useRegisterFixedExpenseMutation()

    const registerFixedExpense = async (payload={}, callback={}) => {
        try {
            if( isLoading === false ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerFixedExpenseMutation(payload).unwrap()
                if( resp?.status === 1 ){
                    Toast.fire({
                        icon: 'success',
                        text: Boolean(payload?._id) ? "Gasto fijo actualizado exitosamente" : "Gasto fijo registrado exitosamente"
                    })
                    clearForm()
                    closeForm()
                }else{
                    Toast.fire({
                        icon: 'error',
                        text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                    })
                }
            }
        } catch (error) {
            throw error
        }
    }

    return {
        registerFixedExpense,
        isLoading
    }
}

export const useArchiveFixedExpenses = () => {
    const [ archiveFixedExpensesMutation, { isLoading }] = useArchiveFixedExpensesMutation()

    const archiveFixedExpenses = async (payload={}) => {
        try {
            if( isLoading === false ){
                const resp = await archiveFixedExpensesMutation(payload).unwrap()
                const message = Boolean(payload?.archived) ?
                    (((payload?.fixedCosts??[]).length >= 2) ? "Los gastos fijos se archivaron exitosamente." : "El gasto fijo se archivo exitosamente.")
                    :
                    ((payload?.fixedCosts??[]).length >= 2) ? "Los gastos fijos se recuperaron exitosamente." : "El gasto fijo se recupero exitosamente."
                if( resp?.status === 1 ){
                    Toast.fire({
                        icon: 'success',
                        text:message
                    })
                }else{
                    Toast.fire({
                        icon: 'error',
                        text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                    })
                }
            }
        } catch (error) {
            throw error
        }
    }

    return {
        archiveFixedExpenses,
        isLoading
    }
}

export const useRecordPaymentToFixedCost = () => {
    const [recordPaymentToFixedCostMutation, { isLoading }] = useRecordPaymentToFixedCostMutation()

    const recordPaymentToFixedCost = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await recordPaymentToFixedCostMutation(payload).unwrap()
                const message = ((payload?.payments??[]).length >= 2) ? "Los pagos se registraron exitosamente." : "El pago se registro exitosamente."
                if( resp?.status === 1 ){
                    Toast.fire({
                        icon: 'success',
                        text:message
                    })
                    clearForm()
                    closeForm()
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordPaymentToFixedCost,
        isLoading
    }
}

export const useFixedCostGenerateAmountFormData = (props={}) => {
    const { fixedCost } = props
    const schema = yup.object().shape({
        fixedCostId: yup.string().required("Ocurrió un problema al cargar el identificador, cierre la ventana he intente nuevamente."),
        amount: yup.number().min(1, "El monto es un campo obligatorio.").required("El monto es un campo obligatorio."),
    })
    let defaultValues = {
        fixedCostId: "",
        amount: 0,
    }

    const [values, setValue] = useState({...defaultValues})
    const resetValues = () => setValue({...defaultValues})
    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }
    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))
    
    useEffect(() => {
        if( Boolean((fixedCost?._id??"")) && !Boolean((values?.fixedCostId??"")) ){
            setFieldValue("fixedCostId", (fixedCost?._id??""))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixedCost])

    return { values, setFieldValue, resetValues, validateValues }
}

export const useGenerateNewExpenseAmount = () => {
    const [generateNewExpenseAmountMutation, {isLoading}] = useGenerateNewExpenseAmountMutation()
    
    const generateNewExpenseAmount = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await generateNewExpenseAmountMutation(payload).unwrap()
                if( resp?.status === 1 ){
                    Toast.fire({
                        icon: 'success',
                        text: "El importe se añadió al acumulado del gasto fijo exitosamente."
                    })
                    clearForm()
                    closeForm()
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: "Ocurrió un error imprevisto intente nuevamente si el problema persiste contacte al area de soporte."
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        generateNewExpenseAmount,
        isLoading
    }
}