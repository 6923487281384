import { Box, Dialog, DialogContent } from '@component/UIComponents'
import { useMemo } from 'react'
import { useGetPurchaseHistoryOfAProduct } from '../hooks/useSales'
import { groupValuesByDates, removeArrayRepeats } from '@util/helpers'
import { currencyFormat } from '@util/currencyFormat'
import SuperDataGrid from '@component/SuperDataGrid'
import dayjs from 'dayjs'

const HistoryOfSellingProductsToCustomers = ({ open=false, onClose=()=>null, filters={}, variant="dialog", title="Historial de venta de productos" }) => {
    const { customerId=null, startDate="", endDate="" } = filters
    const { history, isFetching } = useGetPurchaseHistoryOfAProduct({ ...filters, variant: "historyOfSellingProductsToCustomers", }, {skip: !Boolean(customerId)})
    const { columnsDate, result } = useMemo(() => {
        let n = groupValuesByDates(history, {
            sd: startDate,
            ed: endDate,
            itemName: (n) => (n?.name??""),
            itemKey: (n) => `${(n?.productId??"")}`,
            defaultValues: { quantity: 0, salePrice: 0},
            callback: (n, data) => {
                n.quantity = (data?.quantity??0)
                n.salePrice = (data?.salePrice??0)
                return n
            }
        })
        let validDates = (n?.result??[]).map((x) => {
            let b = {...x}
            delete b.name
            delete b._id
            let c = Object.keys(b)
            return c
        })
        validDates = validDates.reduce((acc, val) => [...acc, ...val,], [])
        validDates = removeArrayRepeats(validDates, { type: "single" })
        validDates = validDates.sort((a, b) => dayjs(b, "DD-MM-YYYY").toDate() - dayjs(a, "DD-MM-YYYY").toDate() )
        n.columnsDate = validDates
        return n
    }, [history, startDate, endDate])

    const calcWidthColum = useMemo(() => {
        let innerHeight = window.innerHeight
        innerHeight = (innerHeight-650)
        innerHeight = (innerHeight <= 150) ? 150 : innerHeight
        return innerHeight
    }, [])

    const columns = useMemo(() => {
        let r = [
            { maxWidth: calcWidthColum, minWidth: calcWidthColum, field: "name", headerName: "Producto"},
        ]
        for( let column of columnsDate ){
            r.push({
                maxWidth: 130,
                minWidth: 130,
                field: column,
                headerName: column,
                renderCell: ({row}) => {
                    const quantity = (row[column]?.quantity??0)
                    return(
                        <Box fontWeight={'500'} component={'span'} sx={{ color: (theme) => (quantity >= 1) ? theme.palette.success.main : undefined }} >
                            {`x${quantity} | ${currencyFormat((row[column]?.salePrice??0))}`}
                        </Box>
                    )
                }
            })
        }
        return r
    }, [columnsDate, calcWidthColum])

    if( variant === "dialog" ){
        return(
            <>
                <Dialog maxWidth="md" transitionDuration={0} PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} >
                    <DialogContent>
                        <SuperDataGrid
                            pageSize={20}
                            title={title}
                            columnBuffer={((columns?.length??0)*2)}
                            fixedCells
                            columns={columns}
                            rows={isFetching ? [] : result}
                            loading={isFetching}
                            filterBy={(param) => `${(param?.name??"")}`}
                        />
                    </DialogContent>
                </Dialog>
            </>
        )
    }else{
        return(
            <>
                <SuperDataGrid
                    pageSize={20}
                    title={title}
                    columnBuffer={((columns?.length??0)*2)}
                    fixedCells
                    columns={columns}
                    rows={isFetching ? [] : result}
                    loading={isFetching}
                    filterBy={(param) => `${(param?.name??"")}`}
                />
            </>
        )
    }
}

export default HistoryOfSellingProductsToCustomers