import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, EditIconRounded } from "@component/UIComponents"
import { preventAction } from "@util/helpers"
import { useCallback } from "react"
import { useArchiveProductCategory } from "./useArchiveProductCategory"

export const useMenusCategories = () => {
    const { archiveProductCategory } = useArchiveProductCategory()

    const primaryMenu = useCallback((props={}) => {
        const { hideMenus=[], onCreateCategory=()=>null, onEditCategory=()=>null, category=null } = props
        let r = [
            { name: "createCategory", title: "Crear categoría", onClick: () => onCreateCategory(), icon: <AddCircleOutlineIconRounded /> },
            { name: "editCategory", title: "Editar", onClick: () => onEditCategory(), icon: <EditIconRounded /> },
            { name: "archiveCategory", title: "Archivar", onClick: () => preventAction({
                text: `¿Desea archivar la categoría ${category?.name??""}?`,
                onSubmit: () => archiveProductCategory({ categoryId: (category?._id??null) })
            }), icon: <ArchiveRoundedIcon /> },
        ]

        r = r.filter((n) => !hideMenus.some((x) => x === (n?.name??"")) )

        return r.map((item, index) => ({...item, _id: index}))
    }, [archiveProductCategory])

    return {
        primaryMenu
    }
}