import TabAppContext from "@component/TabAppContext"
import { Grid } from "@component/UIComponents"
import { useGetBanks } from "./hooks/useBanks"
import { useTranslation } from "react-i18next"
import { useCallback, useState, useEffect, useMemo } from "react"
import BoxesTemplate from "@feature/MasterBox/components/BoxesTemplate"

const Bank = () => {
    const [tab, setTab] = useState(undefined)
    const { t } = useTranslation()
    const { resp } = useGetBanks()
    const box = useMemo(() => ((resp?.find((n) => n._id === tab)??null)), [tab, resp])

    let tabs = useMemo(() => {
        return resp.map((n) => ({ label: t(n?.name??""), value: n._id, component: <BoxesTemplate variant="bank" boxName={(box?.name??"")} refId={(box?._id??null)} boxBalance={(box?.balance??0)} /> }))
    },[t, resp, box])

    const loadDefaultTab = useCallback(() =>{
        if( tabs.length >= 1 ){
            if( !Boolean(tab) ){
                setTab((tabs[0]?.value??undefined))
            }
        }
    }, [tabs, tab]) 

    useEffect(() => {
        loadDefaultTab()
    }, [loadDefaultTab])

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    {(Boolean(tab) && (tabs.length >= 1)) &&
                        <TabAppContext
                            tabs={tabs}
                            tab={tab}
                            onChange={(event, value) => setTab(value)}
                        />
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Bank