import { useRegisterProductPackagingMutation } from "@query/Products"
import { asynchronousResponseHandler } from "@util/helpers"

export const useRegisterProductPackaging = () => {
    const [registerProductPackagingMutation, { isLoading }] = useRegisterProductPackagingMutation()

    const registerProductPackaging = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerProductPackagingMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id??null)) ? "El embalaje se actualizo exitosamente." : "El embalaje se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerProductPackaging,
        isLoadingRegisterProductPackaging: isLoading
    }
}