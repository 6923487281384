import { useRemoveHomeDeliveryFromTheInvoiceMutation } from "@query/Deliveries"
import { asynchronousResponseHandler } from "@util/helpers"

export const useRemoveHomeDeliveryFromTheInvoice = () => {
    const [ removeHomeDeliveryFromTheInvoiceMutation, { isLoading } ] = useRemoveHomeDeliveryFromTheInvoiceMutation()
    const removeHomeDeliveryFromTheInvoice = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await removeHomeDeliveryFromTheInvoiceMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El domicilio se removió satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        removeHomeDeliveryFromTheInvoice,
        isLoading,
    }
}