import AuthContainerForm from "@component/AuthContainerForm"
import RecoverPasswordForm from "./components/RecoverPasswordForm"
import { useAccessRedirect } from "@hook/usePanel"
import { useEffect } from "react"

const RecoverPassword = () => {
    const { accessRedirect } = useAccessRedirect()

    useEffect(() => {
        accessRedirect()
    }, [accessRedirect])

    return(
        <>
            <AuthContainerForm subTitle="Recupera tu contraseña muy fácil." >
                <RecoverPasswordForm />
            </AuthContainerForm>
        </>
    )
}

export default RecoverPassword