import { Box } from '@component/UIComponents'

const InvoiceInformationToEdit = ({ consecutive=0 }) => {
    return(
        <>
            <Box fontWeight={'bold'} fontSize={'1.7rem'} lineHeight={1} mb={1} p={2} borderRadius={1} sx={{ bgcolor: (theme) => theme.palette.warning.light, color: (theme) => theme.palette.grey[800] }} >
                <Box fontSize={'1rem'} >
                    Editando factura:
                </Box>
                #FAC{consecutive}
            </Box>
        </>
    )
}

export default InvoiceInformationToEdit