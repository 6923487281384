import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"
import { useGetRoles, useRoleMenu } from "./hooks/useRole"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import ActionsList from "@component/ActionsList"
import RoleForm from "./components/RoleForm"

const RolesAndPermissions = () => {
    const [editRole, setEditRole] = useState(null)
    const [showRolesForm, setShowRolesForm] = useState(false)
    const { primaryMenu, secondaryMenu } = useRoleMenu()

    const { t } = useTranslation()
    const { roles, isFetching } = useGetRoles()
    const Buttons = (props={}) => <ActionsList
        variant="mobileMenu"
        options={secondaryMenu(props, {
            onEditRole: (roleId) => setEditRole(roleId)
        })}
    />
    const onRoleFormClose = () => {
        setShowRolesForm(false)
        setEditRole(null)
    }
    const columns = useMemo(() => {
        let r = [
            {flex: 1, field: "name", headerName: "Nombre"},
            {flex: 1, field: "createdBy", headerName: "Creado por", renderCell: ({ row }) => `${t((row?.createdBy??""))}`},
            {flex: 1, field: "status", headerName: "Estado", renderCell: ({ row }) => `${row?.status === "active" ? "Habilitado" : "Deshabilitado"}`},
            {flex: 1, field: "permissions", headerName: "Privilegios", renderCell: ({ row }) => (row?.privileges??"") === "all" ? `Privilegios de administrador` : `${(row?.permissions??[]).length} permisos asignados`},
            {minWidth: 50, maxWidth: 50, field: " ", headerName: " ", renderCell: ({ row }) => (row?.createdBy??"") === "system" ? <></> : <Buttons {...row} /> },
        ]
        return r
    }, [t])

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={primaryMenu({
                            onCreateNewRole: () => setShowRolesForm(true)
                        })}
                    />
                </Grid>
                <Grid xs={12} >
                    <SuperDataGrid
                        columns={columns}
                        rows={roles}
                        loading={isFetching}
                    />
                </Grid>
            </Grid>
            <RoleForm open={showRolesForm || Boolean(editRole)} onClose={() => onRoleFormClose()} roleId={editRole} />
        </>
    )
}

export default RolesAndPermissions