import { useSocket } from "@component/SocketContext"
import { useCallback } from "react"

export const useSocketIO = () => {
    const { socketIO } = useSocket()

    const validateSocket= useCallback((socket) => {
        if( !Boolean(socket) ) console.error("No se encontró una conexión valida al socket")
        return Boolean(socket)
    }, [])

    const updateNotificationDisplay = useCallback((params) => {
        if( validateSocket(socketIO) ){
            socketIO.emit("ViewedNotifications", params)
        }
    }, [socketIO, validateSocket])

    return {
        updateNotificationDisplay
    }

}