import { usePanel } from "@hook/usePanel"
import { useCallback, useMemo, useState } from "react"
import { usePaymentScheduleMenu } from "./usePaymentScheduleMenu"
import dayjs from "dayjs"

export const usePaymentSchedule = () => {
    const [purchases, setPurchases] = useState([])
    const { groupArrangement } = usePanel()
    const { value:organizeProgramming } = usePaymentScheduleMenu()

    const addNewPurchase = useCallback((purchase) => {
        let currentPurchases = [...purchases]
        if( Boolean(purchase?._id) ){
            const index = currentPurchases.findIndex((n) => (n?._id??"") === (purchase?._id??""))
            if( index <= -1 ){
                currentPurchases.push(purchase)
                setPurchases(currentPurchases)
            }
        }
    }, [purchases])

    const addAllPurchases = useCallback((list=[]) => {
        let currentPurchases = [...purchases]
        for( let l of list ){
            const index = currentPurchases.findIndex((n) => (n?._id??"") === (l?._id??""))
            if( index <= -1 ){
                currentPurchases.push(l)
            }
        }
        setPurchases(currentPurchases)
    }, [purchases])

    const organizeProgrammingResults = useCallback((results) => {
        let currentResults = [...results]
        const { value="" } = (organizeProgramming??{})
        if( value === "highestInvoiceNumber" ){
            currentResults = currentResults.sort((a, b) => parseInt((b?.invoiceNumber??0)) - parseInt((a?.invoiceNumber??0)))
        }
        if( value === "lowestInvoiceNumber" ){
            currentResults = currentResults.sort((a, b) => parseInt((a?.invoiceNumber??0)) - parseInt((b?.invoiceNumber??0)))
        }
        if( value === "oldestExpirationDate" ){
            currentResults = currentResults.sort((a, b) => dayjs(a?.dueDate??null).toDate() - dayjs(b?.dueDate??null).toDate())
        }
        if( value === "mostRecentExpirationDate" ){
            currentResults = currentResults.sort((a, b) => dayjs(b?.dueDate??null).toDate() - dayjs(a?.dueDate??null).toDate())
        }
        if( value === "highestTotal" ){
            currentResults = currentResults.sort((a, b) => (b?.total??0) - (a?.total??0))
        }
        if( value === "lowestTotal" ){
            currentResults = currentResults.sort((a, b) => (a?.total??0) - (b?.total??0))
        }
        if( value === "withholdingAtTheHighestSource" ){
            currentResults = currentResults.sort((a, b) => (b?.reteFuente??0) - (a?.reteFuente??0))
        }
        if( value === "withholdingAtTheLowestSource" ){
            currentResults = currentResults.sort((a, b) => (a?.reteFuente??0) - (b?.reteFuente??0))
        }
        return currentResults
    }, [organizeProgramming])

    const rows = useMemo(() => {
        let currentRows = []
        let currentPurchases = [...purchases]
        let group = groupArrangement({
            results: currentPurchases,
            groupOuting:(n) => (n?.provider?.name??""),
            firstExit: (n) => ({
                groupName: (n?.provider?.name??""),
                _id: (n?.provider?._id??""),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].results.push(n)
            }
        })

        for( let n of group ){
            (currentRows??[]).push({
                _id: (n?._id??""),
                providerName: (n?.groupName??""),
            })
            let r = organizeProgrammingResults((n?.results??[]))
            for( let x of r ){
                (currentRows??[]).push(x)
            }
            (currentRows??[]).push({
                _id: `${(n?._id??"")}b1`,
                name: "totalizeSupplierProgramming",
                totalize: (n?.results??[]).reduce((acc, n) => (acc+(n?.balance??0)), 0)
            })
        }

        currentRows.push({
            name: "totalPaymentSchedule",
            totalize: currentRows.reduce((acc,val) => ((val?.name??"") === "totalizeSupplierProgramming") ? ((val?.totalize??0)+acc) : (0+acc) ,0),
        })

        return currentRows
    }, [purchases, groupArrangement, organizeProgrammingResults])


    return {
        purchases,
        setPurchases,
        addNewPurchase,
        rows,
        addAllPurchases
    }
    
}