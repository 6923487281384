import ActionsList from "@component/ActionsList"
import QuickList from "@component/QuickList"
import SuperDataGrid from "@component/SuperDataGrid"
import { usePanel } from "@hook/usePanel"
import { currencyFormat } from "@util/currencyFormat"
import { useCallback } from "react"
import { useState } from "react"
import { generateQuickList } from "../hooks/useFixedCosts"
import { useURLParams } from "@hook/useURLParams"

const FixedCostsDataGrid = ({ setFixedExpenseGenerateAmount=()=>null, setFixedExpenseToPay=()=>null, isFetching=false, title="", rows=[], dataGridActions=()=>null, handleSetEditableFixedExpense=()=>null, archiveFixedExpenses=()=>null }) => {
    const [quickList, setQuickList=[], ] = useState([])
    const { getQuery } = useURLParams()
    let viewArchived = getQuery("viewArchived")
    viewArchived = ((viewArchived === true) || (viewArchived === "true"))
    const generateQuickListMemoize = useCallback(() => generateQuickList(quickList), [quickList])
    const { updateStatusWithoutRepeating } = usePanel()
    const Buttons = (props) => <ActionsList options={dataGridActions(props, {
        setEditableFixedExpense: (props) => handleSetEditableFixedExpense(props),
        archiveFixedExpense: (props) => archiveFixedExpenses({ archived: !viewArchived, fixedCosts: [(props?._id??"")] }),
        setAddPaymentsFixedExpense: (props) => setFixedExpenseToPay(props),
        setFixedExpenseGenerateAmount: (props) => setFixedExpenseGenerateAmount(props)
    })} variant="mobileMenu" title={(props?.name??"")} />

    const eachDataGrid = (params={}) => {
        const { adjustCurrentMonth=false, adjustBiweekly=false, each=0 } = params
        let message = ""
        if( adjustCurrentMonth ) message = "Cada mes"
        if( adjustBiweekly ) message = "Cada quincena"
        if( !adjustCurrentMonth && !adjustBiweekly ) message = `Cada ${each??0} ${((each??0) <= 1) && ((each??0) !== 0) ? "día" : "días"} `
        return message
    }
    const programmedAmountDataGrid = (params={}) => {
        const { adjustCurrentMonth=false, adjustBiweekly=false, amount } = params
        let newAmount = ""
        if( adjustCurrentMonth ) newAmount = amount
        if( adjustBiweekly ) newAmount = (amount/2)
        if( !adjustCurrentMonth && !adjustBiweekly ) newAmount = amount
        return newAmount
    }

    const columns = [
        {
            flex: 1,
            minWidth: 200,
            field: "name",
            headerName : "Gasto",
            renderCell: ({row}) => {
                return(
                    <>
                        <Buttons {...row} />
                        {(row?.name??"")}
                    </>
                )
            }
        },
        {maxWidth: 200, minWidth: 200, field: "tag", headerName: "Etiqueta", renderCell: ({ row }) => `${(row?.tag?.name??"")}`},
        {maxWidth: 150, minWidth: 150, field: "amount", headerName : "Base", renderCell: ({ row }) => `${currencyFormat((row?.amount??0))}`},
        {maxWidth: 150, minWidth: 150, field: "programmedAmount", headerName : "Monto programado", renderCell: ({ row }) => `${currencyFormat((programmedAmountDataGrid(row)))}`},
        {maxWidth: 150, minWidth: 150, field: "balance", headerName : "Acumulado", renderCell: ({ row }) => `${currencyFormat((row?.balance??0))}`},
        {maxWidth: 150, minWidth: 150, flex: 1, field: "each", headerName : "Crear nuevo gasto", renderCell: ({ row }) => eachDataGrid(row) },
        {maxWidth: 100, minWidth: 100, field: "autoTracking", headerName : "Seguimiento", renderCell: ({ row }) => `${(row?.autoTracking??false) ? "Automático" : "Manual"}`},
    ]

    return(
        <>
            <SuperDataGrid
                title={title}
                onRowsDataMutation={(params) => updateStatusWithoutRepeating(quickList, params, setQuickList)}
                toolbar={() => <QuickList list={generateQuickListMemoize()} />}
                fixedCells
                loading={isFetching}
                columns={columns}
                rows={rows}
                filterBy={(param) => `${(param?.tag?.name??"")} ${(param?.name??"")}`}
            />
        </>
    )
}

export default FixedCostsDataGrid