import Autocomplete from "@component/Autocomplete"
import { useGetProducts } from "@feature/Inventory/Products/hooks/useProducts"
import { useEffect, useMemo, useState } from "react"
import ProductPreSelection from "./ProductPreSelection"
import { useURLParams } from "@hook/useURLParams"
import { AddCircleOutlineIconRounded, Box, GroupsIconRounded, IconButton, ManageSearchRoundedIcon, PersonSearchRoundedIcon, Stack, Tooltip, TurnedInRoundedIcon } from '@component/UIComponents'
import { useGetCustomers } from "@feature/Customers/hooks/useCustomers"
import { useValidatePermission } from "@hook/useValidatePermission"

const SearchBarPOS = ({ setShowServiceWindow=()=>null, selectionList=[], onChangeSelectionList=undefined, onChangeCustomerList=undefined, customer=undefined, onChangeCustomerPurchaseHistory=()=>null, customerLastPurchases=()=>null }) => {
    const { executeFunctionWithPermission, havePermission } = useValidatePermission()
    const [searchBarMode, setSearchBarMode] = useState("products")
    const { getQuery, delQueries } = useURLParams()
    const productOfTheList = getQuery("ref")
    let [product, setProduct] = useState(null)
    const { results, isLoading } = useGetProducts({ variant: 'pos' })
    const { customers, isFetching } = useGetCustomers({ variant: 'pos' })
    const options = useMemo(() => {
        let resp = []
        if( searchBarMode === "products" ){
            resp = [...results]
            resp.unshift(
                {
                    _id: (results?.length??0)+1,
                    name: <Box textTransform={'uppercase'} display={'flex'} alignItems={'center'} gap={1} fontWeight={'bold'} >
                            <AddCircleOutlineIconRounded sx={{ color: (theme) => theme.palette.primary.main }} />
                            Registrar un servicio
                        </Box>,
                    value: "registerService"
                },
            )
        }else{
            resp = customers
        }

        return resp
    }, [results, customers, searchBarMode])

    const handleChange = (params) => {
        const elem = document.getElementById("searchBarPOS")
        if( searchBarMode === "products" ){
            if( (params?.value??"") === "registerService" ){
                setShowServiceWindow(true)
            }else{
                setProduct(params)
            }
        }else{
            onChangeCustomerList(params)
            setSearchBarMode("products")
            if( Boolean(elem) ){
                elem.blur()
                setTimeout(() => {
                    elem.focus()
                }, 100)
            }
        }
    }
    const { indexItem, defaultValue } = useMemo(() => {
        let currentList = [...selectionList]
        const indexItem = currentList.findIndex(n => (n?.productId??"") === (product?._id??""))
        const defaultValue = currentList[indexItem]
        return { indexItem, defaultValue }
    }, [selectionList, product])

    const handleAddProductToList = (params) => {
        let currentList = [...selectionList]
        if( indexItem >= 0 ){
            currentList[indexItem] = params
        }else{
            currentList.push(params)
        }
        if( Boolean(onChangeSelectionList) ){
            onChangeSelectionList(currentList)
        }
        setProduct(null)
    }

    useEffect(() => {
        if( Boolean(productOfTheList) ){
            const n = results.find((b) => b._id === productOfTheList) || null
            setProduct(n)
            delQueries(['ref'])
        }
    }, [productOfTheList, results, delQueries])

    const handleKeyDown = (event) => {
        const { code="" } = event
        if( code === "Tab" ){
            event.preventDefault()
            executeFunctionWithPermission("switch_between_product_and_customer_list", () => setSearchBarMode((prevValue) => prevValue === "products" ? "customers" : "products" ))
        }
    }

    return(
        <>
        <Stack direction={'row'} width={'100%'} alignItems={'center'} gap={1} >
            <Box width={'100%'} >
                <Autocomplete
                    onKeyDown={handleKeyDown}
                    id={'searchBarPOS'}
                    onChange={(event, params) => handleChange(params)}
                    loading={isLoading || isFetching}
                    options={options}
                    value={null}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                        },
                        '& .MuiInputBase-input': {
                            fontWeight: '700'
                        },
                        '& .MuiAutocomplete-popper': {

                        }
                    }}
                    placeholder={ searchBarMode === "products" ? "Buscar producto..." : "Buscar cliente..."}
                />
            </Box>
            {havePermission("switch_between_product_and_customer_list") &&
                <Box display={'flex'} alignItems={'center'} >
                    <Tooltip arrow title={searchBarMode === "products" ? "Cambiar a buscador de clientes" : "Cambiar a buscador de productos"} >
                        <IconButton color="primary" onClick={() => setSearchBarMode((prevValue) => prevValue === "products" ? "customers" : "products" )} >
                            {searchBarMode === "products" ? <GroupsIconRounded /> : <TurnedInRoundedIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title="Ultimas facturas de venta del cliente" >
                        <IconButton disabled={!Boolean(customer?._id)} color="primary" onClick={customerLastPurchases} >
                            <PersonSearchRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title="Historial de compra del cliente" >
                        <IconButton disabled={!Boolean(customer?._id)} color="primary" onClick={onChangeCustomerPurchaseHistory} >
                            <ManageSearchRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            }
        </Stack>
            <ProductPreSelection
                customer={customer}
                editable={(indexItem >= 0)}
                value={defaultValue}
                product={product}
                onClose={() => handleChange(null)}
                onChange={handleAddProductToList}
            />
        </>
    )
}

export default SearchBarPOS