import { Box,  Grid, useGridApiRef } from "@component/UIComponents"
import SearchBarPOS from "./components/SearchBarPOS"
import SaleList from "./components/SaleList"
import CustomerItem from "./components/CustomerItem"
import TotalSales from "./components/TotalSales"
import SalesMenu from "./components/SalesMenu"
import { usePOS, usePOSFormik, usePOSMenu, useRecordSale } from "./hooks/usePOS"
import FooterButtonGroup from "@component/FooterButtonGroup"
import Cashier from "./components/Cashier"
import OutPOS from "./components/OutPOS"
import { useCallback, useEffect, useRef } from "react"
import CashierLists from "./components/CashierLists"
import SalePaymentWindow from "./components/SalePaymentWindow"
import { useState } from "react"
import Invoice from "@feature/Reports/Invoice"
import { getCookie } from "@util/helpers"
import { useGetUserSessionInformation } from "@feature/Users"
import { SocketProvider } from "@component/SocketContext"
import SalesInvoiceFinder from "@feature/Reports/Sales/components/SalesInvoiceFinder"
import { useParams } from "react-router-dom"
import { useGetSale } from "@feature/Reports/Sales/hooks/useSales"
import InvoiceInformationToEdit from "@feature/Reports/Sales/components/InvoiceInformationToEdit"
import CustomerPurchaseHistory from "./components/CustomerPurchaseHistory"
import HistoryOfSellingProductsToCustomers from "@feature/Reports/Sales/components/HistoryOfSellingProductsToCustomers"
import dayjs from "dayjs"
import ServiceRegistration from "./components/ServiceRegistration"

const Sales = () => {
    const [ showCustomerPreviousPurchases, setShowCustomerPreviousPurchases ] = useState(false)
    const [ showTrackingHistory, setShowTrackingHistory ] = useState(false)
    const [ showServiceWindow, setShowServiceWindow ] = useState(false)

    const handleActiveHistories = (historie, val) => {
        if( historie === "tracking" ){
            console.log(val)
            setShowCustomerPreviousPurchases(false)
            setShowTrackingHistory(val)
            return
        }
        if( historie === "previousPurchases" ){
            setShowCustomerPreviousPurchases(val)
            setShowTrackingHistory(false)
            return
        }
    }
    const { saleId=null } = useParams()
    const { sale } = useGetSale({ ref: saleId })
    const { fullName="", balance=0, rolName="", userData } = useGetUserSessionInformation()
    const [selectPendingInvoice, setSelectPendingInvoice] = useState(null)
    let dataGridRef = useGridApiRef()
    let { values, setFieldValue, validateValues, resetValues } = usePOSFormik(null, { defaultValues: sale })
    const { products, total, totalProfitPercentage, totalProfitCurrency, customer } = values
    const { recordSale, isLoading } = useRecordSale()
    const invoiceRef = useRef(null)
    const invoice = (getCookie("lastBill", {convertJSONstringify: true})??{})
    const { clearBillingArea } = usePOS()

    const handleClearBillingArea = () => clearBillingArea({ resetPOS: () => resetValues() })
    const preSubmit = (props={}) => {
        const { print=false, invoiceType="sale" } = props
        const { type } = validateValues(values)
        if(type === "errors") return
        let payload = {...values}
        payload.invoiceType = invoiceType
        
        recordSale(payload, {
            print,
            payBill: ((invoiceType === "sale") && !Boolean(saleId)) && (userData?.cashier??false) === true,
            clearForm: () => handleClearBillingArea(),
            onPayBill: (invoiceId) => setSelectPendingInvoice(invoiceId)
        })
    }

    const { footerActions, keyboardShortcuts } = usePOSMenu({ registerInvoice: preSubmit, invoiceRef, invoiceCharged: sale })

    useEffect(() => {
        window.addEventListener("keydown", keyboardShortcuts)
        return () => {
            window.removeEventListener("keydown", keyboardShortcuts)
        }
    }, [keyboardShortcuts])

    const handleCloseDialog = useCallback(() => {
        if( Boolean(saleId) ){
            const overlay = document.getElementsByClassName('MuiBackdrop-root');
            const closeDialog = new MouseEvent('click', { bubbles: true, cancelable: true, view: window, });
            if( Boolean(overlay[0]) ) overlay[0].dispatchEvent(closeDialog)
        }
    }, [saleId])

    useEffect(() => {
        handleCloseDialog()
    }, [handleCloseDialog])

    const autoCloseHistories = useCallback(() => {
        if( !Boolean(customer?._id) ){
            setShowCustomerPreviousPurchases(false)
            setShowTrackingHistory(false)
        }
    }, [customer])

    useEffect(() => {
        autoCloseHistories()
    }, [autoCloseHistories])

    return(
        <SocketProvider>
            <Box padding={1} minHeight={'93vh'}>
                <Grid container spacing={2} >
                    {/* Start NavBar */}
                    <Grid xs={12} sx={{ padding: 0 }} >
                        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} sx={{ backgroundColor: (theme) => theme.palette.primary.main }} >
                            <Box display={'flex'} gap={1} alignItems={'center'} >
                                <OutPOS />
                                <Cashier balance={balance} name={fullName} role={rolName} refUser={(userData?._id??null)} />
                            </Box>
                            <Box width={'100%'} display={'flex'} alignItems={'center'} px={2} >
                                <SalesInvoiceFinder />
                            </Box>
                            <Box display={'flex'} alignItems={'center'} >
                                <SalesMenu setFieldValue={setFieldValue} />
                            </Box>
                        </Box>
                    </Grid>
                    {/* End NavBar */}

                    <Grid xs={ (showCustomerPreviousPurchases || showTrackingHistory) ? 8 : 9} >
                        <SearchBarPOS
                            customerLastPurchases={() => handleActiveHistories("previousPurchases", !showCustomerPreviousPurchases)}
                            onChangeCustomerPurchaseHistory={() => handleActiveHistories("tracking", !showTrackingHistory)}
                            customer={customer}
                            selectionList={products}
                            onChangeSelectionList={(newList) => setFieldValue("products", newList)}
                            onChangeCustomerList={(customer) => setFieldValue("customer", customer)}
                            setShowServiceWindow={setShowServiceWindow}
                        />
                        <SaleList
                            apiRef={dataGridRef}
                            list={products}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    {(showCustomerPreviousPurchases || showTrackingHistory) &&
                        <Grid xs={4} >
                            { showTrackingHistory &&
                                <Box maxHeight={'84vh'} overflow={'scroll'} >
                                    <HistoryOfSellingProductsToCustomers
                                        title={""}
                                        variant="standard"
                                        filters={{
                                            customerId: (customer?._id??null),
                                            startDate: dayjs().subtract(30, 'day').format("MM-DD-YYYY"),
                                            endDate: dayjs().format("MM-DD-YYYY"),
                                        }}
                                    />
                                </Box>
                            }
                            {showCustomerPreviousPurchases &&
                                <CustomerPurchaseHistory customerId={(customer?._id??null)} />
                            }
                        </Grid>
                    }
                    {(!showCustomerPreviousPurchases && !showTrackingHistory) &&
                        <Grid xs={3} >
                            {Boolean(sale) &&
                                <InvoiceInformationToEdit {...sale} />
                            }
                            <TotalSales total={total} totalProfitCurrency={totalProfitCurrency} totalProfitPercentage={totalProfitPercentage} />
                            <CustomerItem customer={customer} onRemoveCustomer={() => setFieldValue("customer", null)}  />
                            <CashierLists
                                selectPendingInvoice={selectPendingInvoice}
                                onSetSelectPendingInvoice={(invoiceId) => setSelectPendingInvoice(invoiceId)}
                                onRecoverSavedInvoice={setFieldValue}
                            />
                        </Grid>
                    }

                </Grid>
            </Box>
            <FooterButtonGroup
                options={footerActions({ productList:products, total, customer }, { resetPOS: handleClearBillingArea, loading: isLoading, invoiceRef })}
            />
            <SalePaymentWindow open={Boolean(selectPendingInvoice)} invoiceId={selectPendingInvoice} onClose={() => setSelectPendingInvoice(null)} />
            <Box displayPrint={'block'} display={'none'} >
                <Invoice ref={invoiceRef} {...invoice} />
            </Box>
            <ServiceRegistration
                open={showServiceWindow}
                productList={products}
                onAddService={(newList) => setFieldValue("products", newList)}
                onClose={() => setShowServiceWindow(false)}
            />
        </SocketProvider>
    )
}

export default Sales