import { useCallback, useState } from "react"

export const useActionListController = ({ componentProps }) => {
    const [filtersAnchorEl, setFiltersAnchorEl] = useState(null)
    const [popoverProps, setPopoverProps] = useState({ content: <></>, anchorEl: null })

    const getProps = useCallback((component="", prop="") => {
        let r = null
        let currentComponentProps = {...componentProps}
        if( Boolean(component) ){
            r = (currentComponentProps[component]??null)
            if(Boolean(prop)){
                r = ((currentComponentProps[component]??{})[prop]??null)
            }
        }
        return r
    }, [componentProps])

    return {
        getProps,
        setPopoverProps,
        popoverProps,
        filtersAnchorEl,
        setFiltersAnchorEl,
    }
}