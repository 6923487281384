import PatternFormat from "@component/PatternFormat"
import { Box, Button, Container, Grid, TextField, Typography } from "@component/UIComponents"
import BusinessInformation from "./BusinessInformation"

const Settings = () => {
    const CustomContainer = ({ children, title="" }) => {
        return(
            <>
                <Container maxWidth="xs" sx={{ margin: 0 }} >
                    <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >{title} </Typography>
                    {children}
                </Container>
            </>
        )
    }

    return(
        <>
            <Grid container >
                <Grid xs={4} >
                    <CustomContainer title="INFORMACIÓN DEL NEGOCIO:" >
                        <BusinessInformation />
                    </CustomContainer>
                </Grid>
                <Grid xs={4} >
                    <CustomContainer title="Información del usuario:">
                        <TextField
                            label="Nombre"
                            fullWidth
                            margin="dense"
                        />
                        <TextField
                            label="Apellido"
                            fullWidth
                            margin="dense"
                        />
                        <TextField
                            label="Correo electrónico"
                            fullWidth
                            margin="dense"
                            type="email"
                        />
                        <PatternFormat
                            format="+57 ### ### ## ##"
                            label="Numero de teléfono"
                            fullWidth
                            margin="dense"
                        />
                        <TextField
                            label="Nueva contraseña"
                            fullWidth
                            margin="dense"
                        />
                        <Box display={'flex'} justifyContent={'end'} >
                            <Button>Guardar</Button>
                        </Box>
                    </CustomContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default Settings