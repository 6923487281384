import { Box, Typography } from '@component/UIComponents'

const XLSXFileAlert = () => {
    return(
        <>
            <Typography style={{ marginBottom: 5, fontWeight: "bold", textTransform: "uppercase", textAlign: "left", fontSize: "1rem" }} > Importante: </Typography>
            <Typography style={{ marginBottom: 8, fontWeight: "400", textAlign: "left", fontSize: ".875rem" }} >
                <Box component={'span'} style={{ fontWeight: 'bold' }} >1)</Box> Los nombres de las columnas que están escritos en el archivo en la linea 1 no deben ser modificados, esto puedo ocasionar errores al momento de cargar el archivo.
            </Typography>
            <Typography style={{ marginBottom: 8, fontWeight: "400", textAlign: "left", fontSize: ".875rem" }} >
                <Box component={'span'} style={{ fontWeight: 'bold' }} >2)</Box> Para <Box component={'span'} style={{ fontWeight: 'bold' }} > editar un producto </Box> asegúrese de cargar el numero consecutivo del producto que quiere editar debe examinar cuidadosamente el archivo antes de cargarlo y verificar que cada numero consecutivo pertenezca al producto que quiere editar, si carga en numero consecutivo en un producto diferente el producto se sobrescribirá.
            </Typography>
            <Typography style={{ marginBottom: 8, fontWeight: "400", textAlign: "left", fontSize: ".875rem" }} >
                <Box component={'span'} style={{ fontWeight: 'bold' }} >3)</Box> Si intenta <Box component={'span'} style={{ fontWeight: 'bold' }} > crear un producto nuevo </Box> el campo consecutivo del archivo debe estar en blanco o no existir, si intenta añadir un código consecutivo que no exista también sera registrado el producto como producto nuevo.
            </Typography>
            <Typography style={{ marginBottom: 8, fontWeight: "400", textAlign: "left", fontSize: ".875rem" }} >
                <Box component={'span'} style={{ fontWeight: 'bold' }} >4)</Box> Para <Box component={'span'} style={{ fontWeight: 'bold' }} > crear un producto compuesto </Box> solo debe añadir el numero de consecutivo del producto que quiere que sea el padre del nuevo producto, este consecutivo debe corresponder a un producto simple o podría experimentar un error al momento de cargar el archivo.
            </Typography>
            <Typography style={{ marginBottom: 8, fontWeight: "400", textAlign: "left", fontSize: ".875rem" }} >
                <Box component={'span'} style={{ fontWeight: 'bold' }} >5)</Box> Para <Box component={'span'} style={{ fontWeight: 'bold' }} > asignar valores como las categorías o los proveedores </Box> que se seleccionan desde una lista desplegable en el formulario. En el archivo basta con escribir el nombre exacto del valor en la columna correspondiente de ser un valor existente sera tomado de los que se encuentran registrados, de ser un valor nuevo sera registrado, <Box component={'span'} style={{ fontWeight: 'bold' }} > revise el archivo para corroborar que los valores estén correctamente escritos para no generar duplicados. </Box>
            </Typography>
        </>
    )
}

export default XLSXFileAlert