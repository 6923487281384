import { InputAdornment, SearchIconRounded, TextField, styled } from "@component/UIComponents"

const SearchBarTextField = styled(TextField)(() => ({
    maxWidth: 200,
    backgroundColor: "#FFFFFF",
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
    }
}))

const SearchBar = ({ value="", setTextMatch=()=>null }) => {
    return(
        <>
            <SearchBarTextField
                size='small'
                InputProps={{
                    startAdornment: <InputAdornment position='start' >
                        <SearchIconRounded />
                    </InputAdornment>
                }}
                value={value}
                fullWidth
                placeholder='Buscar...'
                onChange={({ target }) => setTextMatch((target?.value ?? ""))}
            />
        </>
    )
}

export default SearchBar