import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetBusinessesType } from "../hooks/useCustomers"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const BusinessTypeFinder = ({ size=undefined, value=null, onChange=undefined, multiple=undefined, queryValue=undefined }) => {
    const { businessesType, isFetching } = useGetBusinessesType()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: businessesType, queryValue })
    const val = getAutocompleteValue()

    return(
        <>
            <AutocompleteCreable
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetching}
                label="Tipo de negocio"
                options={businessesType}
            />
        </>
    )
}

export default BusinessTypeFinder