import { api, mutationConfig } from "../splitApi";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        registerBusinessOwner: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/register/business/owner", body };
            },
        }),
        authenticateUser: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/authenticate/user", body };
            },
        }),
        sendPasswordRecoveryEmail: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/send/password/recovery/email", body };
            },
        }),
        recoverAdministratorUserPassword: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/recover/administrator/user/password", body };
            },
        }),
        sendValidationEmail: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/send/validation/email", body };
            },
        }),
        newUserAndBusinessRegistration: build.mutation({
            query(body) {
                return { ...mutationConfig, url: "/new/user/and/business/registration", body };
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useRegisterBusinessOwnerMutation,
    useAuthenticateUserMutation,
    useRecoverAdministratorUserPasswordMutation,
    useSendPasswordRecoveryEmailMutation,
    useSendValidationEmailMutation,
    useNewUserAndBusinessRegistrationMutation,
} = Endpoints;
