import Autocomplete from "@component/Autocomplete"
import { useGetProviders } from "../hooks/useProviders"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const ProviderFinder = ({ placeholder=undefined, queryValue=undefined, multiple=undefined, label="Proveedores", sx=undefined, id=undefined, fullWidth=undefined, autoFocus=undefined, size=undefined, helperText=undefined, variant=undefined, onChange=undefined, value=undefined, inputRef=undefined, compare=undefined, filters={} }) => {
    const { isFetching, providers } = useGetProviders({ ...filters })
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: providers, queryValue, compare })
    let val = getAutocompleteValue()

    return(
        <>
            <Autocomplete
                multiple={multiple}
                ref={inputRef}
                label={label}
                sx={sx}
                fullWidth={fullWidth}
                autoFocus={autoFocus}
                id={id}
                helperText={helperText}
                size={size}
                value={val}
                loading={isFetching}
                options={providers}
                variant={variant}
                placeholder={placeholder}
                onChange={onChange}
            />
        </>
    )
}

export default ProviderFinder