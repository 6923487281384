import React from 'react'
import { PatternFormat as PatternFormatMUI, styled } from '@component/UIComponents'
import TextField from './TextField';

const TextFieldStyled = styled(TextField)(() => ({
    '& input[type="number"]': {
        MozAppearance: 'textfield',
        WebkitAppearance: 'none',
        margin: 0,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
}));

const CustomPatternFormat = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    const handleOnChange = (event) => {
        const { value="", floatValue=0 } = event
        if (onChange) {
            onChange(value, floatValue);
        }
    };
    return (
        <PatternFormatMUI
            getInputRef={ref}
            onValueChange={handleOnChange}
            allowEmptyFormatting
            {...other}
        />
    );
});

const PatternFormat = ({ margin="none", variant="outlined", InputLabelProps=undefined, size=undefined, format="", InputFontWeight = undefined, onMouseEnter = undefined, onMouseOut = undefined, onFocus = undefined, onBlur = undefined, name = undefined, value, label = undefined, helperText = undefined, error = undefined, fullWidth = undefined, ...rest }) => {
    // const withValueCap = (inputObj) => {
    //     const { value } = inputObj;
    //     if (maxVal !== undefined) {
    //         if (value <= maxVal) return true;
    //         return false;
    //     }
    //     return true;
    // };

    // isAllowed: withValueCap,

    return (
        <TextFieldStyled
            variant={variant}
            InputLabelProps={InputLabelProps}
            InputFontWeight={InputFontWeight}
            onFocus={onFocus}
            onBlur={onBlur}
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseOut}
            name={name}
            fullWidth={fullWidth}
            helperText={helperText}
            error={error}
            label={label}
            margin={margin}
            size={size}
            value={value}
            InputProps={{ inputComponent: CustomPatternFormat }}
            inputProps={{ value, format, ...rest }}
        />
    );
}

export default React.memo(PatternFormat)