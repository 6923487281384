import { Box, SearchIconRounded, createFilterOptions, styled } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useGetSales } from "../hooks/useSales"
import dayjs from "dayjs"
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '@util/currencyFormat'
import { usePanel } from '@hook/usePanel'
import { useMemo } from 'react'
import DialogInvoice from '@feature/Reports/Invoice/components/DialogInvoice'
import { useState } from 'react'
import { Toast } from '@util/swal'
import { useValidatePermission } from '@hook/useValidatePermission'

const BoxStyled = styled(Box)(() => ({
    '& .MuiInputBase-root': {
        backgroundColor: "#FFFFFF",
        borderRadius: "50px !important",
        height: "45px !important",
        padding: "4px !important"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
    },
    '& .MuiInputBase-input': {
        padding: "7.5px 45px 7.5px 45px !important"
    }
}))

const SalesInvoiceFinder = () => {
    const { havePermission } = useValidatePermission()

    const [invoiceSelected, setInvoiceSelected] = useState(null) 
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const toDay = dayjs().format("MM-DD-YYYY")
    const { isFetching, results } = useGetSales({ variant: "salesReport", invoiceType: "sale", startDate: toDay, endDate: toDay })
    const Line = ({ keyPair="", value="", translation=false }) => {
        return(
            <Box fontSize={'.800rem'} fontWeight={'500'} >
                {keyPair}: <Box component={'span'} > { translation ? t(value) : value} </Box>
            </Box>
        )
    }
    const salesColorGuide = {
        partial: (theme) => theme.palette.success.main,
        credit: (theme) => theme.palette.warning.main,
        undefined: (theme) => theme.palette.error.main,
    }

    const filterOptions = createFilterOptions({
        stringify: (option) => ` ${t((option?.customer?.name??"some"))} ${(option?.seller?.name??"")} ${(option?.seller?.lastName??"")} ${(option?.total??0)} ${(option?.consecutive??"")} ${t((option?.typePayment??""))}`,
    });

    const { totalSale, totalUndefined } = useMemo(() => {
        return {
            totalSale: results.filter((n) => (n?.typePayment??"") !== "undefined" ).reduce((acc, val) => (acc+(val?.total??0)), 0),
            totalUndefined: results.filter((n) => (n?.typePayment??"") === "undefined" ).reduce((acc, val) => (acc+(val?.total??0)), 0),
        }
    }, [results])

    const handleChange = (params=null) => {
        if( (params?.typePayment??"") === "undefined" ){
            Toast.fire({
                icon: "warning",
                text: "Defina el pago de la factura antes de continuar."
            })
        }else{
            setInvoiceSelected((params?._id??null))
        }
    }

    return(
        <>
            <BoxStyled width={'100%'} position={'relative'} >
                { havePermission("show_sales_report") &&
                    <>
                        <Autocomplete
                            onChange={(_, param) => handleChange((param??null))}
                            loading={isFetching}
                            placeholder={`Facturas del dia - Venta: ${currencyFormat(totalSale)} ${totalUndefined >= 1 ? `- Sin definir: ${currencyFormat(totalUndefined)}` : ""}`}
                            fullWidth
                            options={results}
                            renderOption={(props, option) => {
                                return(
                                    <li {...props} key={option._id} >
                                        <Box>
                                            <Line keyPair={'Cliente'} translation value={`${t((option?.customer?.name??"some"))} - ${t((option?.typePayment??""))}`} />
                                            <Line keyPair={'Vendedor'} value={`${(option?.seller?.name??"")} ${(option?.seller?.lastName??"")}`} />
                                            <Line keyPair={'Venta'} value={
                                                <>
                                                    <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {`#FAC${(option?.consecutive??"")}`} </Box>
                                                    {" - "}
                                                    <Box component={'span'} sx={{color: salesColorGuide[(option?.typePayment??"")]}} >
                                                        {`${currencyFormat((option?.total??0))}`}
                                                    </Box>
                                                    {" - "}
                                                    <Box component={'span'} > {getDateFormats((option?.createdAt??undefined)).hhmmss} </Box>
                                                </>
                                            } />
                                        </Box>
                                    </li>
                                )
                            }}
                            filterOptions={filterOptions}
                        />
                        <SearchIconRounded
                            sx={{
                                position: "absolute",
                                top: "12px",
                                left: "14px",
                                color: (theme) => theme.palette.primary.main
                            }} />
                    </>
                }
            </BoxStyled>
            <DialogInvoice
                open={Boolean(invoiceSelected)}
                saleRef={invoiceSelected}
                onClose={() => setInvoiceSelected(null)}
            />
        </>
    )
}

export default SalesInvoiceFinder