import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@component/UIComponents'
import FinderPermissions from './FinderPermissions'
import { useGetRole, useRecordRole, useRoleFormData } from '../hooks/useRole'
import Form from '@component/Form'
import { useCallback } from 'react'
import { useEffect } from 'react'

const RoleForm = ({ open=false, onClose=()=>null, roleId=null }) => {
    const { role } = useGetRole({roleId}, { skip: !Boolean(roleId) })
    const { recordRole, isLoading } = useRecordRole()
    const { values, setFieldValue, validateValues, resetValues } = useRoleFormData({ defaultValues: role })
    const { name="", permissions=[] } = values

    const resetForm = useCallback(() => {
        if( !open ) resetValues()
    }, [open, resetValues])

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        recordRole(values, {
            closeForm: () => {
                resetValues()
                onClose()
            }
        })
    }

    useEffect(() => {
        resetForm()
    }, [resetForm])

    return(
        <>
            <Dialog maxWidth="sm" fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <TextField
                                    value={name}
                                    fullWidth
                                    label="Nombre"
                                    onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                                    inputProps={{
                                        style:{
                                            fontWeight: "bold",
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <FinderPermissions
                                    queryValue={permissions.join(",")}
                                    onChange={(_, value) => setFieldValue("permissions", value.map((n) => (n?.permission??"")))}
                                    multiple
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default RoleForm