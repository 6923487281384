import { useLazyRetrieveReceivedSaleInvoiceQuery } from "@query/Sales"

export const useLazyRetrieveReceivedSaleInvoice = () => {
    const [ retrieveReceivedSaleInvoice, { isFetching } ] = useLazyRetrieveReceivedSaleInvoiceQuery()

    return {
        retrieveReceivedSaleInvoice,
        isFetching
    }

}