import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@component/UIComponents'

const LoadProgress = ({ text="", variant="standard", open=true }) => {
    const Progress = () => {
        return(
            <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} height={200} >
                <Box textAlign={'center'} >
                    <CircularProgress />
                    <Typography> {text} </Typography>
                </Box>
            </Box>
        )
    }
    if( variant === "standard" ){
        return(
            <Progress />
        )
    }else if(variant === "dialog") {
        return(
            <Dialog maxWidth="xs" open={open} fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} >
                <DialogContent>
                    <Progress />
                </DialogContent>
            </Dialog>
        )
    }else{
        return(
            <></>
        )
    }
}

export default LoadProgress