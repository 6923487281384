import { yup } from "@hook/useFormik"
import { useManageFormData, usePanel } from "@hook/usePanel"
import { useURLParams } from "@hook/useURLParams"
import { useArchiveCustomerMutation, useGetBranchesQuery, useGetBusinessesTypeQuery, useGetCustomerAdvanceHistoryQuery, useGetCustomerDebtsQuery, useGetCustomerQuery, useGetCustomersQuery, useGetNeighborhoodsQuery, useGetZonesQuery, useRegisterAdvanceToCustomerMutation } from "@query/Customers"
import { asynchronousResponseHandler } from "@util/helpers"
import { useMemo } from "react"

export const useGetCustomerDebts = (filters={}, config={}) => {
    const { data, isFetching } = useGetCustomerDebtsQuery(filters, config)
    const payload = ((data?.payload??[])[0]??{})
    const { invoicesFound, totalDebt, results:customerDebts } = (payload??{})

    return {
        isFetching,
        invoicesFound,
        totalDebt,
        customerDebts
    }
}

export const useCustomersFilters = () => {
    const { getQueries } = useURLParams()
    const { showOnly=undefined, groupBy=undefined } = getQueries(['showOnly', 'groupBy'])
    return {
        showOnly,
        groupBy,
        archived: showOnly === "archived" ? true : undefined
    }
}

export const useGetCustomer = (filters={}) => {
    const { refId } = filters
    const { data, isFetching } = useGetCustomerQuery(filters, { skip: !Boolean(refId) })
    const customer = (data?.payload??{})

    return {
        customer,
        isFetching
    }
}

export const useGetCustomers = (filters={}) => {
    const { showOnly=undefined, groupBy=undefined, ...rest} = filters
    const { data, isFetching } = useGetCustomersQuery(rest)
    const { groupArrangement } = usePanel()
    let groupCustomers = []
    let customers = (data?.payload??[])
    if( showOnly === "inAdvance" ) customers = customers.filter((n) => (n?.balance??0) >= 1)
    if( showOnly === "withoutAdvance" ) customers = customers.filter((n) => (n?.balance??0) <= 0)
    if( Boolean(groupBy) ){
        groupCustomers = groupArrangement({
            results: [...customers],
            groupOuting:(n) => (n[groupBy]?.name??""),
            firstExit: (n) => ({
                groupName: (n[groupBy]?.name??""),
                results: [n]
            }),
            secondExit: (group, index, n) => {
                group[index].results.push(n)
            }
        })
        groupCustomers = groupCustomers.filter((n) => Boolean(n?.groupName))
    }

    return {
        customers,
        groupCustomers,
        isFetching,
        listRenderingOptions: Boolean(groupBy) ? groupCustomers.map((n) => n.results) : [customers]
    }
}

export const useGetCustomerAdvanceHistory = (filters={}, config={}) => {
    const { ...rest} = filters
    const { data, isFetching } = useGetCustomerAdvanceHistoryQuery(rest, config)
    let advances = (data?.payload??[])

    return {
        advances,
        isFetching
    }
}

export const useGetNeighborhoods = (filters={}) => {
    const { data, isFetching } = useGetNeighborhoodsQuery(filters)
    const neighborhoods = (data?.payload??[])

    return {
        neighborhoods,
        isFetching
    }
}

export const useGetBusinessesType = (filters={}) => {
    const { data, isFetching } = useGetBusinessesTypeQuery(filters)
    const businessesType = (data?.payload??[])

    return {
        businessesType,
        isFetching
    }
}

export const useGetBranches = (filters={}) => {
    const { data, isFetching } = useGetBranchesQuery(filters)
    const branches = (data?.payload??[])

    return {
        branches,
        isFetching
    }
}

export const useGetZones = (filters={}) => {
    const { data, isFetching } = useGetZonesQuery(filters)
    const zones = (data?.payload??[])

    return {
        zones,
        isFetching
    }
}

export const useArchiveCustomer = () => {
    const [ archiveCustomerMutation, {isLoading} ] = useArchiveCustomerMutation()
    const archiveCustomer = async (payload=null, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await archiveCustomerMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean(resp?.payload?.isArchived??false) ?
                        "El cliente se archivo exitosamente."
                        :
                        "El cliente se recupero exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archiveCustomer,
        isLoading
    }
}

export const useGenerateAdvanceFormData = (props={}) => {
    const { defaultValues=undefined } = props
    const initialValues = useMemo(() => ({ customerId: "", concept:"advanceCustomer", payments: [] }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            customerId: yup.string().required("Ocurrió un problema al cargar el proveedor, cierre la ventana he intente nuevamente."),
            concept: yup.string().oneOf(['advanceCustomer', 'returnedToBox'], 'Ocurrió un problema al definir el concepto, cierre la ventana he intente nuevamente.'),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useRegisterAdvanceToCustomer = () => {
    const [registerAdvanceToCustomerMutation, { isLoading }] = useRegisterAdvanceToCustomerMutation()

    const registerAdvanceToCustomer = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, elementFocus=()=>null } = callback
                const resp = await registerAdvanceToCustomerMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El anticipo se registro satisfactoriamente.",
                    clearForm,
                    closeForm,
                    callback: () => {
                        elementFocus()
                    },
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerAdvanceToCustomer,
        isLoading
    }
}