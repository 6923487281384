import { Box, Grid, RadioButtonCheckedRoundedIcon } from "@component/UIComponents"
import SuperDataGrid from "@component/SuperDataGrid"
import { useGetSales, useSaleMenus } from "../Sales/hooks/useSales"
import { currencyFormat } from "@util/currencyFormat"
import { usePanel } from "@hook/usePanel"
import { useTranslation } from "react-i18next"
import ActionsList from "@component/ActionsList"
import Filters from "../Sales/components/Filters"
import { useState } from "react"
import DialogInvoice from "../Invoice/components/DialogInvoice"
import ListRendering from "@component/ListRendering"
import ComponentTitle from "@component/ComponentTitle"
import { useSalesController } from "../Sales/hooks/useSalesController"
import { useCreditsController, useCreditsControllerDataGrid } from "./hooks/useCreditsController"
import { useMemo } from "react"

const Credits = () => {
    const { filters, setFiltersData, invoiceType, groupBy, groupSales, activeFilters } = useSalesController()
    const queries = activeFilters
    const { filtersCredit } = useCreditsController()
    const { quickList } = useCreditsControllerDataGrid()

    const [invoiceSelected, setInvoiceSelected] = useState(null)
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const { isFetching, group, groupResults, results } = useGetSales({ variant: "creditReport", ...queries })
    const credits = useMemo(() => {
        let r = []
        r = filtersCredit(results)
        return r
    }, [results, filtersCredit])

    const { secondaryMenu } = useSaleMenus()
    const Buttons = (props={}) => <ActionsList
    variant="mobileMenu"
    options={secondaryMenu(props, {
        onViewInvoice: (invoiceId) => setInvoiceSelected(invoiceId)
    })}
    />
    const columns = [
        { maxWidth: 50, minWidth: 50, field: " ", headerName: ' ', renderCell: ({row}) => <Buttons {...row} /> },
        { flex: 1, minWidth: 220, field: "customer", headerName: t('name'), renderCell: ({row}) => {
            return(
                <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} >
                    <Box component={'span'} display={'flex'} alignItems={'center'} >
                        {getDateFormats((row?.creditExpirationDate??"")).missingDays-1 <= 0 ? <RadioButtonCheckedRoundedIcon sx={{ fontSize: "1rem", color: (theme) => theme.palette.error.main }} /> : <RadioButtonCheckedRoundedIcon sx={{ fontSize: "1rem", color: (theme) => theme.palette.grey[700] }} />}
                    </Box>
                    {`${t((row?.customer?.name??"some"))}`}
                </Box>
            )
        }},
        { maxWidth: 80, minWidth: 80, align: "center", field: "consecutive", headerName: t('#FAC'), },
        { maxWidth: 120, minWidth: 120, field: "total", headerName: t('sale'), renderCell: ({row}) => `${currencyFormat((row?.total??0))}`},
        { maxWidth: 120, minWidth: 120, field: "totalPayments", headerName: t('Pagos'), renderCell: ({row}) => `${currencyFormat((row?.totalPayments??0))}`},
        { maxWidth: 120, minWidth: 120, field: "balance", headerName: t('balance'), renderCell: ({row}) => `${currencyFormat((row?.balance??0))}`},
        { maxWidth: 130, minWidth: 130, field: "seller", headerName: t('seller'), renderCell: ({row}) => `${t((row?.seller?.name??""))} ${t((row?.seller?.lastName??""))}`},
        { maxWidth: 250, minWidth: 250, field: "creditExpirationDate", headerName: t('dueDate'), renderCell: ({row}) => {
            const missingDays = getDateFormats((row?.creditExpirationDate??"")).missingDays
            const currentMissingDays = (missingDays >= 1) ? (missingDays-1) : missingDays
            return `${getDateFormats((row?.creditExpirationDate??"")).ddmmyyyy} - ${currentMissingDays <= 0 ? "VENCIDO" : `Faltan ${currentMissingDays} días para vencerse`}`
        }},
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: t('billingDate'), renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`},
    ]

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Créditos" />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        componentProps={{
                            filter: {
                                active: true,
                                props: {
                                    component: () => <Filters filters={{...filters, invoiceType, groupBy, groupSales }} variant="credit" setFiltersData={setFiltersData} />,
                                    filters,
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid xs={12} >
                    <ListRendering
                        elements={group}
                        component={(list, index) => (
                            <SuperDataGrid
                                onQuickList={quickList}
                                title={(groupResults[index]?.groupName??undefined)}
                                downloadData
                                loading={isFetching}
                                columns={columns}
                                rows={Boolean(groupBy) ? list : credits}
                                filterBy={(params) => `${t((params?.customer?.name??"some"))} ${(params?.seller?.name??"")} ${(params?.consecutive??"")} ${(params?.total??0)} ${getDateFormats((params?.createdAt??"")).mmddyyyyhhmm}`}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <DialogInvoice
                open={Boolean(invoiceSelected)}
                saleRef={invoiceSelected}
                onClose={() => setInvoiceSelected(null)}
            />
        </>
    )
}

export default Credits