import { Box, DataGrid, IconButton, Popover, RestoreRoundedIcon, Tooltip, styled } from '@component/UIComponents'
import { useGetPurchaseHistoryOfAProduct } from '@feature/Reports/Sales/hooks/useSales'
import { usePanel } from '@hook/usePanel'
import { currencyFormat, calculateProfitMargin } from '@util/currencyFormat'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-columnHeaders': {
        display: "none"
    }
}))

const ProductSalesHistory = ({ customerId, productId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation()

    const { getDateFormats } = usePanel()
    const { history, isFetching } = useGetPurchaseHistoryOfAProduct({ productId, customerId, limit: 5 }, { skip: (!Boolean(customerId) && !Boolean(productId)) })
    const columns = [
        {field: "consecutive", maxWidth: 120, minWidth: 120, headerName: "", renderCell: ({ row }) => `#FAC ${(row?.consecutive??"")}` },
        {field: "name", flex: 1, minWidth: 250, headerName: ""},
        {field: "quantity", maxWidth: 50, minWidth: 50, headerName: "", align: "center", renderCell: ({row}) => `x ${(row?.quantity??0)}`},
        {field: "salePrice", maxWidth: 130, minWidth: 130, headerName: "", renderCell: ({row}) => `${currencyFormat((row?.salePrice??0))}`},
        {field: "margin", maxWidth: 80, minWidth: 80, headerName: "", renderCell: ({row}) => `${calculateProfitMargin((row?.salePrice??0), ((row?.salePrice??0)-(row?.costPrice??0)))} %`, align: "center",},
        {field: "typePayment", maxWidth: 100, minWidth: 100, headerName: "", align: "center", renderCell: ({ row }) => `${t((row?.typePayment??""))}` },
        {field: "createdAt", maxWidth: 160, minWidth: 160, headerName: "", renderCell: ({ row }) => getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm },
    ]

    return(
        <>
            <Tooltip placement='left' arrow title={ !Boolean(customerId) ? "Seleccione un cliente para activar el historial de venta" : "Ver historial de venta"} >
                <Box component={'span'} >
                    <IconButton color='primary' disabled={!Boolean(customerId)} onClick={handleClick} >
                        <RestoreRoundedIcon />
                    </IconButton>
                </Box>
            </Tooltip>
            <Popover anchorEl={anchorEl} open={open} onClose={handleClose} variant="contained" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} transformOrigin={{ vertical: 'bottom', horizontal: 'center', }} >
                <DataGridStyled
                    localeText={{
                        noRowsLabel: "No hay resultados para el historial de venta"
                    }}
                    rowHeight={25}
                    getRowId={params => params._id}
                    loading={isFetching}
                    hideFooter
                    autoHeight
                    rows={history}
                    columns={columns}
                />
            </Popover>
        </>
    )
}

export default ProductSalesHistory