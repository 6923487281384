import { api, mutationConfig } from "../splitApi";
import { HaulierTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        // registerAdvanceToSupplier: build.mutation({
        //     query(body) {
        //         return { ...mutationConfig, url: "/register/advance/to/supplier", body };
        //     },
        //     invalidatesTags: ProviderTag
        // }),
        getHauliers: build.query({
            query: (params=undefined) => ({
                url: `/get/hauliers`,
                params
            }),
            providesTags: HaulierTag
        }),
        getMerchandiseTransported: build.query({
            query: (params=undefined) => ({
                url: `/get/merchandise/transported`,
                params
            }),
            providesTags: HaulierTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetHauliersQuery,
    useGetMerchandiseTransportedQuery,
} = Endpoints;