import { Box, Grid } from "@component/UIComponents"
import FinancialState from "./components/FinancialState"
import SaleOfTheDay from "./components/SaleOfTheDay"
import CreditsFromPreviousDays from "./components/CreditsFromPreviousDays"
import SaleOfTheMonth from "./components/SaleOfTheMonth"

const Panel = () => {
    return(
        <>
            <Box p={1} >
                <Grid container spacing={1} >
                    <Grid xs={3} >
                        <SaleOfTheMonth />
                    </Grid>
                    <Grid xs={3} >
                        <SaleOfTheDay />
                    </Grid>
                    <Grid xs={3} >
                        <CreditsFromPreviousDays />
                    </Grid>
                    <Grid xs={12} >
                        <FinancialState />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Panel