import { Box, Skeleton } from '@component/UIComponents'

const SkeletonInvoiceActionList = () => {
    return(
        <>
            <Box  display={'flex'} >
                <Skeleton variant="text" width={"80%"} height={60} />
            </Box>
        </>
    )
}

export default SkeletonInvoiceActionList