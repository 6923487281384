import { ArticleRoundedIcon, PersonSearchRoundedIcon, ReceiptRoundedIcon } from "@component/UIComponents"
import { usePanel } from "@hook/usePanel"
import { useURLParams } from "@hook/useURLParams"
import { useValidatePermission } from "@hook/useValidatePermission"
import { useGetCreditNotesQuery, useGetCreditsFromPreviousDaysQuery, useGetPaymentsToSalesInvoiceQuery, useGetPurchaseHistoryOfAProductQuery, useGetSaleOfTheDayQuery, useGetSaleQuery, useGetSalesQuery, useGetSalesSummaryQuery } from "@query/Sales"
import { calculateProfitMargin } from "@util/currencyFormat"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { totalPaymentsToSalesInvoice } from '../utils/totalPaymentsToSalesInvoice'

export const useGetSaleReportFilters = () => {
    const { getQueries } = useURLParams()
    const toDay = dayjs().format("MM-DD-YYYY")
    const { groupBy, invoiceType = "sale", startDate = toDay, typesOfCredit=undefined, endDate = toDay, invoiceNumber = undefined, identificationNumber = undefined, customer = undefined, city = undefined, neighborhood = undefined, businessType = undefined, branch = undefined, zone = undefined, list=undefined } = getQueries(["list", "typesOfCredit", "groupBy", "invoiceType", "startDate", "endDate", "invoiceNumber", "identificationNumber", "customer", "city", "neighborhood", "businessType", "branch", "zone"])
    const filters = useMemo(() => ({ list, startDate, endDate, invoiceNumber, identificationNumber, customer, city, neighborhood, businessType, branch, zone, invoiceType, typesOfCredit, groupBy, }), [list, groupBy, typesOfCredit, invoiceType, invoiceNumber, identificationNumber, customer, city, neighborhood, businessType, branch, zone, startDate, endDate])
    return filters
}

export const useSalesCalculation = (params = {}) => {
    let { sales = [] } = params
    const { addQuery } = useURLParams()
    const { t } = useTranslation()
    const currentSales = useMemo(() => (sales), [sales])
    let summaryByDays = []
    const sale = currentSales.reduce((acc, params) => (params?.total ?? 0) + acc, 0)
    const profit = currentSales.reduce((acc, params) => (params?.totalProfitCurrency ?? 0) + acc, 0)
    const totalSale = { title: t('totalSale'), sale: sale, profit: profit, margin: calculateProfitMargin(sale, profit) }

    for (let n of currentSales) {
        let day = dayjs(n.createdAt).format("DD-MM-YYYY")
        let d = dayjs(n.createdAt).day()
        let m = dayjs(n.createdAt).month()
        const summaryByDayIndex = summaryByDays.findIndex((b) => b.date === day)
        if (summaryByDayIndex >= 0) {
            summaryByDays[summaryByDayIndex].sale += (n?.total ?? 0)
            summaryByDays[summaryByDayIndex].profit += (n?.totalProfitCurrency ?? 0)
            summaryByDays[summaryByDayIndex].margin = calculateProfitMargin(summaryByDays[summaryByDayIndex].sale, summaryByDays[summaryByDayIndex].profit)
        } else {
            summaryByDays.push({
                title: `${t(`day_${d}`)} ${dayjs(n.createdAt).format("DD")} de ${t(`month_${m}`)}`,
                date: day,
                sale: (n?.total ?? 0),
                profit: (n?.totalProfitCurrency ?? 0),
                margin: (n?.totalProfitPercentage ?? 0),
            })
        }
    }
    summaryByDays = summaryByDays.sort((a, b) => {
        const dateA = dayjs(a.date, "DD-MM-YYYY");
        const dateB = dayjs(b.date, "DD-MM-YYYY");
        return dateB - dateA;
    });
    summaryByDays = summaryByDays.map((n) => ({ ...n, onClick: () => addQuery({ sd: dayjs(n.date, "DD-MM-YYYY").format("MM-DD-YYYY"), ed: dayjs(n.date, "DD-MM-YYYY").format("MM-DD-YYYY") }) }))
    summaryByDays.unshift(totalSale)

    return {
        summaryByDays,
    }
}

export const useGetSalesSummary = (filters = {}, config = {}) => {
    const { ...rest } = filters
    const { data, isFetching } = useGetSalesSummaryQuery(rest, config)
    const salesSummary = useMemo(() => ((data?.payload ?? [])), [data])

    return {
        salesSummary,
        isFetching,
    }
}

export const useGetSales = (filters = {}) => {
    const { typesOfCredit, groupBy, ...rest } = filters
    const { data, isLoading, isFetching } = useGetSalesQuery(rest)
    let results = useMemo(() => ((data?.payload ?? [])), [data])
    let groupResults = []
    let customerTracking = { customerTracking: [], columnsDate: [] }

    const createSummaryPerDay = (report=[]) => {
        const summary = {};
        [...report].forEach(item => {
            const date = dayjs(item.creditExpirationDate).format("MM-DD-YYYY");
            if (summary[date]) {
                summary[date].total += item.total;
                summary[date].totalProfitCurrency += item.totalProfitCurrency;
            } else {
                summary[date] = {
                    date,
                    total: item.total,
                    totalProfitCurrency: item.totalProfitCurrency,
                };
            }
        });
        let result = Object.values(summary);
        result = result.map((n) => ({...n, totalProfitPercentage: calculateProfitMargin((n?.total??0), (n?.totalProfitCurrency??0)) }))
        return result
    }
    const { groupArrayByOptionList } = usePanel()

    if( Boolean(groupBy) ){
        const availableGroups = ["city", "branch", "zone", "neighborhood", "businessType", "customers"]
        groupResults = groupArrayByOptionList({ list: results, availableGroups, groupBy,
            groupAction: (value, index) => groupBy === "customers" ?
            (value?.customer?.name??"")
            :
            (value?.customer[index]?.name??"")
        })
    }

    return {
        results,
        groupResults: groupResults,
        group: Boolean(groupBy) ? groupResults.map((n) => (n?.results??[])) : [results],
        isLoading,
        isFetching,
        customerTracking,
        createSummaryPerDay,
    }
}

export const useGetPaymentsToSalesInvoice = (filters={}, config={} ) => {
    const { data, isFetching } = useGetPaymentsToSalesInvoiceQuery(filters, config)
    let payments = (data?.payload??[])
    return {
        payments,
        isFetching
    }
}

export const useGetSale = (filters={}, config={}) => {
    const { ref } = filters
    const { paymentData=true, creditNoteList=true, ...restConfig } = config
    const { data, isFetching } = useGetSaleQuery(filters, { skip: !Boolean(ref), ...restConfig })
    let sale = useMemo(() => Boolean(ref) ? (data?.payload??null) : null, [ref, data])
    const { payments, isFetching:isFetchingPayments } = useGetPaymentsToSalesInvoice({ ref }, { skip: !Boolean(ref) && !Boolean(paymentData), })
    const { data:dataCreditNotes, isFetching:isFetchingCreditNotes } = useGetCreditNotesQuery({ saleId: ref }, { skip: !Boolean(ref) && !Boolean(paymentData) && !Boolean(creditNoteList), })
    const creditNotes = useMemo(() => (dataCreditNotes?.payload??[]), [dataCreditNotes])

    const currentSale = useMemo(() => {
        let cSale = {...sale}
        cSale = {...cSale, ...totalPaymentsToSalesInvoice((cSale?.total??0), (payments??[]))}
        return Boolean(ref) ? cSale : null
    }, [sale, payments, ref])

    return {
        sale: currentSale,
        isFetching,
        isFetchingPayments,
        isFetchingCreditNotes,
        creditNotes
    }
}

export const useGetPurchaseHistoryOfAProduct = (filters = {}, options = {}) => {
    const { data, isFetching } = useGetPurchaseHistoryOfAProductQuery(filters, options)
    const history = (data?.payload ?? [])

    return {
        history,
        isFetching
    }
}

export const useSaleMenus = (params = {}) => {
    const { validateArrayByPermissionOrder } = useValidatePermission()
    const primaryMenu = (config = {}) => {
        const { isActiveCustomerTracking = false, onCustomerTracking = () => null, onViewSaleSummary = () => null } = config
        let buttons = [
            {
                title: "Seguimiento de clientes",
                onClick: () => onCustomerTracking(),
                icon: <PersonSearchRoundedIcon />,
                buttonProps: {
                    variant: isActiveCustomerTracking ? "contained" : "text",
                    disableElevation: true,
                }
            },
            {
                title: "Resumen del informe",
                onClick: () => onViewSaleSummary(),
                icon: <ArticleRoundedIcon />,
                buttonProps: {}
            },
        ]
        buttons = validateArrayByPermissionOrder(["show_customer_tracking", "show_summary_of_the_sale_report"], buttons)
        return buttons
    }

    const secondaryMenu = (params = {}, config = {}) => {
        const { _id } = params
        const { onViewInvoice = () => null } = config
        let options = [
            {
                title: "Ver factura",
                onClick: () => onViewInvoice(_id),
                icon: <ReceiptRoundedIcon />
            },
            // {
            //     title: "Ver cambios realizados",
            //     onClick: () => null,
            //     icon: <DynamicFeedRoundedIcon />
            // },
        ]
        options = validateArrayByPermissionOrder(["show_sales_invoice"], options)
        return options
    }

    return {
        secondaryMenu,
        primaryMenu,
    }
}

export const useGetSaleOfTheDay = (filter={}, config={}) => {
    const { data, isFetching } = useGetSaleOfTheDayQuery(filter, config)
    const payload = useMemo(() => ((data?.payload??[])[0]??null), [data])
    const { invoicesFound=0, total=0, totalProfitCurrency=0, balance=0, results=[], totalProfitPercentage=0 } = (payload??{})

    return {
        isFetching,
        invoicesFound,
        total,
        totalProfitCurrency,
        balance,
        results,
        totalProfitPercentage,
    }
}

export const useGetCreditsFromPreviousDays = () => {
    const { data, isFetching } = useGetCreditsFromPreviousDaysQuery()
    const payload = useMemo(() => ((data?.payload??[])[0]??null), [data])
    const { invoicesFound=0, total=0, results=[] } = (payload??{})

    return {
        isFetching,
        invoicesFound,
        total,
        results,
    }
}