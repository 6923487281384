import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, EditIconRounded, FileDownloadIconRounded, FileUploadRoundedIcon, ManageSearchRoundedIcon } from "@component/UIComponents"
import { formErrorList } from "@hook/useFormTools"
import { yup } from "@hook/useFormik"
import { useManageFormData, usePanel } from "@hook/usePanel"
import { useURLParams } from "@hook/useURLParams"
import { useGetProductFamilyQuery, useGetProductPurchaseHistoryQuery, useGetProductQuery, useGetProductSalesHistoryQuery, useGetProductsByTypeQuery, useGetProductsQuery, useMassLoadingOfProductsMutation, useRegisterProductMutation } from "@query/Products"
import { calculateProfitAndBaseMarginSale } from "@util/currencyFormat"
import { asynchronousResponseHandler, convertURLFileInObjectFile, preventAction, transformStringIntoArray } from "@util/helpers"
import { createBook, readBook } from "@util/xlsx"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useGetProductSalesHistory = (filters={}) => {
    const { data, isFetching } = useGetProductSalesHistoryQuery(filters)
    const history = (data?.payload??[])

    return {
        history,
        isFetching
    }
}

export const useGetProductPurchaseHistory = (filters={}) => {
    const { data, isFetching } = useGetProductPurchaseHistoryQuery(filters)
    const history = (data?.payload??[])

    return {
        history,
        isFetching
    }
}

export const useGetProductsFilter = () => {
    const { getQueries } = useURLParams()
    const { groupBy=undefined, showProduct=undefined, categories=undefined, providers=undefined, listType=undefined } = getQueries(['showProduct', 'groupBy', 'categories', 'providers', 'listType'])

    return {
        groupBy,
        showProduct,
        categories,
        providers,
        listType,
    }
}

export const useGetProducts = (filters={}, config={}) => {
    const { groupArrayByOptionList } = usePanel()
    const { groupBy, showProduct, categories, providers, ...rest} = filters
    const { data, isFetching } = useGetProductsQuery(rest, config)
    let results = useMemo(() => (data?.payload??[]), [data])
    let productList = []

    if( showProduct === "single" ) results = results.filter((n) => !Boolean(n?.compound) && !Boolean(n?.productParent) )
    if( showProduct === "compound" ) results = results.filter((n) => Boolean(n?.compound) && Boolean(n?.productParent) )
    if( Boolean(categories) ) {
        let r = transformStringIntoArray(categories, ",")
        results = results.filter((n) => r.some((b) => b === (n?.category?.name??"")) )
    }
    if( Boolean(providers) ){
        let r = transformStringIntoArray(providers, ",")
        results = results.filter((n) => r.some((b) => b === (n?.provider?.name??"")) )
    }
    if( Boolean(groupBy) ){
        productList = groupArrayByOptionList({
            availableGroups: ["provider", "category"],
            groupBy,
            list: [...results],
            groupAction: (n, field) => (n[field]?.name??"")
        })
    }else{
        productList = [{results: [...results]}]
    }

    const getProductsByCategories = useCallback((categories=[]) => {
        let r = [...results]
        r = r.filter((n) => categories.some((b) => b === (n?.category?._id??"")) )
        return categories.length <= 0 ? [] : r
    }, [results])

    const getProductsByPackaging = useCallback((packages=[]) => {
        let r = [...results]
        r = r.filter((n) => packages.some((b) => b === (n?.packaging?._id??"")) )
        return packages.length <= 0 ? [] : r
    }, [results])

    return {
        results,
        isFetching,
        productList,
        getProductsByCategories,
        getProductsByPackaging
    }
}

export const useGetProduct = (filters={}, config={}) => {
    const { data, isFetching } = useGetProductQuery(filters, config)
    let product = useMemo(() => (data?.payload??null), [data])
    product = Boolean(filters?.productId) ? product : null
    return {
        product,
        isFetching,
    }
}

export const useGetProductsByType = (filters={}) => {
    const { data, isFetching } = useGetProductsByTypeQuery(filters, { skip: !Boolean(filters?.variant) })
    let results = useMemo(() => (data?.payload??[]), [data])
    return {
        results,
        isFetching,
    }
}

export const useGetProductFamily = (filters={}) => {
    const { data, isFetching } = useGetProductFamilyQuery(filters)
    let productFamily = (data?.payload??[])

    return {
        productFamily,
        isFetching
    }
}

export const useXLSXProducts = () => {
    const { validate } = useValidateDataLoadedFromExcel()
    const { getTranslationListKey } = usePanel()
    const downloadData = useCallback((props={}) => {
        let { selectedFields=[], rows=[] } = props
        let rowsToBook = []
        rows = rows.map((product) => {
            let r = {...product}
            let prices = (r?.prices??[])
            prices.forEach((price, index) => {
                r[`sellPrice${(index+1)}`] = (price?.sellPrice??0)
            })
            delete r.prices
            return r
        })

        for( let product of rows ){
            let newObject = {}
            for( let params of selectedFields ){
                let { field="", name="" } = params
                if( field === "tax" && Boolean(product[field])){
                    product[field] = `${(product[field]?.name??"")} ${(product[field]?.tax??0)}%`
                }
                if( typeof product[field] === "boolean" ) product[field] = Boolean(product[field]) ? "Si" : "No"
                if( typeof product[field] === "object" && !Array.isArray(product[field]) ) product[field] = (product[field]?.name??"")
                newObject[name] = product[field]
            }
            rowsToBook.push(newObject)
        }
        createBook({ rows: rowsToBook, fileName: `${dayjs().format("MMDDYYYYHHmmss")}.xlsx` })
    }, [])

    const loadData = useCallback((files=[], ref, props={}) => {
        const { onManageData=()=>null } = props

        if( Boolean(ref.current) ) ref.current.value = ""
        readBook(files)
        .then((data=[]) => {
            let productsNameFieldsCorrected = []
            const products = [...data]
            for( let productIndex in products ){
                let newResult = {}
                let product = products[productIndex]
                for( let [i, n] of Object.entries(product) ){
                    let field = getTranslationListKey(i)
                    if(field === "product") field = "name"
                    if( n === "Si" || n === "SI" || n === "si" ) n = true
                    if( n === "No" || n === "NO" || n === "no" ) n = false
                    if(field === "productParent") n = Boolean(n) ? n : null
                    if(field === "consecutive") n = Boolean(n) ? n : null

                    newResult[field] = n
                }
                productsNameFieldsCorrected.push(newResult)
            }
            productsNameFieldsCorrected = productsNameFieldsCorrected.map((product) => {
                let r = {...product}
                const regexSellPrice = /sellPrice\d+/;
                const sellPriceFields = Object.keys(r).filter((n) => regexSellPrice.test(n))
                const prices = sellPriceFields.map((priceField) => {
                    const sellPrice = (r[priceField]??0)
                    const { margin, profit } = calculateProfitAndBaseMarginSale((r?.cost??0), sellPrice)
                    let price = { sellPrice: r[priceField], profit, percentageProfit: margin }
                    delete r[priceField]
                    return price
                })
                r.prices = prices
                return r
            })
            const { type, results } = validate(productsNameFieldsCorrected)
            if( type === "errors" ) return
            if( type === "OK" ){
                preventAction({
                    icon: "success",
                    text: "El archivo se valido exitosamente, ¿Desea cargar la información?.",
                    onSubmit: () => onManageData(results)
                })
            }
        })
        .catch(err => console.log(err))
    }, [getTranslationListKey, validate])

    return {
        downloadData,
        loadData
    }
}

export const useProductMenu = () => {

    const primaryMenu = useCallback((props={}, config={}) => {
        const { onCreateProduct=()=>null, onDownloadXLSX=()=>null, onLoadXLSX=()=>null } = config

        return [
            {
                title: "Crear producto",
                onClick: () => onCreateProduct(),
                icon: <AddCircleOutlineIconRounded />,
            },
            {
                title: "Descargar plantilla",
                onClick: () => onDownloadXLSX(),
                icon: <FileDownloadIconRounded />,
            },
            {
                title: "Cargar plantilla",
                onClick: () => onLoadXLSX(),
                icon: <FileUploadRoundedIcon />,
            },
        ]
    }, [])
    
    const secondaryMenu = useCallback((props={}, config={}) => {
        const { onEdit=()=>null, onSaleHistory=()=>null, onPurchaseHistory=()=>null, onArchived=()=>null } = config
        const isCompoundProduct = Boolean(props?.compound)
        const productId = (props?._id??null)
        return [
            {
                title: "Editar",
                onClick: () => onEdit(productId),
                icon: <EditIconRounded />,
            },
            {
                title: "Historial de venta",
                onClick: () => onSaleHistory(productId),
                icon: <ManageSearchRoundedIcon />,
            },
            ...(isCompoundProduct ? [] : [
                {
                    title: "Historial de compra",
                    onClick: () => onPurchaseHistory(productId),
                    icon: <ManageSearchRoundedIcon />,
                }
            ]),
            {
                title: "Archivar",
                onClick: () => preventAction({
                    text: `¿Desea archivar el producto ${(props?.name??"")}?`,
                    onSubmit: () => onArchived((props?._id??null))
                }),
                icon: <ArchiveRoundedIcon />,
            },
        ]
    }, [])

    return {
        primaryMenu,
        secondaryMenu,
    }
}

export const useProductOptions = () => {
    let primaryOptions = useMemo(() => {
        return [
            {name: "Compuestos", value: "compound"},
            {name: "Simples", value: "single"},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])

    let secondaryOptions = useMemo(() => {
        return [
            {name: "Proveedores", value: "provider"},
            {name: "Categorías", value: "category"},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])
    
    let thirdOption = useMemo(() => {
        return [
            {name: "Información general de productos", value: "products"},
            {name: "Información general de inventario", value: "inventory"},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])

    let fourthOption = useMemo(() => {
        return [
            {name: "Impuesto", value: "tax"},
            {name: "Proveedor", value: "provider"},
            {name: "Producto padre", value: "productParent"},
            {name: "Precio de venta 1", value: "sellPrice1"},
            {name: "Precio de venta 2", value: "sellPrice2"},
            {name: "Precio de venta 3", value: "sellPrice3"},
            {name: "Mostrar en punto de venta", value: "activePOS"},
            {name: "Mostrar en compras", value: "activePurchase"},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])
    
    let fifthOptions = useMemo(() => {
        return [
            {name: "Punto de venta", value: "pointOfSale"},
            {name: "Compras", value: "purchase"},
        ].map((n, index) => ({...n, _id: (index+1)}))
    }, [])



    return {
        primaryOptions,
        secondaryOptions,
        thirdOption,
        fourthOption,
        fifthOptions,
    }
}

export const useProductFormData = (config={}) => {
    const [ resolvedDefectValues, setResolvedDefectValues ] = useState(null)
    const { defaultValues=undefined, openModal=false } = config

    const dfv = useCallback(async () => {
        let resp = null
        if( Boolean(defaultValues) ){
            let currentDefaultValues = {...defaultValues}
            currentDefaultValues.images = await convertURLFileInObjectFile((currentDefaultValues?.images??[]))
            resp = currentDefaultValues
        }
        setResolvedDefectValues(resp)
    }, [defaultValues])

    useEffect(() => {
        dfv()
    }, [dfv])

    const initialValues = useMemo(() => ({ name: "", productParent: null, activePOS: true, activePurchase: true, category: null, packaging: null, provider: null, stock: 0, skuCode: "", tax: null, cost: 0, prices: [{ sellPrice: 0, percentageProfit: 0, profit: 0 }], images: [], purchaseCodes: [] }), [])
    const schema = {
        name: yup.string().required("El nombre del producto es un campo obligatorio."),
    }
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues: resolvedDefectValues,
        initialValues,
        schema,
        openModal,
    })

    const { prices } = values

    const updatePrices = useCallback((newPrice, index) => {
        let currentPrices = [...prices]
        currentPrices[index] = newPrice
        let lastPrice = currentPrices[currentPrices.length-1]

        if( Boolean(lastPrice?.sellPrice) ){
            if( currentPrices.length < 3 ){
                currentPrices.push({ sellPrice: 0, percentageProfit: 0, profit: 0 })
            }
        }

        setFieldValue("prices", currentPrices)
    }, [prices, setFieldValue])
    return { values, setFieldValue, resetValues, validateValues, updatePrices, schema }
}

export const useValidateDataLoadedFromExcel = () => {
    const schema = yup.array().min(1, "Debe añadir al menos un elemento a la lista.").max(100, "La cantidad maxima de productos a cargar es de 100 productos por archivo.").of(yup.object().shape({
        activePOS: yup.boolean("Mostrar en punto de venta: los valores validos son (Si) y (No)"),
        activePurchase: yup.boolean("Mostrar en compras: los valores validos son (Si) y (No)"),
        category: yup.string(),
        consecutive: yup.number().nullable(),
        cost: yup.number(),
        name: yup.string().required("El nombre del producto es requerido."),
        packaging: yup.string(),
        prices: yup.array().of(yup.object().shape({
            sellPrice: yup.number("Precio de venta: Debe ser un valor numérico."),
            profit: yup.number("Ganancia: Debe ser un valor numérico."),
            percentageProfit: yup.number("Margen: Debe ser un valor numérico."),
        })),
        productParent: yup.number().nullable(),
        provider: yup.string(),
        skuCode: yup.string(),
        stock: yup.number(),
        tax: yup.string().nullable().matches(/IVA\s([1-9]|[1-8][0-9])%$/),
    }))

    const validate = useCallback((products=[]) => {
        try {
            schema.validateSync(products, { abortEarly: false })
            return { type: "OK", results: products }
        } catch (error) {
            formErrorList((error?.errors??[]), "error")
            return { type: "errors", results: (error?.errors??[]) }
        }
    }, [schema])

    return {
        validate
    }
}

export const useRegisterProduct = () => {
    const [ registerProductMutation, { isLoading } ] = useRegisterProductMutation()
    const registerProduct = async (payload, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await registerProductMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean(payload?._id??null) ?
                        "El producto se actualizo exitosamente."
                        :
                        "El producto se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            throw error
        }
    }
    return {
        registerProduct,
        isLoading
    }
}

export const useMassLoadingOfProducts = () => {
    const [ massLoadingOfProductsMutation, { isLoading } ] = useMassLoadingOfProductsMutation()
    
    const massLoadingOfProducts = async (payload, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await massLoadingOfProductsMutation(payload).unwrap()
                const productsCreated = (resp?.payload?.create??[]).length
                const productsUpdate = (resp?.payload?.update??[]).length
                asynchronousResponseHandler(resp, {
                    successMessage: `La carga del archivo se realizo exitosamente, se registraron ${productsCreated} nuevos y se actualizaron ${productsUpdate} productos existente.`,
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            throw error
        }
    }

    return {
        massLoadingOfProducts,
        isLoading
    }
}