import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useCallback, useEffect, useMemo } from "react"

export const useDeliveryInformationFormController = ({defaultValues=null, onSubmit=()=>null, invoiceRef=null}) => {
    const initialValues = useMemo(() => ({ invoiceRef: null, delivery: null, deliveryPaymentMethod: null, deliveryAmount: 0 }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            invoiceRef: yup.string().required("Ocurrió un problema al cargar el identificador de la factura, cierre la ventana he intente nuevamente."),
            delivery: yup.object().shape({
                name: yup.string().required("El domiciliario es requerido.")
            }).required("El domiciliario es requerido."),
            deliveryPaymentMethod: yup.string().oneOf(['inBox', 'uponDelivery'], 'Seleccione un medio de pago para el domicilio.').required('Seleccione un medio de pago para el domicilio.'),
            deliveryAmount: yup.number().min(0).required("El valor del domicilio es requerido."),
        }
    })

    const submitValues = useCallback(() => {
        let currentValues = {...values}
        currentValues.deliveryPaymentMethod = (currentValues?.deliveryPaymentMethod?.value??"")
        const { type } = validateValues(currentValues)
        if(type === "errors") return
        onSubmit(currentValues)
    }, [values, validateValues, onSubmit])

    const loadInvoiceRef = useCallback(() => {
        if( Boolean(invoiceRef) ){
            setFieldValue("invoiceRef", invoiceRef)
        }
    }, [invoiceRef, setFieldValue])

    useEffect(() => {
        loadInvoiceRef()
    }, [loadInvoiceRef])

    return { values, setFieldValue, resetValues, validateValues, submitValues }
}