import { Fragment } from "react"
import { Badge, IconButton, Tooltip } from '@component/UIComponents'

const IconButtonVariant = ({ options=[], sxButton={}, setPopoverProps=()=>null }) => {
    return(
        <>
            {options?.map((item, index) => {
                const { button=null, buttonProps={}, badge=0, icon=<></>, title="", onClick=()=>null, onPopover=undefined } = (item??{})
                return(
                    <Fragment key={index} >
                        {Boolean((button??null)) ?
                            <>
                                {(button??<></>)}
                            </>
                            :
                            <Tooltip arrow title={(title??"")} >
                                <IconButton sx={{...sxButton}} size="small" color="primary" onClick={ (event) => Boolean(onPopover) ? onPopover(event, setPopoverProps) : onClick()} {...(buttonProps??{})}  >
                                    <Badge badgeContent={(badge??0)} color="primary">
                                        {(icon??<></>)}
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        }
                    </Fragment>
                )
            })}
        </>
    )
}

export default IconButtonVariant