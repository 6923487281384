import { Box, Card } from '@component/UIComponents'
import { useGetSaleOfTheDay } from "@feature/Reports/Sales/hooks/useSales"
import { currencyFormat } from '@util/currencyFormat'
import dayjs from 'dayjs'

const SaleOfTheDay = () => {
    const { total, totalProfitCurrency, totalProfitPercentage, invoicesFound } = useGetSaleOfTheDay({
        startDate: dayjs().format("MM-DD-YYYY"),
        endDate: dayjs().format("MM-DD-YYYY"),
    }, {refetchOnMountOrArgChange: true})
    return(
        <>
            <Card elevation={0} variant='outlined' sx={{ padding: 2, height: 110 }} >
                <Box lineHeight={1.2} fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} >
                    Venta del dia :
                    <Box fontSize={'.775rem'} >
                        { invoicesFound <= 0 && `No hay ventas registradas`}
                        { invoicesFound === 1 && `${invoicesFound} venta registrada`}
                        { invoicesFound >= 2 && `${invoicesFound} ventas registradas`}
                    </Box>
                </Box>
                <Box fontSize={'1.3rem'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }} >
                    {currencyFormat(total)}
                </Box>
                <Box fontSize={'.775rem'} fontWeight={'bold'} display={'flex'} gap={2} textTransform={'uppercase'} sx={{ color: (theme) => theme.palette.primary.light }} >
                    <Box> ganancia: <Box component={'span'} sx={{ textDecoration: 'underline' }} > {currencyFormat(totalProfitCurrency)} </Box> </Box>
                    <Box> margen: <Box component={'span'} sx={{ textDecoration: 'underline' }} > {totalProfitPercentage} % </Box> </Box>
                </Box>
            </Card>
        </>
    )
}

export default SaleOfTheDay