import { FixedSizeList as FixedSizeListWindow } from 'react-window'
import { Box } from '@component/UIComponents'

const FixedSizeList = ({ list=[], height=(window.innerHeight-100), itemSize=550, render=()=><></> }) => {

    return (
        <>
            <FixedSizeListWindow height={height} itemCount={list.length} itemSize={itemSize} width={"100%"} >
                {({ index, style }) => {
                    const iteration = list[index]
                    return (
                        <Box style={style} >
                            {render(iteration, index, list)}
                        </Box>
                    )
                }}
            </FixedSizeListWindow>
        </>
    )
}

export default FixedSizeList