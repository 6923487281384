import NumericFormat from '@component/NumericFormat'
import { Box, Stack }from '@component/UIComponents'
import { calculateProfitAndBaseMarginSale, calculateProfitAndSaleBasedMargin, calculateSalesAndMarginBasedProfit } from '@util/currencyFormat'
import { memo, useCallback } from 'react'

const PriceListForm = ({ cost=0, sellPrice=0, profit=0, percentageProfit=0, onChange=()=>null }) => {

    const handleChangeSellPrice = useCallback((value=0) => {
        const field = (document?.activeElement?.id??null)
        if( Boolean(field)){
            if( field === "sellPrice" ){
                const { margin, profit } = calculateProfitAndBaseMarginSale(cost, value)
                if( Boolean(onChange) ) onChange({ sellPrice: value, percentageProfit: margin, profit })
                return
            }
        }
    }, [onChange, cost])

    const handleChangeProfit = useCallback((value=0) => {
        const field = (document?.activeElement?.id??null)
        if( Boolean(field)){
            if( field === "profit" ){
                const { margin, sale } = calculateSalesAndMarginBasedProfit(cost, value)
                if( Boolean(onChange) ) onChange({ sellPrice: sale, percentageProfit: margin, profit: value })
                return
            }
        }
    }, [onChange, cost])

    const handleChangeMargin = useCallback((value=0) => {
        const field = (document?.activeElement?.id??null)
        if( Boolean(field)){
            if( field === "percentageProfit" ){
                const { profit, sale } = calculateProfitAndSaleBasedMargin(cost, value)
                if( Boolean(onChange) ) onChange({ sellPrice: sale, percentageProfit: value, profit })
                return
            }
        }
    }, [onChange, cost])

    return(
        <>
            <Stack direction={'column'} mb={1} >
                <Stack direction={'row'} spacing={1}>
                    <Box>
                        <NumericFormat
                            id="sellPrice"
                            value={(sellPrice??0)}
                            label="Precio de venta"
                            onChange={({ floatValue }) => handleChangeSellPrice(floatValue)}
                            disabled={(cost <= 0)}
                        />
                    </Box>
                    <Box>
                        <NumericFormat
                            id="profit"
                            value={(profit??0)}
                            label="Ganancia"
                            onChange={({ floatValue }) => handleChangeProfit(floatValue)}
                            disabled={(cost <= 0)}
                        />
                    </Box>
                    <Box>
                        <NumericFormat
                            id="percentageProfit"
                            prefix=""
                            suffix="%"
                            value={(percentageProfit??0)}
                            label="Margen"
                            onChange={({ floatValue }) => handleChangeMargin(floatValue)}
                            disabled={(cost <= 0)}
                        />
                    </Box>
                </Stack>
            </Stack>
        </>
    )
}

export default memo(PriceListForm)