import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '@util/routes'
import { useTranslation } from 'react-i18next'
import TabAppContext from '@component/TabAppContext'
import Purchases from './Purchases'
const { purchaseReport } = routes

const PurchaseReport = () => {
    const { t } = useTranslation()
    const tabs = [
        { label: t('purchases'), value: "purchases", component: <Purchases /> },
        { label: t('discounts'), value: "discounts", component: <></> },
    ]
    const navigate = useNavigate()
    const { tab = "purchases" } = useParams()
    const handleChange = (event, newValue) => navigate(purchaseReport(newValue))

    return (
        <>
            <TabAppContext tab={tab} tabs={tabs} onChange={handleChange} />
        </>
    )
}

export default PurchaseReport