import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import dayjs from "dayjs"
import { useMemo } from "react"

export const useUpdateCreditExpirationDateForm = (props = {}) => {
    const { defaultValues = undefined } = props
    const initialValues = useMemo(() => ({ saleRef: null, creditExpirationDate: dayjs() }), [])

    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            saleRef: yup.string().required("El código de la factura no se cargo correctamente, cierre la ventana he intente de nuevo."),
            creditExpirationDate: yup.date().required("La fecha de vencimiento del crédito es un campo obligatorio."),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}