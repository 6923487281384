import { Box } from '@component/UIComponents'
import ActionsList from '@component/ActionsList'
import { usePOSMenu } from '../hooks/usePOS'
import CashierActions from './CashierActions'
import { useGetUserSessionInformation } from '@feature/Users'
import CustomerForm from '@feature/Customers/CustomerForm'
import { useState } from 'react'
import ProductForm from '@feature/Inventory/Products/ProductForm'

const SalesMenu = ({ setFieldValue=()=>null }) => {
    const [showForms, setShowForms] = useState({ registerClient: false, registerProduct: false })
    const { balance, userData } = useGetUserSessionInformation()
    const { optionActions } = usePOSMenu()

    return(
        <>
            <Box display={'flex'} gap={1} sx={{
                '& .MuiSvgIcon-root': {
                    fontSize: "1.5rem"
                }
            }} >
                <ActionsList
                    variant='iconButton'
                    sxButton={{
                        fontSize: ".875rem !important",
                        fontWeight: "bold",
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        color: (theme) => theme.palette.grey[200]
                    }}
                    options={optionActions({
                        onRecordCustomer: () => setShowForms((prevState) => ({...prevState, registerClient: true})),
                        onRecordProduct: () => setShowForms((prevState) => ({...prevState, registerProduct: true})),
                    })}
                />
                <CashierActions variantListButton='mobileMenu' balance={balance} refUser={(userData?._id??null)} />
            </Box>
            <CustomerForm
                open={showForms.registerClient}
                defaultValues={null}
                onClose={() => setShowForms((prevState) => ({...prevState, registerClient: false}))}
                actionWhenClosingFormWithSuccessfulRegistration={(customer) => setFieldValue("customer", customer)}
            />
            <ProductForm
                open={showForms.registerProduct}
                onClose={() => setShowForms((prevState) => ({...prevState, registerProduct: false}))}
            />
        </>
    )
}

export default SalesMenu