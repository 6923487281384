import SuperDataGrid from "@component/SuperDataGrid"
import { Avatar, Box, CameraAltIconRounded, Grid, Typography } from "@component/UIComponents"
import { useGetProducts, useMassLoadingOfProducts, useProductMenu, useXLSXProducts } from "./hooks/useProducts"
import { useMemo, useRef, useState } from "react"
import { currencyFormat } from "@util/currencyFormat"
import ActionsList from "@component/ActionsList"
import ProductForm from "./ProductForm"
import ProductSalesHistory from "../ProductSalesHistory"
import ProductPurchaseHistory from "../ProductPurchaseHistory"
import { calculateTotalInventory, generateQuickSummary } from "./utils/helpersToProducts"
import Filters from "./components/Filters"
import ListRendering from "@component/ListRendering"
import spent from '@style/images/agotado.png'
import available from '@style/images/en-stock.png'
import DownloadProductUploadTemplate from "./components/DownloadProductUploadTemplate"
import { preventAction } from "@util/helpers"
import XLSXFileAlert from "./components/XLSXFileAlert"
import ComponentTitle from "@component/ComponentTitle"
import { useProductsController } from "./hooks/useProductsController"

const Products = () => {
    const { filters, handlerSetFilters, activeFilters } = useProductsController()
    const [downloadExcelTemplate, setDownloadExcelTemplate] = useState(false)
    const [productPurchaseHistory, setProductPurchaseHistory] = useState({ open: false, productId: null })
    const [productSaleHistory, setProductSaleHistory] = useState({ open: false, productId: null })
    const [productForm, setProductForm] = useState({ open: false, productId: null })
    const { results=[], productList } = useGetProducts({ ...activeFilters, variant: "inventory" })
    const { primaryMenu, secondaryMenu } = useProductMenu()
    const { downloadData, loadData } = useXLSXProducts()
    const { massLoadingOfProducts } = useMassLoadingOfProducts()
    const inputFileRef = useRef()
    const fileUploadHandler = (files) => loadData(files, inputFileRef, { onManageData: (data) => massLoadingOfProducts({ products: data }) })

    const Buttons = (props={}) => <ActionsList
        title={<>
            <Box component={'span'} display={'flex'} alignItems={'center'} gap={1} >
                <Avatar src={props?.images[0]??undefined} sx={Boolean(props?.images[0]) ? { width: 60, height: 60 } : {}} >
                    <CameraAltIconRounded />
                </Avatar>
                {`${(props?.name??"")}`}
            </Box>
        </>}
        variant="mobileMenu"
        options={secondaryMenu(props, {
            onEdit: (productId) => setProductForm(prevState => ({...prevState, open: true, productId})),
            onSaleHistory: (productId) => setProductSaleHistory({open: true, productId}),
            onPurchaseHistory: (productId) => setProductPurchaseHistory({open: true, productId}),
        })}
    />

    const columns = useMemo(() => {
        let priceLength = (((results??[]).map((n) => (n?.prices??[])).sort((a, b) => (b?.length??0) - (a?.length??0))[0]??[])?.length??0)
        let r = [
            { flex: 1, minWidth: 300, field: "name", headerName: "Producto", renderCell: ({row}) => {
                return(
                    <>
                        <Box display={'flex'} gap={1} alignItems={'center'} >
                            <Box>
                                <Buttons {...row} />
                            </Box>
                            {(row?.stock??0) <= 0 ?
                                <Avatar src={spent} sx={{ width: 20, height: 20 }} />
                                :
                                <Avatar src={available} sx={{ width: 20, height: 20 }} />
                            }
                            <Typography fontSize={'.875rem'} >
                                {row?.name??""}
                            </Typography>
                        </Box>
                    </>
                )
            }},
            { maxWidth: 100, minWidth: 100, field: "consecutive", headerName: "Consecutivo", renderCell: ({row}) => `N°${(row?.consecutive??0)}` },
            { flex: 1, minWidth: 250, field: "productParent", headerName: "Producto padre", renderCell: ({row}) => Boolean(row?.productParent) ? `${row?.productParent?.name??""}` : "N/A" },
            { flex: 1, minWidth: 180, field: "category", headerName: "Categoría", renderCell: ({ row }) => `${(row?.category?.name??"")}` },
            { maxWidth: 150, minWidth: 150, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => Boolean(row?.packaging) ? `${(row?.packaging?.name??"")} - x${(row?.packaging?.quantity??0)}` : "" },
            { flex: 1, minWidth: 250, field: "provider", headerName: "Proveedor", renderCell: ({ row }) => (row?.provider?.name??"") },
            { maxWidth: 130, minWidth: 130, field: "cost", headerName: "Costo", renderCell: ({ row }) => `${currencyFormat((row?.cost??0))}` },
            { maxWidth: 80, minWidth: 80, field: "activePOS", headerName: "Mostrar en punto de venta", align: "center", renderCell: ({row}) => Boolean(row?.activePOS) ? "SI" : "NO"},
            { maxWidth: 80, minWidth: 80, field: "activePurchase", headerName: "Mostrar en compras", align: "center", renderCell: ({row}) => Boolean(row?.activePurchase) ? "SI" : "NO"},
            { maxWidth: 120, minWidth: 120, align: "center", field: "skuCode", headerName: "Código SKU" },
            { maxWidth: 120, minWidth: 120, field: "stock", headerName: "Stock", renderCell: ({ row }) => `${currencyFormat((row?.stock??0), {applyCurrencyFormat: false})}`},
            { maxWidth: 120, minWidth: 120, field: "totalStock", headerName: "Total Stock", renderCell: ({ row }) => `${currencyFormat(calculateTotalInventory(row))}`},
            { maxWidth: 90, minWidth: 90, field: "tax", headerName: "Impuesto", renderCell: ({row}) => Boolean(row?.tax) ? `${row?.tax?.name??""} ${row?.tax?.tax??""}%` : "" },
        ]
        const costIndex = r.findIndex((n) => (n?.field??"") === "cost")
        
        for( let i = 1; i <= priceLength; i++ ){
            if( costIndex >= 0 ){
                let row = { maxWidth: 130, minWidth: 130, field: `sellPrice${(i??0)}`, headerName: `Precio de venta ${(i??0)}`, renderCell: ({ row }) => `${currencyFormat(((row?.prices??[])[(i??0)]?.sellPrice??0))}` }
                r.splice((costIndex+i), 0, row)
            }
        }
        return r 
    }, [results])

    const columns2 = useMemo(() => {
        let r = [
            { flex: 1, minWidth: 300, field: "name", headerName: "Producto", renderCell: ({row}) => {
                return(
                    <>
                        <Box display={'flex'} gap={1} alignItems={'center'} >
                            {(row?.stock??0) <= 0 ?
                                <Avatar src={spent} sx={{ width: 20, height: 20 }} />
                                :
                                <Avatar src={available} sx={{ width: 20, height: 20 }} />
                            }
                            <Typography fontSize={'.875rem'} >
                                {row?.name??""}
                            </Typography>
                        </Box>
                    </>
                )
            }},
            { maxWidth: 150, minWidth: 150, field: "packaging", headerName: "Embalaje", renderCell: ({ row }) => Boolean(row?.packaging) ? `${(row?.packaging?.name??"")} - x${(row?.packaging?.quantity??0)}` : "" },
            { maxWidth: 130, minWidth: 130, field: "stockPackaging", headerName: "Stock por embalaje", renderCell: ({ row }) => (row?.compound === true && Boolean(row?.productParent?._id)) ? (row?.stock??0) : ((row?.stock??0)/(row?.packaging?.quantity??0)) },
            { maxWidth: 130, minWidth: 130, field: "stock", headerName: "Stock unitario", renderCell: ({ row }) => (row?.compound === true && Boolean(row?.productParent?._id)) ? ((row?.stock??0)*(row?.packaging?.quantity??0)) : (row?.stock??0) },
            { maxWidth: 150, minWidth: 150, field: "cost", headerName: "Costo unitario", renderCell: ({row}) => currencyFormat((row?.cost??0))},
            { maxWidth: 150, minWidth: 150, field: "costStock", headerName: "Costo por embalaje", renderCell: ({row}) => (row?.compound === true && Boolean(row?.productParent?._id)) ? currencyFormat((row?.cost??0)) : currencyFormat(((row?.cost??0)*(row?.packaging?.quantity??0))) },
            { maxWidth: 180, minWidth: 180, field: "totalStockCost", headerName: "Costo total en inventario", renderCell: ({row}) => (row?.compound === true && Boolean(row?.productParent?._id)) ? currencyFormat(((row?.cost??0)*(row?.stock??0))) : currencyFormat(((row?.cost??0)*(row?.stock??0))) },
        ]
        return r
    }, [])

    return(
        <>
            <ComponentTitle activeNavigatorTabTitle={true} title="Inventario" />
            <input style={{ display: "none" }} ref={inputFileRef} type="file" accept=".xlsx, .xls" onChange={({target}) => fileUploadHandler([...target.files]) } />
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        componentProps={{
                            filter: {
                                active: true,
                                props: {
                                    component: () => <Filters filters={{...filters}} setFiltersData={handlerSetFilters} />,
                                    filters,
                                }
                            },
                            portal: { active: true }
                        }}
                        options={primaryMenu(null, {
                            onCreateProduct: () => setProductForm(prevState => ({...prevState, open: true})),
                            onDownloadXLSX: () => preventAction({
                                component: <XLSXFileAlert />,
                                onSubmit: () => setDownloadExcelTemplate(true),
                                confirmButtonText: "Siguiente",
                                cancelButtonText: "Cancelar",
                            }),
                            onLoadXLSX: () => preventAction({
                                component: <XLSXFileAlert />,
                                onSubmit: () => Boolean(inputFileRef.current) ? inputFileRef.current.click() : null,
                                confirmButtonText: "Siguiente",
                                cancelButtonText: "Cancelar",
                            }),
                        })}
                    />
                </Grid>
                <Grid xs={12} >
                    <ListRendering
                        elements={[...productList].map((n) => (n?.results??[]))}
                        component={(list, index) => {
                            return(
                                <SuperDataGrid
                                    portalId="actionList-portal"
                                    onDownloadBook={downloadData}
                                    downloadData
                                    title={(productList[index]?.groupName??undefined)}
                                    fixedCells
                                    columnBuffer={((results?.length??0)*2)}
                                    columns={((filters?.listType??"") === "inventory") ? columns2 : columns}
                                    rows={list}
                                    filterBy={(param) => `${(param?.name??"")}`}
                                    onQuickList={generateQuickSummary}
                                />
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <ProductForm open={productForm.open} onClose={() => setProductForm(prevState => ({...prevState, open: false, productId: null}))} productId={(productForm?.productId??null)} />
            <ProductSalesHistory
                open={productSaleHistory.open}
                productId={productSaleHistory.productId}
                onClose={() => setProductSaleHistory(prevState => ({...prevState, open: false, productId: null}))}
            />
            <ProductPurchaseHistory
                open={productPurchaseHistory.open}
                productId={productPurchaseHistory.productId}
                onClose={() => setProductPurchaseHistory(prevState => ({...prevState, open: false, productId: null}))}
            />
            <DownloadProductUploadTemplate
                open={downloadExcelTemplate}
                onClose={() => setDownloadExcelTemplate(false)}
            />
        </>
    )
}

export default Products