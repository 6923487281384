import Autocomplete from "@component/Autocomplete"
import { useGetPackaging } from "../hooks/usePackaging"
import { useMemo } from "react"

const PackagingFinder = ({ helperText=undefined, placeholder=undefined, label="Embalajes", fullWidth=undefined, size=undefined, onChange=undefined, value=null, sx=undefined, inputRef=undefined }) => {
    const text = useMemo(() => {
        if( helperText === undefined ){
            return Boolean(value?._id) ? `Cantidad: x${(value?.quantity??0)}` : ""
        }else{
            return helperText
        }
    }, [helperText, value])

    const { packages, isLoading } = useGetPackaging()
    return(
        <>
            <Autocomplete
                placeholder={placeholder}
                ref={inputRef}
                sx={sx}
                onChange={onChange}
                helperText={text}
                value={value}
                fullWidth={fullWidth}
                size={size}
                label={label}
                options={packages}
                loading={isLoading}
                renderOption={(props, option) => (<li {...props} key={option._id} > {option.name} = x{(option?.quantity??0)} </li>)}
            />
        </>
    )
}

export default PackagingFinder