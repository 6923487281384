import { useLazyValidateBusinessCode } from "@feature/Business/hooks/useLazyValidateBusinessCode"
import { Box, CheckCircleOutlineRoundedIcon, CircularProgress, HighlightOffRoundedIcon, Tooltip } from "@component/UIComponents"
import { useCallback, useEffect, useState } from "react"

const BusinessCodeValid = ({ businessCode="" }) => {
    const [loading, setLoading] = useState(false)
    const [validateCurrentBusinessCode, setValidateCurrentBusinessCode] = useState({ exist: null, showResult: false })
    const { lazyValidateBusinessCodeQuery } = useLazyValidateBusinessCode()

    const validateBusinessCode = useCallback( async () => {
        if( businessCode.length === 6 ){
            setValidateCurrentBusinessCode((prevState) => ({...prevState, exist: null, showResult: true}))
            setLoading(true)
            const { data } = await lazyValidateBusinessCodeQuery({ businessCode })
            const exist = (data?.payload?.exist??null)
            setValidateCurrentBusinessCode((prevState) => ({...prevState, exist}))
            setLoading(false)
        }else{
            setValidateCurrentBusinessCode((prevState) => ({...prevState, exist: null, showResult: false}))
            setLoading(false)
        }
    }, [setValidateCurrentBusinessCode, businessCode, lazyValidateBusinessCodeQuery])

    useEffect(() => {
        validateBusinessCode()
    }, [validateBusinessCode])

    return(
        <>
            {validateCurrentBusinessCode.showResult &&
                <Box display={'flex'} alignItems={'center'} >
                    { loading ?
                        <>
                            <CircularProgress sx={{ width: "20px !important", height: "20px !important" }} />
                        </>
                        :
                        <>
                            {validateCurrentBusinessCode.exist === true &&
                                <>
                                    <Tooltip title="Código valido" arrow >
                                        <CheckCircleOutlineRoundedIcon sx={{ color: (theme) => theme.palette.success.main }} />
                                    </Tooltip>
                                </>
                            }
                            {validateCurrentBusinessCode.exist === false &&
                                <>
                                    <Tooltip title="Código invalido" arrow >
                                        <HighlightOffRoundedIcon sx={{ color: (theme) => theme.palette.error.main }} />
                                    </Tooltip>
                                </>
                            }
                        </>
                    }
                </Box>
            }
        </>
    )
}

export default BusinessCodeValid