import { Box, Divider, ReceiptLongRoundedIcon, Typography, createFilterOptions } from '@component/UIComponents'
import Autocomplete from "@component/Autocomplete"
import { useGetSales } from '@feature/Reports/Sales/hooks/useSales'
import { currencyFormat } from '@util/currencyFormat'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import DialogInvoice from '@feature/Reports/Invoice/components/DialogInvoice'
import { useState } from 'react'

const MySalesToday = ({ onChangeTab }) => {
    const [invoice, setInvoice] = useState(null)
    const { t } = useTranslation()
    const toDay = dayjs().format("MM-DD-YYYY")
    const { results, isFetching, } = useGetSales({ variant: "mySales", startDate: toDay, endDate: toDay })
    const filterOptions = createFilterOptions({
        stringify: (option) => `${t((option?.customer?.name??""))} ${(option?.seller?.name??"")} ${option.total}`,
    });
    const handleKeyDown = (event) => {
        const { code } = event
        if( code === "Tab" ){
            event.preventDefault()
            onChangeTab("saved")
        }
    }
    return(
        <>
            <Box mt={1} >
                <Autocomplete
                    onChange={(_, n) => setInvoice((n?._id??""))}
                    onKeyDown={handleKeyDown}
                    filterOptions={filterOptions}
                    loading={isFetching}
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiPaper-elevation': {
                                    boxShadow: 'none'
                                }
                            }
                        }
                    }}
                    options={results}
                    value={null}
                    open={true}
                    placeholder="Ventas de hoy"
                    renderOption={(props, option) => {
                        return(
                            <li {...props} key={option._id} >
                                <Box width={'100%'} >
                                    <Box display={'flex'} alignItems={'center'} >
                                        <Box width={'100%'} >
                                            <Typography sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.1} fontSize={'.875rem'} fontWeight={'bold'} > Cliente: <Box component={'span'} sx={{ color: "#000" }} > {t((option?.customer?.name??""))} </Box> </Typography>
                                            <Typography fontSize={'.875rem'} fontWeight={'bold'} > #FAC: <Box component={'span'} sx={{ color: "#000" }} > {(option?.consecutive??0)} </Box> </Typography>
                                            <Typography sx={{ color: (theme) => theme.palette.primary.main }} lineHeight={1.1} fontSize={'.875rem'} fontWeight={'bold'} > Total: <Box component={'span'} sx={{ color: "#000" }} > {currencyFormat((option?.total??0))} </Box> </Typography>
                                        </Box>
                                        <Box>
                                            <ReceiptLongRoundedIcon />
                                        </Box>
                                    </Box>
                                    <Divider />
                                </Box>
                            </li>
                        )
                    }}
                />
            </Box>
            <DialogInvoice open={Boolean(invoice)} saleRef={invoice} onClose={() => setInvoice(null)} />
        </>
    )
}

export default MySalesToday