import { Box, Divider, Grid, MonetizationOnRoundedIcon } from '@component/UIComponents'
import { useGetCloseOfDay } from './hooks/useCloseOfDay'
import dayjs from 'dayjs'
import EmptyList from '@component/EmptyList'
import ActionsList from '@component/ActionsList'
import { useClosingDayActions } from './hooks/useClosingDayActions'
import LoadProgress from '@component/LoadProgress'
import ClosingInformation from './components/ClosingInformation'

const ClosingOfTheDay = () => {
    const { primaryActions } = useClosingDayActions()
    const startDate = dayjs().format("MM-DD-YYYY")
    const endDate = dayjs().format("MM-DD-YYYY")
    const { closeOfDay } = useGetCloseOfDay({startDate, endDate})

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        options={primaryActions()}
                        filterButton={{
                            component: () => <></>,
                            show: true,
                        }}
                    />
                    <Divider />
                </Grid>
                { Boolean(closeOfDay) ?
                    <Box width={'100%'} >
                        <ClosingInformation closeOfDay={closeOfDay} />
                    </Box>
                    :
                    <Grid xs={12} >
                        <Box mt={2} >
                            <EmptyList
                                icon={<MonetizationOnRoundedIcon />}
                                text='No se ha generado el cierre del dia de hoy'
                            />
                        </Box>
                    </Grid>
                }
            </Grid>
            <LoadProgress open={false} variant='dialog' text='Estamos generando el cierre del dia...' />
        </>
    )

    // return(
    //     <>
    //         <Grid container >
    //             <Grid xs={8} >
    //                 <ListRendering
    //                     elements={summaries}
    //                     component={(list) => {
    //                         return(
    //                             <SuperDataGrid
    //                                 title={`${t((list?.group??""))} - ${currencyFormat((list?.total??0))}`}
    //                                 loading={isFetching}
    //                                 columns={columns}
    //                                 rows={(list?.results??[])}
    //                             />
    //                         )
    //                     }}
    //                 />
    //             </Grid>
    //             <Grid xs={4} >
    //                 <Summary list={summaryReport} />
    //             </Grid>
    //         </Grid>
    //     </>
    // )
}

export default ClosingOfTheDay