import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, EditIconRounded, ManageSearchRoundedIcon, RemoveRedEyeIconRounded } from '@component/UIComponents'
import { useGetHauliersQuery, useGetMerchandiseTransportedQuery } from '@query/Hauliers'
import { useCallback } from 'react'

export const useGetHauliers = () => {
    const { data, isFetching } = useGetHauliersQuery()
    const hauliers = (data?.payload??[])

    return {
        hauliers,
        isFetching
    }
}

export const useGetMerchandiseTransported = (filters={}, config={}) => {
    const { data, isFetching } = useGetMerchandiseTransportedQuery(filters, config)
    const merchandiseTransported = (data?.payload??null)

    return {
        merchandiseTransported,
        isFetching
    }
}

export const useHauliersMenu = () => {
    const primaryMenu = useCallback((props={}) => {
        const { onTransportedMerchandise=()=>null } = props
        let r = [
            {
                title: "Editar",
                onClick: () => null,
                icon: <EditIconRounded />,
            },
            {
                title: "Mercancía transportada",
                onClick: () => onTransportedMerchandise(),
                icon: <ManageSearchRoundedIcon />,
            },
            {
                title: "Archivar",
                onClick: () => null,
                icon: <ArchiveRoundedIcon />,
            },
        ]
        return r
    }, [])

    const secondaryMenu = useCallback(() => {
        let r = [
            {
                title: "Crear transportista",
                onClick: () => null,
                icon: <AddCircleOutlineIconRounded />,
            },
        ]
        return r
    }, [])

    const thirdMenu = useCallback((props={}) => {
        const { onShowPurchaseInvoice=()=>null } = props
        let r = [
            {
                title: "Ver factura",
                onClick: () => onShowPurchaseInvoice(),
                icon: <RemoveRedEyeIconRounded />,
            },
        ]
        return r
    }, [])

    return {
        primaryMenu,
        secondaryMenu,
        thirdMenu,
    }
}