import { AddCircleOutlineIconRounded, ArchiveRoundedIcon, EditIconRounded } from "@component/UIComponents"
import { preventAction } from "@util/helpers"
import { useCallback } from "react"
import { useArchiveProductPackaging } from "./useArchiveProductPackaging"

export const useMenusPackages = () => {
    const { archiveProductPackaging } = useArchiveProductPackaging()

    const primaryMenu = useCallback((props={}) => {
        const { hideMenus=[], onCreatePackaging=()=>null, onEditPackaging=()=>null, packaging=null } = props
        let r = [
            { name: "createPackage", title: "Crear embalaje", onClick: () => onCreatePackaging(), icon: <AddCircleOutlineIconRounded /> },
            { name: "editPackage", title: "Editar", onClick: () => onEditPackaging(), icon: <EditIconRounded /> },
            { name: "archivePackage", title: "Archivar", onClick: () => preventAction({
                text: `¿Desea archivar el embalaje ${packaging?.name??""}?`,
                onSubmit: () => archiveProductPackaging({ packagingId: (packaging?._id??null) })
            }), icon: <ArchiveRoundedIcon /> },
        ]

        r = r.filter((n) => !hideMenus.some((x) => x === (n?.name??"")) )

        return r.map((item, index) => ({...item, _id: index}))
    }, [archiveProductPackaging])

    return {
        primaryMenu
    }
}