import { Box, DataGrid, Grid, Typography, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import dayjs from 'dayjs'
import { forwardRef } from 'react'
import { useMemo } from 'react'

const DataGridStyled = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        minHeight: "30px",
        maxHeight: "30px",
        lineHeight: "30px",
        border: "none",
    },
    '& .MuiDataGrid-cell--withRenderer': {
        fontSize: '.875rem'
    }
}))

const PurchaseOrderSummary = forwardRef(({ products=[], provider={} }, ref) => {
    const { getDateFormats } = usePanel()
    const columns = useMemo(() => [
        {flex: 1, field: "name", headerName: "Producto", renderCell: ({ row }) => `${(row?.name??"")}` },
        {minWidth: 100, maxWidth: 100, align: "center", field: "quantity", headerName: "Cantidad", renderCell: ({ row }) => `x${(row?.quantity??0)}`},
    ], [])

    const rows = useMemo(() => {
        return products.map((n, index) => ({...n, id: index}))
    }, [products])

    return(
        <>
            <Box ref={ref} position={'absolute'} left={'-9000px'} maxWidth={1200} >
                <Box padding={1} >
                    <Grid container >
                        <Grid xs={12} >
                            <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'1.2rem'} textTransform={'uppercase'} > pedido </Typography>
                            <Typography textAlign={'center'} fontWeight={'bold'} lineHeight={1} fontSize={'1rem'} textTransform={'uppercase'} > {provider?.name??""} </Typography>
                        </Grid>
                        <Grid xs={12} >
                            <DataGridStyled
                                hideFooter
                                autoHeight
                                columns={columns}
                                rows={rows}
                                rowHeight={30}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <Typography textAlign={'right'} fontSize={'.875rem'} > Impreso el {getDateFormats( dayjs() ).fullDate} a las {getDateFormats( dayjs() ).hhmmss} </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
})

export default PurchaseOrderSummary