import { Box, Card, CloseRoundedIcon, IconButton, Stack } from '@component/UIComponents'

const PanelContainer = ({ onClose=undefined, children }) => {
    return(
        <Card variant="outlined" sx={{ borderRadius: '0px', padding: 1 }} >
            <Stack width={'100%'} direction={'row'} justifyContent={'end'} >
                <Box>
                    <IconButton size="small" onClick={onClose} >
                        <CloseRoundedIcon />
                    </IconButton>
                </Box>
            </Stack>
            {children}
        </Card>
    )
}

export default PanelContainer