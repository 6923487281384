import { useURLParams } from "@hook/useURLParams"
import dayjs from "dayjs"
import { useCallback, useMemo, useState } from "react"

export const usePurchaseController = () => {
    const { getAllQueries } = useURLParams()
    const [purchaseReportFilters, setPurchaseReportFilters] = useState({
        invoiceNumber: "",
        paymentTypes: "credit",
        omitDateRange: "omitDateRange",
        purchaseStatus: "pendingPayable",
        startDate: dayjs(),
        endDate: dayjs(),
        groupBy: "",
        viewArchived: "",
        useDueDate: "",
        see: "",
    })

    const queriesActive = useMemo(() => getAllQueries(), [getAllQueries])

    const getQueryFilter = useMemo(() => {
        let { startDate=dayjs(), endDate=dayjs(), invoiceNumber="", omitDateRange="omitDateRange", useDueDate="", purchaseStatus="pendingPayable", viewArchived="", paymentTypes="credit" } = (queriesActive??{})
        return {
            startDate: dayjs(startDate).format("MM-DD-YYYY"),
            endDate: dayjs(endDate).format("MM-DD-YYYY"),
            ...(Boolean(invoiceNumber) ? { invoiceNumber } : {}),
            ...((omitDateRange === "omitDateRange") ? { omitDateRange: true } : (useDueDate === "useDueDate") ? { useDueDate: true } : {} ),
            ...(Boolean(purchaseStatus) ? { purchaseStatus } : {}),
            ...(Boolean(paymentTypes) ? { paymentTypes } : {}),
            ...((viewArchived === true || viewArchived === "true") ? { viewArchived } : {}),
            variant: "purchases",
        }
    }, [queriesActive])

    const getConfigurationFilters = useMemo(() => {
        const { groupBy="", see="" } = (queriesActive??{})
        return {
            groupBy,
            see
        }
    }, [queriesActive])

    const handleSetPurchaseReportFilters = useCallback((field, value) => setPurchaseReportFilters((prevState) => ({...prevState, [field]: value})), [])

    return {
        getConfigurationFilters,
        getQueryFilter,
        purchaseReportFilters,
        setPurchaseReportFilters: handleSetPurchaseReportFilters
    }
}