import { ArrowBackIosRoundedIcon, Box, IconButton, Tooltip } from "@component/UIComponents"
import { useNavigate } from "react-router-dom"
import { routes } from "@util/routes"
const { home } = routes

const OutPOS = () => {
    const navigate = useNavigate()
    return(
        <>
            <Box>
                <Tooltip title="Volver al panel" arrow >
                    <IconButton onClick={() => navigate(home())} sx={{ color: (theme) => "#FFFFFF" }} >
                        <ArrowBackIosRoundedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    )
}

export default OutPOS