import { useRegisterCreditNoteMutation } from "@query/Sales"
import { asynchronousResponseHandler } from "@util/helpers"

export const useRegisterCreditNote = () => {
    const [ registerCreditNoteMutation, { isLoading } ] = useRegisterCreditNoteMutation()

    const registerCreditNote = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await registerCreditNoteMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "Nota crédito registrada satisfactoriamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerCreditNote,
        isLoadingRegisterCreditNote: isLoading
    }
}