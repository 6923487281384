import { Link, Typography } from "@component/UIComponents"

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://facill.net" target='_blank' >
                facill.net
            </Link>{' '}
            {' - '}
            <Link color="inherit" href="https://uniko.com.co" target='_blank' >
                uniko.com.co
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export default Copyright