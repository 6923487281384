import Form from '@component/Form'
import NumericFormat from '@component/NumericFormat'
import PatternFormat from '@component/PatternFormat'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@component/UIComponents'
import CitiesFinder from '@feature/Business/components/CitiesFinder'
import TaxesFinder from '@feature/Business/components/TaxesFinder'
import { useProviderFormData, useRegisterProvider } from './hooks/useProviderForm'
import { useEffect } from 'react'
import { Text } from '@component/Title'

const ProviderForm = ({ open=false, onClose=()=>null, defaultValues=undefined }) => {
    const { values, setFieldValue, validateValues, resetValues } = useProviderFormData({ defaultValues })
    const { address, bank, bankAccountNumber, city, code, name, nit, phone, representative, tax, typeOfBankAccount, bankAgreementNumber } = values
    const { isLoading, registerProvider } = useRegisterProvider()

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        registerProvider(values, {
            clearForm: () => resetValues(),
            closeForm: () => onClose()
        })
    }

    useEffect(() => {
        if( !Boolean(open) ){
            resetValues()
        }
    }, [open, resetValues])

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} transitionDuration={0} fullWidth open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <Text variant='form-title' >
                        { Boolean(defaultValues?._id) ?
                            `Editar proveedor ${(defaultValues?.name??"")}`
                        :
                            "Crear proveedor"
                        }
                    </Text>
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                <TextField value={(name??"")} fullWidth label="Proveedor" onChange={({ target }) => setFieldValue("name", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(representative??"")} fullWidth label="Representante" onChange={({ target }) => setFieldValue("representative", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(nit??"")} fullWidth label="NIT" onChange={({ target }) => setFieldValue("nit", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(code??"")} fullWidth label="Código de proveedor" onChange={({ target }) => setFieldValue("code", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <CitiesFinder value={(city??null)} fullWidth onChange={(_, param) => setFieldValue("city", (param??null))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(address??"")} fullWidth label="Dirección" onChange={({ target }) => setFieldValue("address", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <PatternFormat value={(phone??"")} fullWidth label="Teléfono" format='+57 ### ### ####' onChange={(params) => setFieldValue("phone", (params??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <TaxesFinder value={(tax??null)} fullWidth filters={{ name: "retefuente" }} onChange={(_, param) => setFieldValue("tax", (param??null))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(bank??"")} fullWidth label="Banco" onChange={({ target }) => setFieldValue("bank", (target?.value??null))} />
                            </Grid>
                            <Grid xs={6} >
                                <TextField value={(typeOfBankAccount??"")} fullWidth label="Tipo de cuenta" onChange={({ target }) => setFieldValue("typeOfBankAccount", (target?.value??null))} />
                            </Grid>
                            <Grid xs={6} >
                                <NumericFormat value={(bankAccountNumber??"")} suffix="" prefix="" thousandSeparator="" fullWidth label="Numero de cuenta" onChange={({ floatValue }) => setFieldValue("bankAccountNumber", (floatValue??0))} />
                            </Grid>
                            <Grid xs={6} >
                                <NumericFormat value={(bankAgreementNumber??"")} suffix="" prefix="" thousandSeparator="" fullWidth label="Numero de convenio" onChange={({ floatValue }) => setFieldValue("bankAgreementNumber", (floatValue??0))} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} > Guardar </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default ProviderForm