let regexList = {
    blanks: /\s/g,
}

export let regex = ({ action="", string="", validation="", flag=undefined }) => {
    let r = ""
    if( Boolean(action) ){
        r = string[action](regexList[validation], flag)
    }
    return r
}