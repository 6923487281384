import { Box, Divider, Stack, styled } from '@component/UIComponents'
import { useValidatePermission } from '@hook/useValidatePermission'
import { currencyFormat } from '@util/currencyFormat'

const StackStyled = styled(Stack)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1)
}))

const BoxTotalContainer = styled(Box)(() => ({
    WebkitTextStroke: '.8px',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    lineHeight: 1.5,
}))

const BoxMarginContainer = styled(Box)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: 1,
    color: theme.palette.grey[600]
}))

const TotalSales = ({ total=0, totalProfitCurrency=0, totalProfitPercentage=0 }) => {
    const { havePermission } = useValidatePermission()
    return(
        <>
            <StackStyled>
                <BoxTotalContainer>
                    { currencyFormat(total) }
                </BoxTotalContainer>
                <Box>
                    <Stack direction='row' gap={1} divider={<Divider orientation='vertical' flexItem />} >
                        {havePermission("show_profit_information") &&
                            <>
                                <BoxMarginContainer>
                                    {currencyFormat(totalProfitCurrency)}
                                </BoxMarginContainer>
                                <BoxMarginContainer>
                                    {totalProfitPercentage} %
                                </BoxMarginContainer>
                            </>
                        }
                    </Stack>
                </Box>
            </StackStyled>
        </>
    )
}

export default TotalSales