import { useRemoveCreditFromTheSaleMutation } from "@query/Sales"
import { asynchronousResponseHandler } from "@util/helpers"

export const useRemoveCreditFromTheSale = () => {
    const [removeCreditFromTheSaleMutation, { isLoading }] = useRemoveCreditFromTheSaleMutation()

    const removeCreditFromTheSale = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await removeCreditFromTheSaleMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El abono se removió exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        removeCreditFromTheSale,
        isLoadingRemoveCreditFromTheSale: isLoading,
    }
}