import { Toast } from "@util/swal"
import { useState, useCallback, useMemo, useEffect } from "react"

export const useDropzone = (props={}) => {
    const { fileInputRef, imageLimit, onChange=()=>null, defaultImages=[], multiple=true } = props
    const [selectedFiles, setSelectedFiles] = useState([])

    const processFiles = useCallback((files) => {
        let newArrayFiles = []
        const megabytesToKilobyte = 5242880

        let currentSelectedFiles = [...selectedFiles]
        for( let file of files ){
            const fileExist = currentSelectedFiles.some((n) => (n?.name??"") === (file?.name??""))
            if( !fileExist ){
                if( multiple ){
                    newArrayFiles.push(file)
                }else{
                    newArrayFiles = [file]
                }
            }
        }

        if( multiple ){
            currentSelectedFiles = [...currentSelectedFiles, ...newArrayFiles]
        }else{
            currentSelectedFiles = newArrayFiles
        }

        if( Boolean(fileInputRef?.current) ){
            fileInputRef.current.value = ""
        }
        const fileCollectionSize = currentSelectedFiles.reduce((acc, value) => (acc+(value?.size??0)), 0)
        if( currentSelectedFiles.length > imageLimit ){
            Toast.fire({
                icon: "warning",
                text: `Has alcanzado el limite de imágenes (${imageLimit})`
            })
        }else if(fileCollectionSize > megabytesToKilobyte){
            Toast.fire({
                icon: "warning",
                text: `La colección de imágenes supera los (5mb)`
            })
        }else{
            setSelectedFiles(currentSelectedFiles)
            if(Boolean(onChange)) onChange(currentSelectedFiles)
        }
    }, [selectedFiles, fileInputRef, imageLimit, onChange, multiple])

    const removeImage = useCallback((index) => {
        let currentSelectedFiles = [...selectedFiles]
        currentSelectedFiles.splice(index, 1)
        setSelectedFiles(currentSelectedFiles)
        if(Boolean(onChange)) onChange(currentSelectedFiles)
    }, [selectedFiles, onChange])

    const previewImages = useMemo(() => {
        let currentSelectedFiles = [...selectedFiles]
        currentSelectedFiles = currentSelectedFiles.map((n) => URL.createObjectURL(n))
        return currentSelectedFiles
    }, [selectedFiles])

    const loadDefaultImages = useCallback(async () => {
        if( defaultImages.length >= 1 ){
            setSelectedFiles(defaultImages)
        }
    }, [defaultImages])

    useEffect(() => {
        loadDefaultImages()
    }, [loadDefaultImages])

    return {
        processFiles,
        selectedFiles,
        previewImages,
        removeImage,
    }
}