import ActionsList from "@component/ActionsList"
import { Box, Grid, useTheme } from "@component/UIComponents"
import Filters from "../Sales/components/Filters"
import { useGetSaleReportFilters, useGetSales } from "../Sales/hooks/useSales"
import { useStatistics } from "./hooks/useStatistics"
import BarChart from "@component/BarChart"
import { useMemo } from "react"
import { useSalesOptions } from "../Sales/hooks/useSalesOptions"

const Statistics = () => {
    const { groupSalesBy } = useSalesOptions()
    const filters = useGetSaleReportFilters()
    const { results } = useGetSales({ ...filters, variant: "salesReport" })
    const { salesGroupedByHours, hoursOfTheDay } = useStatistics({ sales: results })
    const theme = useTheme()

    const data = useMemo(() => {
        let d = hoursOfTheDay().map((n) => {
            let t = (salesGroupedByHours.find((b) => b.hour === n)?.total??0)
            return t
        })
        return {
            labels: hoursOfTheDay(),
            datasets: [
                {
                    label: 'Venta',
                    data: [...d],
                    backgroundColor: theme.palette.primary.light,
                },
            ],
        }
    }, [hoursOfTheDay, salesGroupedByHours, theme])

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <ActionsList
                        filterButton={{
                            show: true,
                            component: () => <Filters groupByList={groupSalesBy()} />
                        }}
                    />
                </Grid>
                <Grid xs={12} sx={{ display:'flex', justifyContent: 'center' }} >
                    <Box width={'70vw'} >
                        <BarChart data={data} />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Statistics