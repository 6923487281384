import { Grid } from "@component/UIComponents"
import Summary from "./Summary"

const ClosingInformation = ({ closeOfDay={} }) => {
    return(
        <>
            <Grid container sx={{ mt: 1 }} >
                <Grid xs={8} >

                </Grid>
                <Grid xs={4} >
                    <Summary closeOfDay={closeOfDay} />
                </Grid>
            </Grid>
        </>
    )
}

export default ClosingInformation