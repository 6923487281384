import { createCookie, getCookie } from "@util/helpers"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useLoginController = () => {
    const [preloadWindows, setPreloadWindows] = useState({ login: "preload" })
    const [showPassword, setShowPassword] = useState("password")
    const handleShowPassword = () => setShowPassword(prevState => (prevState === "password") ? "text" : "password")
    const cookieNameWithQuickAccessAccount = useMemo(() => "quickAccessAccount", [])
    const renderOnLogin = useMemo(() => (preloadWindows?.login??""), [preloadWindows])

    const handleSetPreloadWindows = useCallback((field, value) => setPreloadWindows((prevState) => ({...prevState, [field]: value })), [])

    const storeQuickAccessLogin = useCallback((user) => {
        const expireAccount = dayjs().add(1, "year")
        const payload = {
            name: `${(user?.name??"")} ${(user?.lastName??"")}`,
            email: `${(user?.email??"")}`,
        }
        createCookie({ name: cookieNameWithQuickAccessAccount, value: payload, convertJSONstringify: true, expirationDate: expireAccount })
    }, [cookieNameWithQuickAccessAccount])

    const getQuickAccessAccount = useCallback(() => {
        return getCookie(cookieNameWithQuickAccessAccount, { convertJSONstringify: true })
    }, [cookieNameWithQuickAccessAccount])

    const deleteQuickAccessAccount = useCallback(() => {
        createCookie({ name: cookieNameWithQuickAccessAccount, value: "", expirationDate: dayjs().subtract(1, "day") })
        window.location.reload(true)
    }, [cookieNameWithQuickAccessAccount])

    useEffect(() => {
        if( Boolean(getQuickAccessAccount()?.email??"") ){
            handleSetPreloadWindows("login", "quickLogin")
        }else{
            handleSetPreloadWindows("login", "loginForm")
        }
    }, [getQuickAccessAccount, handleSetPreloadWindows])

    return {
        renderOnLogin,
        preloadWindows,
        getQuickAccessAccount,
        showPassword,
        handleShowPassword, 
        storeQuickAccessLogin,
        setPreloadWindows,
        handleSetPreloadWindows,
        deleteQuickAccessAccount
    }
}