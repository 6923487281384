import { useFormik, yup } from '@hook/useFormik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoverAdministratorUserPasswordMutation } from '@query/Authentication'
import { Toast } from '@util/swal'
import { useNavigate } from 'react-router-dom'
import { routes } from '@util/routes'
const { login } = routes

export const useRecoverPassword = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [recoverAdministratorUserPasswordMutation, {isLoading:isLoadingRecoverAdministratorUserPasswordMutation}] = useRecoverAdministratorUserPasswordMutation()

    const recoverAdministratorUserPassword = async (payload) => {
        try {
            const resp = await recoverAdministratorUserPasswordMutation(payload).unwrap()
            if( resp.status === 1 ){
                Toast.fire({
                    icon: 'success',
                    text: `La contraseña se actualizo satisfactoriamente.`
                })
                navigate(login())
            }else{
                Toast.fire({
                    icon: 'error',
                    text: `ERROR: ${t((resp.payload??""))}`
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recoverAdministratorUserPassword,
        isLoadingRecoverAdministratorUserPasswordMutation
    }
}

/**
 * @typedef {Object} Props
 * @property {object} defaultValues
 * @property {undefined} onSubmit
 */

/**
 * @param {Props} params
 */

export const useRecoverPasswordFormValues = (params={}) => {
    const { t } = useTranslation()
    const { defaultValues={}, onSubmit } = params
    const initialValues = React.useMemo(() => {
        let val =  {
            token: "",
            password: "",
        }
        if( Boolean(defaultValues?._id) ){
            val = {...defaultValues}
        }
        return val
    },[defaultValues])
    const { errors, touched, setFieldValue, handleSubmit, values, resetForm } = useFormik({
        onSubmit,
        initialValues,
        schema: {
            token: yup.string(),
            password: yup.string().required(t('passwordRequired')),
        }
    })

    return { errors, touched, setFieldValue, handleSubmit, values, resetForm }
}