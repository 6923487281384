import Autocomplete from "@component/Autocomplete"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"
import { useGetRoles } from '../hooks/useRole'

const FinderRoles = ({ disableCloseOnSelect=false, multiple=undefined, label="Roles", size=undefined, onChange=undefined, value=null, queryValue="" }) => {
    const { isFetching, roles } = useGetRoles()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: roles, queryValue })
    const val = getAutocompleteValue()
    return(
        <>
            <Autocomplete
                disableCloseOnSelect={disableCloseOnSelect}
                loading={isFetching}
                multiple={multiple}
                size={size}
                label={label}
                options={roles}
                onChange={onChange}
                value={val}
            />
        </>
    )
}

export default FinderRoles