import { AppBar, Button, ButtonGroup, styled } from '@component/UIComponents'

const ButtonStyled = styled(Button)(({ theme }) => ({
    width: "100%",
    height: '7vh',
    borderRadius: 0,
}))

const FooterButtonGroup = ({ options=[] }) => {
    return (
        <AppBar position="static" color="transparent" elevation={0} >
            <ButtonGroup>
                {options?.map((item, index) => {
                    return(
                        <ButtonStyled variant='outlined' key={index} sx={{...(item?.sx??{})}} startIcon={(item?.icon??undefined)} onClick={() => item.onClick()} {...(item?.buttonProps??{})} >
                            {(item?.title??"")}
                        </ButtonStyled>
                    )
                })}
            </ButtonGroup>
        </AppBar>
    )
}

export default FooterButtonGroup