import { RemoveRedEyeIconRounded } from "@component/UIComponents"
import { useCallback } from "react"

export const useProductsPurchaseHistoryMenu = () => {
    const primaryMenu = useCallback((props={}, config={}) => {
        const { onViewInvoice=()=>null } = config
        return [
            {
                onClick: () => onViewInvoice((props?.purchaseId??null)),
                icon: <RemoveRedEyeIconRounded />,
            }
        ]
    }, [])

    return {
        primaryMenu,
    }
}