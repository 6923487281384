import SuperDataGrid from '@component/SuperDataGrid'
import { Dialog, DialogContent } from '@component/UIComponents'
import { useGetDeliveriesRealized } from '../hooks/useDeliveries'
import { currencyFormat } from '@util/currencyFormat'
import { useTranslation } from 'react-i18next'
import { usePanel } from '@hook/usePanel'

const HistoryOfDeliveriesRealized = ({ open=false, onClose=()=>null, refId="" }) => {
    const { deliveriesRealized, isFetching } = useGetDeliveriesRealized({ ref: refId }, { skip: !Boolean(refId) })
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const columns = [
        {
            maxWidth: 150,
            minWidth: 150,
            field: "seller",
            headerName: "Vendedor",
            renderCell: ({row}) => `${(row?.seller?.name??"")} ${(row?.seller?.lastName??"")}`

        },
        {
            flex: 1,
            minWidth: 200,
            field: "customer",
            headerName: "Cliente",
            renderCell: ({row}) => `${t((row?.customer?.name??"some"))}`
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "consecutive",
            headerName: "#FAC",
            renderCell: ({row}) => `${(row?.consecutive??"")}`
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "total",
            headerName: "Total",
            renderCell: ({row}) => `${currencyFormat((row?.total??0))}`,

        },
        {
            maxWidth: 140,
            minWidth: 140,
            field: "deliveryPaymentMethod",
            headerName: "Tipo de pago",
            renderCell: ({row}) => `${t((row?.deliveryPaymentMethod??""))}`,
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "deliveryAmount",
            headerName: "Valor domicilio",
            renderCell: ({row}) => `${currencyFormat((row?.deliveryAmount??0))}`,
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "createdAt",
            headerName: "Fecha de registro",
            renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`,
        },
    ]
    return(
        <>
            <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <DialogContent>
                    <SuperDataGrid
                        title={`Domicilios realizados por ${(deliveriesRealized?.name??"")}`}
                        loading={isFetching}
                        columns={columns}
                        rows={(deliveriesRealized?.sales??[])}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default HistoryOfDeliveriesRealized