import Form from '@component/Form'
import SelectionOfPaymentMethod from '@component/SelectionOfPaymentMethod'
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Typography } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import { Fragment, memo, useEffect, useMemo } from 'react'
import { usePayPurchases, usePaymentPurchasesFormData } from '../hooks/usePurchase'
import { elementFocus } from '@util/helpers'

const PurchasePaymentWindow = ({ list=[], open, onClose }) => {
    const currentList = useMemo(() => list, [list])
    let allInvoices = useMemo(() => currentList.reduce((acc, value) => ([...acc, ...(value.results??[])]),[]), [currentList])
    const invoicesToPayment = useMemo(() => {
        return allInvoices.map((n) => ({
            providerId: (n?.provider?._id??""),
            invoiceId: (n?._id??""),
            payments: []
        }))
    }, [allInvoices])
    const totalAllInvoices = useMemo(() => allInvoices.reduce((acc, value) => (acc+(value?.balance??0)),0), [allInvoices])
    const { payPurchases, isLoading } = usePayPurchases()
    const { values, handlePaymentToInvoice, validateValues, resetValues } = usePaymentPurchasesFormData({ defaultValues: invoicesToPayment })
    const totalPayments = useMemo(() => values.reduce((acc, param) => [...acc, ...(param?.payments??[])], []).reduce((acc, val) => acc+(val?.amount??0),0), [values])

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if( type === "errors" ) return
        payPurchases({ payments: values }, {
            clearForm: () => resetValues(),
            closeForm: () => onClose(),
        })
    }

    useEffect(() => {
        if( open === true ){
            setTimeout(() => {
                elementFocus("_cashToPay", null, { by: "className", elemIndex: 0 })
            }, 150)
        }
    }, [open])

    return(
        <>
            <Dialog PaperProps={{ elevation: 0 }} fullWidth open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        { currentList?.map((item, index) => {
                            const results = (item?.results??[])
                            return(
                                <Fragment key={index} >
                                    <Typography lineHeight={1} fontWeight={'bold'} fontSize={'.875rem'} > {(item?.groupName??"")} </Typography>
                                    <Divider />
                                    { results?.map((invoice, invoiceIndex) => {
                                        const paymentIndex = (values?.findIndex((n) => (n?.invoiceId??"") === (invoice?._id??"")))
                                        return(
                                            <SelectionOfPaymentMethod
                                                index={paymentIndex}
                                                blockFCommand
                                                autoFocable={false}
                                                onChange={(_, n, newPayment) => handlePaymentToInvoice({ invoiceIndex: paymentIndex, payments: newPayment })}
                                                onDeletePayment={(_, n, newPayment) => handlePaymentToInvoice({ invoiceIndex: paymentIndex, payments: newPayment })}
                                                payments={(values[paymentIndex]?.payments??[])}
                                                key={invoiceIndex}
                                                title={`#C${(invoice?.consecutive??0)} - #FAC${(invoice?.invoiceNumber??0)}`}
                                                hideTabs
                                                useDialog={false}
                                                totalToPay={(invoice?.balance??0)}
                                                paymentReference={(invoice?.provider?._id??null)}
                                                paymentMethodsAvailable={{
                                                    credit: false
                                                }}
                                            />
                                        )
                                    }) }
                                    <Typography textAlign={'end'} mb={1} fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Total a pagar a <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} >{(item?.groupName??"")}</Box>: <br /> {currencyFormat((results?.reduce((acc, value) => (acc+value?.balance??0),0)))} </Typography>
                                </Fragment>
                            )
                        }) }
                        <Typography mb={1} fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >
                            Total a pagar a proveedores: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > <br /> {currencyFormat(totalAllInvoices)} </Box>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} disabled={(totalPayments <= 0)} > Pagar - {currencyFormat(totalPayments)} </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default memo(PurchasePaymentWindow)