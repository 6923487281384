import { Box, Card, Divider, Stack, TrendingDownRoundedIcon, TrendingUpRoundedIcon, Typography, styled } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'

const BoxStyled = styled(Box)(({theme}) => ({
    padding: theme.spacing(2),
}))

const TypographyStyled = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "balance" && prop !== "primaryBalance" && prop !== "secondaryBalance",
})(({ balance, theme, primaryBalance, secondaryBalance }) => ({
    ...(balance ? {
        fontSize: "1.5rem",
        fontWeight: "bold",
        position: "relative",
        lineHeight: 1,
        '& span' : {
            top: 4,
            lineHeight: 0,
            fontSize: '1rem',
            fontWeight: '500',
            position: "absolute",
            width: 50,
            marginLeft: 5,
            color: (primaryBalance > secondaryBalance) ? theme.palette.error.main : theme.palette.success.main
        }
    } : {
        fontSize: ".875rem",
        fontWeight: "500",
    })
}))
const CashBalance = ({ primaryBalance=0, secondaryBalance=0, primaryDate="", secondaryDate="" }) => {
    const { getDateFormats } = usePanel()
    return(
        <>
            <Card sx={{ borderRadius: 0 }} elevation={0} variant="outlined" >
                <Stack justifyContent={'center'} direction="row" divider={<Divider orientation="vertical" flexItem />} >
                    <BoxStyled>
                        <TypographyStyled balance > { currencyFormat(primaryBalance) } </TypographyStyled>
                        <TypographyStyled > Cierre de caja del { getDateFormats(primaryDate).fullDate } </TypographyStyled>
                    </BoxStyled>
                    <BoxStyled >
                        <TypographyStyled balance primaryBalance={primaryBalance} secondaryBalance={secondaryBalance} >
                            { currencyFormat(secondaryBalance) }
                            <span>
                                {(primaryBalance > secondaryBalance) ? "-" : "+" }
                                { (primaryBalance > secondaryBalance) ?
                                    (((primaryBalance-secondaryBalance)/primaryBalance)*100).toFixed(2)
                                    :
                                    (((secondaryBalance-primaryBalance)/secondaryBalance)*100).toFixed(2)
                                }%
                                {(primaryBalance > secondaryBalance) ? <TrendingDownRoundedIcon /> : <TrendingUpRoundedIcon /> }
                            </span>
                        </TypographyStyled>
                        <TypographyStyled >
                            { getDateFormats(secondaryDate).isToday ? "Caja actual" : "Cierre de caja del" }
                            { getDateFormats(secondaryDate).fullDate }
                        </TypographyStyled>
                    </BoxStyled>
                </Stack>
            </Card>
        </>
    )
}

export default CashBalance