import { useArchiveProductPackagingMutation } from "@query/Products"
import { asynchronousResponseHandler } from "@util/helpers"

export const useArchiveProductPackaging = () => {
    const [ archiveProductPackagingMutation, { isLoading } ] = useArchiveProductPackagingMutation()

    const archiveProductPackaging = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await archiveProductPackagingMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((resp?.payload?.isArchived??false)) ? "El embalaje se archivo exitosamente." : "El embalaje se recupero exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archiveProductPackaging,
        isLoadingArchiveProductPackaging: isLoading,
    }
}