import { Box, Typography } from '@component/UIComponents'
import i18n from '@util/translate'

const AuthFormHeaderText = ({ title=i18n.t('appName'), subTitle="" }) => {
    return(
        <Box mb={2} >
            <Typography mb={1} fontWeight={'bold'} fontSize={'4.5vh'} lineHeight={1} sx={{ WebkitTextStrokeWidth: 1 }} >
                {title}
            </Typography>
            <Typography fontWeight={'500'} fontSize={'2vh'} lineHeight={1} >
                {subTitle}
            </Typography>
        </Box>
    )
}

export default AuthFormHeaderText