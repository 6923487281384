import { api, mutationConfig } from "../splitApi";
import { BoxTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        // registerAdvanceToSupplier: build.mutation({
        //     query(body) {
        //         return { ...mutationConfig, url: "/register/advance/to/supplier", body };
        //     },
        //     invalidatesTags: ProviderTag
        // }),
        getUserSessionInformation: build.query({
            query: (params=undefined) => ({
                url: `/get/user/session/information`,
                params,
            }),
            providesTags: BoxTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetUserSessionInformationQuery,
} = Endpoints;