import Form from "@component/Form"
import TextField from "@component/TextField"
import { Button, Grid, Stack } from "@component/UIComponents"
import { routes } from "@util/routes"
import { useNavigate } from "react-router-dom"
import { useAuthenticateUser, useLoginFormValues } from "../hooks/useLogin"
import { getErrorsParams } from "@hook/useFormik"
import { useTranslation } from "react-i18next"
import SessionOptions from "./SessionOptions"
import { useLoginController } from "../hooks/useLoginController"

const LoginForm = () => {
    const { handleShowPassword, showPassword } = useLoginController()
    const { isLoadingAuthenticateUserMutation, authenticateUser } = useAuthenticateUser()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { singIn } = routes
    const { values, errors, touched, handleSubmit, setFieldValue } = useLoginFormValues({ onSubmit: authenticateUser })
    const { email, password } = values
    const error = (name) => getErrorsParams({ errors, field: name, touched })

    return(
        <>
            <Form onSubmit={() => handleSubmit()} >
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <TextField
                            value={email}
                            label={t('email')}
                            fullWidth
                            type="email"
                            InputFontWeight={500}
                            {...error("email")}
                            onChange={({target}) => setFieldValue("email", (target?.value??""))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            showPassword
                            value={password}
                            label={t('password')}
                            fullWidth
                            type={showPassword}
                            InputFontWeight={500}
                            {...error("password")}
                            onChange={({target}) => setFieldValue("password", (target?.value??""))}
                            onViewPassword={() => handleShowPassword()}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <Stack direction={'row'} justifyContent={'end'} >
                            <SessionOptions />
                        </Stack>
                    </Grid>
                    <Grid xs={12} sx={{ mt: 3 }} >
                        <Stack justifyContent={'space-between'} direction={'row'} >
                            <Button onClick={() => navigate(singIn())} loading={isLoadingAuthenticateUserMutation} >
                                {t('createAccount')}
                            </Button>
                            <Button variant="contained" disableElevation type="submit" loading={isLoadingAuthenticateUserMutation} >
                                {t('getInto')}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
}

export default LoginForm