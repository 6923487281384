import { Button, CircularProgress, DatePicker, Dialog, DialogActions, DialogContent, FormHelperText, Grid } from '@component/UIComponents'
import { usePanel } from '@hook/usePanel'
import { getDetailedDueDate } from '@util/date'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useLazyGetPurchase } from '../hooks/useLazyGetPurchase'
import Form from '@component/Form'
import { useUpdatePurchaseExpirationDate } from '@feature/EnterPurchase/hooks/useUpdatePurchaseExpirationDate'

const UpdateDueDate = ({ purchaseId="", open=false, onClose=()=>null }) => {
    const [ isLoading, setIsLoading ] = useState(false)
    const { lazyGetPurchaseQuery } = useLazyGetPurchase()
    const { getDateFormats } = usePanel()
    const [dueDate, setDueDate] = useState(dayjs().add(1, "day").format("MM-DD-YYYY"))
    const { updatePurchaseExpirationDate } = useUpdatePurchaseExpirationDate()

    const loadDefaultDueDate = useCallback( async () => {
        setIsLoading(true)
        let purchase = ((await lazyGetPurchaseQuery({ ref: purchaseId }))?.data?.payload??[])
        purchase = ((purchase?.length??0) === 1) ? purchase[0] : null
        if( Boolean(purchase?._id) ){
            setDueDate((purchase?.dueDate??""))
        }
        setIsLoading(false)
    }, [purchaseId, lazyGetPurchaseQuery])

    useEffect(() => {
        (async () => {
            await loadDefaultDueDate()
        })()
    }, [loadDefaultDueDate])

    useEffect(() => {
        if( !open ){
            setDueDate(dayjs().add(1, "day").format("MM-DD-YYYY"))
        }
    }, [open])

    return(
        <>
            <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <Form onSubmit={() => updatePurchaseExpirationDate({ purchaseId, dueDate: dayjs(dueDate).format("MM-DD-YYYY") }, { clearForm: () => onClose() })} >
                    <DialogContent>
                        <Grid container >
                            { isLoading === true ?
                                <Grid xs={12} sx={{ display: 'flex', justifyContent: "center" }} >
                                    <CircularProgress />
                                </Grid>
                                :
                                <>
                                    <Grid xs={12} >
                                        <DatePicker
                                            format='DD/MM/YYYY'
                                            value={dayjs(dueDate)}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true
                                                }
                                            }}
                                            onChange={(date) => setDueDate( dayjs(date).format("MM-DD-YYYY") )}
                                        />
                                    </Grid>
                                    <Grid xs={12} >
                                        <FormHelperText>
                                            {getDetailedDueDate(dueDate)} - { getDateFormats(dueDate).fullDate }
                                        </FormHelperText>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' >
                            Actualizar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default UpdateDueDate