import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useGetBusinessInformationQuery, useUpdateBusinessInformationMutation } from "@query/Business"
import { asynchronousResponseHandler } from "@util/helpers"
import { useMemo } from "react"

export const useGetBusinessInformation = () => {
    const { data, isLoading, isSuccess } = useGetBusinessInformationQuery()
    const business = (data?.payload??null)
    return {
        business,
        isLoading,
        isSuccess,
    }
}

export const useBusinessFormData = (props={}) => {
    const { defaultValues=undefined } = props
    const initialValues = useMemo(() => ({ address: "", email:"", name: "", nit: "", phone: "", }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del negocio es un campo requerido."),
            email: yup.string().email("El correo electrónico no tiene un formato valido."),
        }
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useUpdateBusinessInformation = () => {
    const [updateBusinessInformationMutation, { isLoading }] = useUpdateBusinessInformationMutation()

    const updateBusinessInformation = async (payload, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await updateBusinessInformationMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "La información del negocio se actualizo exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updateBusinessInformation,
        isLoading
    }
}