import Autocomplete from "@component/Autocomplete"
import { useGetCategories } from "../hooks/useCategories"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const CategoriesFinder = ({ label="Categorías", multiple=undefined, fullWidth=undefined, size=undefined, onChange=undefined, value=null, sx=undefined, inputRef=undefined, queryValue=undefined }) => {
    const { categories, isFetching } = useGetCategories()
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: categories, queryValue })
    let val = getAutocompleteValue()
    return(
        <>
            <Autocomplete
                multiple={multiple}
                ref={inputRef}
                sx={sx}
                onChange={onChange}
                value={val}
                fullWidth={fullWidth}
                size={size}
                label={label}
                options={categories}
                loading={isFetching}
            />
        </>
    )
}

export default CategoriesFinder