import { useCallback } from "react"
import { useTranslation } from "react-i18next"

export const useSalesOptions = () => {
    const { t } = useTranslation()

    const invoiceTypeList = useCallback((props={}) => {
        const { hideOptions=[] } = props
        let r = [{ name: "Venta", value: "sale" }, { name: "Cotización", value: "price" }, { name: "Archivados", value: "archived" }]
        r = r.map((b, index) => ({...b, _id: index}))
        if( hideOptions.length >= 1 ){
            r = r.filter((n) => !hideOptions.some((b) => b === (n?.value??"")) )
        }
        return r
    }, [])


    const groupSalesBy = useCallback(() => {
        let r = [
            { name: t('city'), value: "city" },
            { name: t('Sucursal'), value: "branch" },
            { name: t('Zona'), value: "zone" },
            { name: t('Barrio'), value: "neighborhood" },
            { name: t('Tipo de negocio'), value: "businessType" },
        ].map((n, index) => ({...n, _id: (index+1)}))
        return r
    }, [t])

    const groupTracking = useCallback(() => {
        let r = [
            { name: "Por días", value: "days" },
            { name: "Por meses", value: "months" },
            { name: "Por años", value: "years" },
        ].map((n, index) => ({...n, _id: index}))

        return r
    }, [])
    
    return {
        groupTracking,
        invoiceTypeList,
        groupSalesBy
    }
}