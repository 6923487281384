import { useGetCustomers } from "../hooks/useCustomers"
import Autocomplete from "@component/Autocomplete"
import { useAutocompleteManipulateValue } from "@hook/useAutocompleteManipulateValue"

const CustomerFinder = ({ size=undefined, value=null, onChange=undefined, multiple=undefined, queryValue=undefined, compare=undefined }) => {
    const { isFetching, customers } = useGetCustomers({ variant: 'pos' })
    const { getAutocompleteValue } = useAutocompleteManipulateValue({ multiple, value, list: customers, queryValue, compare, })
    const val = getAutocompleteValue()

    return(
        <>
            <Autocomplete
                size={size}
                multiple={multiple}
                value={val}
                onChange={onChange}
                loading={isFetching}
                label="Cliente"
                options={customers}
            />
        </>
    )
}

export default CustomerFinder