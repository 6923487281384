import ActionsList from "@component/ActionsList"
import SuperDataGrid from "@component/SuperDataGrid"
import { Grid } from "@component/UIComponents"
import { useGetReceivedSalesInvoices } from "@feature/ReceiptOfSalesInvoices/hooks/useGetReceivedSalesInvoices"
import { usePanel } from "@hook/usePanel"
import { currencyFormat } from "@util/currencyFormat"
import { useReceiptOfSalesInvoicesOptions } from "./hooks/useReceiptOfSalesInvoicesOptions"
import PreviewSalesInvoice from "./components/PreviewSalesInvoice"
import useReceiptOfSalesInvoicesController from "./hooks/useReceiptOfSalesInvoicesController"
import { useMemo } from "react"

const ReceiptOfSalesInvoices = () => {
    const { popUpWindows, selectedInvoice, openPreviewInvoice, closePreviewInvoice, enterReceivedInvoicePurchase } = useReceiptOfSalesInvoicesController()
    const { pendingInvoiceOptionsMenu, optionsReceivedInvoice } = useReceiptOfSalesInvoicesOptions()
    const { getDateFormats } = usePanel()
    const { receiptSalesInvoices, getReceiptSaleInvoice } = useGetReceivedSalesInvoices({ variant: "received" })
    const receiptSaleInvoice = useMemo(() => getReceiptSaleInvoice({
        findBy: (n) => Boolean(n?.receivedSalesInvoice) ? (n?.receivedInvoiceRef??"") : (n?.sale?._id??""),
        val: selectedInvoice
    }), [getReceiptSaleInvoice, selectedInvoice])

    const Buttons = (props={}) => <ActionsList
        {...props}
        variant="mobileMenu"
        options={(props?.receivedSalesInvoice??false) ?
            optionsReceivedInvoice({
                onPreviewSaleInvoice: () => openPreviewInvoice((props?.receivedInvoiceRef??null)),
                onEnterInvoicePurchase: () => enterReceivedInvoicePurchase({ receivedSale: (props?.receivedInvoiceRef??null) })
            })
            :
            pendingInvoiceOptionsMenu({
                onPreviewSaleInvoice: () => openPreviewInvoice((props?.sale?._id??null)),
                invoiceReceptionId: (props?._id??null),
            })
        }
    />
    const columns = [
        {flex: 1, field: "business", headerName: "Remitente", renderCell: ({row}) => (row?.business??"")},
        {maxWidth: 150, minWidth: 150, field: "total", headerName: "Valor", renderCell: ({row}) => currencyFormat((row?.sale?.total??""))},
        {maxWidth: 150, minWidth: 150, field: "consecutive", headerName: "Consecutivo", renderCell: ({row}) => `#FAC${(row?.sale?.consecutive??"")}`},
        {maxWidth: 150, minWidth: 150, field: "receivedSalesInvoice", headerName: "Estado", renderCell: ({row}) => (row?.receivedSalesInvoice??false) ? "Recibida" : "Pendiente"},
        {maxWidth: 150, minWidth: 150, field: "createdAt", headerName: "Fecha de registro", renderCell: ({row}) => getDateFormats(row?.createdAt).ddmmyyyyhhmm},
        {maxWidth: 50, minWidth: 50,field: "", headerName: "", renderCell: ({row}) => <Buttons {...row} />},
    ]

    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <SuperDataGrid
                        columns={columns}
                        rows={receiptSalesInvoices}
                    />
                </Grid>
            </Grid>
            <PreviewSalesInvoice isReceived={(receiptSaleInvoice?.receivedSalesInvoice??false)} saleRef={selectedInvoice} open={popUpWindows.previewSalesInvoice} onClose={() => closePreviewInvoice()} />
        </>
    )
}

export default ReceiptOfSalesInvoices