import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@component/UIComponents'
import { useCategoriesControllerForm } from '../hooks/useCategoriesControllerForm'
import Form from '@component/Form'
import { useEffect } from 'react'
import { useRegisterProductCategory } from '../hooks/useRegisterProductCategory'

const RegisterCategory = ({ category=null, open=false, onClose=()=>null }) => {
    const { isLoadingRegisterProductCategory, registerProductCategory } = useRegisterProductCategory()
    const { values, setFieldValue, resetValues, validateValues } = useCategoriesControllerForm({ defaultValues: category })
    const { name="" } = values

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        registerProductCategory(values, {
            closeForm: () => onClose()
        })
    }

    useEffect(() => {
        if( open === false ){
            resetValues()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return(
        <>
            <Dialog maxWidth="xs" fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Grid container >
                            <Grid xs={12} >
                                <TextField
                                    onChange={({ target }) => setFieldValue("name", (target?.value??""))}
                                    placeholder="Categoría"
                                    fullWidth
                                    value={name}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button loading={isLoadingRegisterProductCategory} type='submit' >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default RegisterCategory