import { AddCardRoundedIcon, IconButton, TextField, styled } from '@component/UIComponents'
import NumericFormat from "./NumericFormat"

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    '& input[type="number"]': {
        MozAppearance: 'textfield',
        WebkitAppearance: 'none',
        margin: 0,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
    },
    '& .MuiInputBase-input': {
        lineHeight: 1,
        padding: 0,
        fontSize: "2.5rem",
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    '& .MuiFormHelperText-root': {
        lineHeight: 1,
        fontWeight: 'bold',
        textTransform: "uppercase",
        fontSize: "1rem",
        margin: 0,
        textAlign: "center",
    },
}));

const PayFullAmount = ({ maxVal=undefined, onKeyUp=undefined, onClick=undefined, defaultValue=undefined, helperText=undefined, size=undefined, id=undefined, className=undefined, value=undefined, onChange=undefined,  }) => {
    return(
        <>
            <NumericFormat
                endAdornment={<>
                    <IconButton color="primary" onClick={onClick} >
                        <AddCardRoundedIcon />
                    </IconButton>
                </>}
                onKeyUp={onKeyUp}
                TextFieldDefault={TextFieldStyled}
                // defaultValue={defaultValue}
                // maxVal={maxVal}
                className={className}
                size={size}
                id={id}
                value={value}
                fullWidth
                style={{
                    fontWeight: "bold"
                }}
                onChange={ Boolean(onChange) ? (params) => onChange(params) : () => null }
            />
        </>
    )
}

export default PayFullAmount