import { AddRoundedIcon, Box, Card, Divider, RemoveRoundedIcon, Stack, styled, Typography } from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import dayjs from 'dayjs'
import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import '@style/printStyles.css'
import SkeletonInvoice from './components/SkeletonInvoice'

const TypographyCustomerInformation = styled(Typography)(() => ({
    lineHeight: 1.2,
    fontSize: '.7rem',
}))

const Invoice = forwardRef(({ variant="sale", loading=false, listOfProductsToCompare=[], sx={}, invoiceType="", consecutive=0, createdAt="", seller={}, business={}, customer={}, products=[], total=0 }, ref) => {
    const { t } = useTranslation()

    const analyzeProductsBetweenTwoInvoices = useCallback((product) => {
        const newProduct = listOfProductsToCompare.some(n => ((n?.productId??null) === (product?.productId??null)))
        const removedProducts = listOfProductsToCompare.filter((n) => !products.some((x) => x.productId === n.productId))
        return {
            newProduct: (listOfProductsToCompare.length <= 0) ? true : newProduct,
            removedProducts
        }
    }, [listOfProductsToCompare, products])

    const ProductDetailLine = ({ product={}, isNewProduct=false, isProductRemoved=false }) => {
        return(
            <Box component={'span'} sx={{
                ...(isProductRemoved ? {
                    displayPrint: "none",
                } : {})
            }} >
                <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'} >
                    <Box display={'flex'} alignItems={'center'} displayPrint={"none"} >
                        {isNewProduct &&
                            <AddRoundedIcon sx={{ fontSize: '1.2rem', color: (theme) => theme.palette.success.main }} />
                        }
                        {isProductRemoved &&
                            <RemoveRoundedIcon sx={{ fontSize: '1.2rem', color: (theme) => theme.palette.error.main }} />
                        }
                    </Box>
                    <Box my={"2px"} display={'flex'} flexDirection={'column'} width={'100%'} >
                        <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} fontWeight='bold' > {product?.name??""} </Typography>
                        <Box display={'flex'} justifyContent={'space-between'} >
                            <Box display={'flex'} >
                                <Typography className='accentuateBlackColor' mr={1} lineHeight={1.2} fontSize={".8rem"} > x{product?.quantity??0} </Typography>
                                <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} > { currencyFormat(product?.salePrice??0) } </Typography>
                            </Box>
                            <Typography className='accentuateBlackColor' lineHeight={1.2} fontSize={".8rem"} > {currencyFormat(product.salePrice*product.quantity)} </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider
                    sx={{
                        borderColor: "#00000078"
                    }}
                />
            </Box>
        )
    }

    return(
        <>
            <Stack width={1} spacing={1} >
                <Card ref={ref} elevation={0} variant='outlined' className='templateInvoice' sx={{ width: "100%", height: "max-content", padding: 1.5, ...sx }} >
                    {loading ?
                        <>
                            <SkeletonInvoice />
                        </>
                        :
                        <>
                            {invoiceType === "price" ?
                                <Box>
                                    <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.5rem"} textAlign={'center'} textTransform={'uppercase'} >Orden de pedido</Typography>
                                    <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > UNIKO </Typography>
                                </Box>
                                :
                                <Box>
                                    {variant === "creditNote" &&
                                        <Typography textTransform={'uppercase'} className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > Nota crédito </Typography>
                                    }
                                    <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} >{ business?.name??"" }</Typography>
                                    <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > Tel. { business?.phone??"" } </Typography>
                                    <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {business?.address??""} </Typography>
                                    <Typography className='accentuateBlackColor' lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {business?.nit ? business?.nit : ""} </Typography>
                                </Box>
                            }
                            <Box mt={1} display="flex" justifyContent={'space-between'} >
                                <TypographyCustomerInformation className='accentuateBlackColor' fontWeight={'500'} > {invoiceType === "price" ? "#C" : "#R"} {consecutive} </TypographyCustomerInformation>
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('date')}:  {dayjs(createdAt).format("DD-MM-YYYY HH:mm:ss")} </TypographyCustomerInformation>
                            </Box>
                            <Box mb={1} >
                                <TypographyCustomerInformation className='accentuateBlackColor' fontSize={".8rem"} > {t('seller')} : {seller?.name??""} {seller?.lastName??""} </TypographyCustomerInformation>
                            </Box>
                            <Divider />
                            <Box my={1} >
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('customer')}: {customer?.name??""} </TypographyCustomerInformation>
                                <TypographyCustomerInformation className='accentuateBlackColor' >Tel: {customer?.phoneNumber??""} </TypographyCustomerInformation>
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('address')}: {customer?.address??""} </TypographyCustomerInformation>
                                <TypographyCustomerInformation className='accentuateBlackColor' >{t('city')}: {customer?.city?.name??""} </TypographyCustomerInformation>
                            </Box>
                            <Divider />
                            {(products !== undefined) &&
                                <>
                                    {products?.map((product, index) => {
                                        return(
                                            <Box key={index} >
                                                <ProductDetailLine product={product} isNewProduct={!analyzeProductsBetweenTwoInvoices(product).newProduct} />
                                            </Box>
                                        )
                                    })}
                                    {(analyzeProductsBetweenTwoInvoices()?.removedProducts??[])?.map((product, index) => {
                                        return(
                                            <Box key={index} >
                                                <ProductDetailLine product={product} isProductRemoved />
                                            </Box>
                                        )
                                    })}
                                </>
                            }
                            <Box my={1} >
                                <Typography className='accentuateBlackColor' textAlign={'right'} lineHeight={1.2} fontSize={"1rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('total')}: { currencyFormat(total??0) } </Typography>
                            </Box>
                        </>
                    }
                </Card>
            </Stack>
        </>
    )
})

export default Invoice