import Form from '@component/Form'
import { Box, Button, Divider, Popover, Typography } from '@component/UIComponents'
import { useURLParams } from '@hook/useURLParams'
import { useCallback } from 'react'

const paperProps = {
    paper:{
        elevation: 0,
        sx : {
            overflow: 'visible',
            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 40,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
    }
}

const Filters = ({ filtersAnchorEl=null, openFilters=false, onClose=()=>null, component=()=><></>, filters=null, variant="new" }) => {
    const { addQuery, delAllQueries } = useURLParams()

    const applyFilters = useCallback(() => {
        let query = {}
        delAllQueries()
        Object.keys(filters).forEach((key) => {
            if( Boolean(filters[key]) ){
                query[key] = filters[key]
            }
        })
        addQuery(query)
        onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const clearAllFilters = useCallback(() => {
        delAllQueries()
        onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if( variant === "standard" ){
        return(
            <>
                <Popover anchorEl={filtersAnchorEl} open={openFilters} onClose={onClose} variant="contained" anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} slotProps={{...paperProps}} >
                    <Box maxWidth={400} padding={2}>
                        <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Filtros: </Typography>
                        <Divider sx={{ mb: 1 }} />
                        {component()}
                    </Box>
                </Popover>
            </>
        )
    }else{
        return(
            <>
                <Popover anchorEl={filtersAnchorEl} open={openFilters} onClose={onClose} variant="contained" anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} slotProps={{...paperProps}} >
                    <Form onSubmit={applyFilters} >
                        <Box maxWidth={400} padding={2}>
                            <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} > Filtros: </Typography>
                            <Divider sx={{ mb: 1 }} />
                            {component()}
                        </Box>
                        <Box p={1} minWidth={250} display={'flex'} justifyContent={'space-between'} >
                            <Button size="small" color='error' onClick={clearAllFilters} > Limpiar filtros </Button>
                            <Button size="small" color='primary' type='submit' > Filtrar </Button>
                        </Box>
                    </Form>
                </Popover>
            </>
        )
    }
}

export default Filters