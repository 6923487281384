import { useGetTaxesQuery, useGetCitiesQuery, useGetBusinessAssetsQuery, useGetBusinessLiabilitiesQuery } from "@query/Business"
import { useGetNotificationsQuery } from "@query/Notifications"
import { useCallback, useMemo } from "react"

export const useGetTaxes = (filters={}) => {
    const { data, isFetching } = useGetTaxesQuery(filters)
    const taxes = (data?.payload??[])
    return {
        taxes,
        isFetching
    }
}

export const useGetCities = (filters={}) => {
    const { data, isFetching } = useGetCitiesQuery(filters)
    const cities = (data?.payload??[])
    return {
        cities,
        isFetching
    }
}

export const useGetNotifications = () => {
    const { data, isFetching } = useGetNotificationsQuery()
    let notifications = (data?.payload??[])
    notifications = Array.isArray(notifications) ? notifications : []
    const unreadNotifications = (notifications??[]).filter((n) => n.view === false)
    return {
        notifications,
        isFetching,
        unreadNotifications
    }
}

export const useGetBusinessAssets = () => {
    const { data, isFetching } = useGetBusinessAssetsQuery()
    const payload = useMemo(() => (data?.payload??[]), [data])

    const { assets, details } = useMemo(() => {
        const assets = (payload?.assets??0)
        const details = (payload?.details??[])
        return { assets, details }
    }, [payload])

    const getAsset = useCallback((asset="") => {
        let currentDetails = [...details]
        return currentDetails.find((n) => (n?._id??"") === asset) || null
    }, [details])


    return {
        isFetching,
        assets,
        details,
        getAsset
    }
}

export const useGetBusinessLiabilities = () => {
    const { data, isFetching } = useGetBusinessLiabilitiesQuery()
    const payload = useMemo(() => (data?.payload??[]), [data])

    const { liabilities, details } = useMemo(() => {
        const liabilities = (payload?.liabilities??0)
        const details = (payload?.details??[])
        return { liabilities, details }
    }, [payload])

    const getLiabilities = useCallback((liabilities="") => {
        let currentDetails = [...details]
        return currentDetails.find((n) => (n?._id??"") === liabilities) || null
    }, [details])


    return {
        isFetching,
        liabilities,
        details,
        getLiabilities
    }
}

export const useBusinessBalance = () => {
    const { isFetching:isFetchingAssets, assets, details:assetsDetails } = useGetBusinessAssets()
    const { isFetching:isFetchingLiabilities, liabilities, details:liabilitiesDetails } = useGetBusinessLiabilities()
    const balance = (assets-liabilities)

    return {
        isFetchingAssets,
        isFetchingLiabilities,
        assets,
        liabilities,
        assetsDetails,
        liabilitiesDetails,
        balance,
    }
}