import { yup } from "@hook/useFormik"
import { useManageFormData } from "@hook/usePanel"
import { useGetBanksQuery, useRegisterNewBankMutation } from "@query/Movements"
import { asynchronousResponseHandler } from "@util/helpers"
import { useMemo } from "react"

export const useGetBanks = () => {
    const { data, isFetching } = useGetBanksQuery()
    let resp = (data?.payload??[])

    return {
        resp,
        isFetching
    }
}

export const useBankFormData = (props={}) => {
    const { defaultValues=undefined, openModal=false } = props
    const initialValues = useMemo(() => ({ name: "", accountNumber: "", account: "personal", accountType: "savings", enabledPaymentsPurchases: true, enabledPaymentsSales: true, enabledTransfers: true, }), [])
    const { resetValues, setFieldValue, validateValues, values } = useManageFormData({
        defaultValues,
        initialValues,
        schema: {
            name: yup.string().required("El nombre del banco es requerido."),
        },
        openModal,
    })
    return { values, setFieldValue, resetValues, validateValues }
}

export const useRegisterNewBank = () => {
    const [ registerNewBankMutation, { isLoading } ] = useRegisterNewBankMutation()

    let registerNewBank = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await registerNewBankMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id??null)) ?
                        "El banco se actualizo exitosamente."
                        :
                        "El nuevo banco se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            
        }
    }

    return {
        registerNewBank,
        isLoading
    }
}