import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";

export const usePreSelectionTimeOut = () => {
    const [timeoutId, setTimeoutId] = useState(null);

    const clearTimeOutFunction = () => {
        if ( Boolean(timeoutId) ) {
            clearTimeout(timeoutId);
        }
    }
    const activeTimeOut = (onTimeOut=undefined) => {
        if( Boolean(onTimeOut) ){
            const newTimeoutId = setTimeout(onTimeOut, 1000);
            setTimeoutId(newTimeoutId);
        }
    }

    return {
        clearTimeOutFunction,
        activeTimeOut
    }
}

export const useProductPreSelection = ({ setFieldValue, product, defaultValue=null, formValues={} }) => {
    const [triggerEraser, setTriggerEraser] = useState(true)
    const { activeTimeOut, clearTimeOutFunction } = usePreSelectionTimeOut()

    const getCursorPositionInInput = (inputValue="", event=null) => {
        const cursorPosition = (event?.target?.selectionStart??null)
        const countStringValue = [...inputValue].length
        const isLastPosition = (countStringValue===cursorPosition)

        return { cursorPosition, countStringValue, isLastPosition }
    }

    const replaceInputValue = ({inputName="", value, lastPosition}) => {
        if( triggerEraser === true ){
            setTriggerEraser(false)
            setFieldValue(inputName, "")
            if( lastPosition ){
                value = [...String(value)]
                value = [value[value.length-1]]
                value = parseFloat(value.join(""))
                value = Boolean(value) ? value : 0
            }
            setFieldValue(inputName, value)
        }else{
            setFieldValue(inputName, value)
        }
    }

    const handleChangePrice = ({ typePrice="list", pricePosition=0, event=null, values={} }) => {
        setFieldValue("typePrice", typePrice)
        if( typePrice === "list" ){
            setFieldValue("pricePosition", pricePosition)
            setFieldValue("salePrice", ((product?.prices??[])[pricePosition]?.sellPrice??0))
        }
        if(typePrice === "manual"){
            clearTimeOutFunction()
            setFieldValue("pricePosition", 0)
            const { formattedValue, floatValue } = values
            const { isLastPosition } = getCursorPositionInInput(formattedValue, event)
            replaceInputValue({ inputName: "salePrice", lastPosition: isLastPosition, value: floatValue })
            activeTimeOut(() => {
                setTriggerEraser(true)
            })
        }
    }

    const handleChangeQuantity = (values={}, event=null) => {
        clearTimeOutFunction()
        const { formattedValue, floatValue } = values
        const { isLastPosition } = getCursorPositionInInput(formattedValue, event)
        replaceInputValue({ inputName: "quantity", lastPosition: isLastPosition, value: floatValue })
        activeTimeOut(() => {
            setTriggerEraser(true)
        })
    }

    const loadProductData = useCallback(() => {
        if( !Boolean(defaultValue?.productId) ){
            setFieldValue("costPrice", (product?.cost??0))
            setFieldValue("name", (product?.name??0))
            setFieldValue("productId", (product?._id??""))
            setFieldValue("salePrice", ((product?.prices??[])[0]?.sellPrice??0))
            setFieldValue("typePrice", "list")
            setFieldValue("pricePosition", 0)
        }
    }, [product, setFieldValue, defaultValue])

    const keyboardShortcuts = useCallback((event) => {
        const { key } = event
        const { pricePosition, typePrice, salePrice } = formValues
        const prices = (product?.prices??[]).length-1

        const eventPrevent = ["Tab"]
        if( eventPrevent.some((n) => n === key) ){
            event.preventDefault()
        }
        
        if( key === "Tab" ){
            if( typePrice === "list" ){
                if( pricePosition >= prices ){
                    let elem = document.getElementById("manualPrice")
                    if( Boolean(elem) ){
                        setFieldValue("typePrice", "manual")
                        setFieldValue("pricePosition", 0)
                        setFieldValue("salePrice", 0)
                        elem.focus()
                    }
                }else{
                    setFieldValue("salePrice", ((product?.prices??[])[pricePosition+1]?.sellPrice??0))
                    setFieldValue("pricePosition", pricePosition+1)
                }
            }else{
                const activeElement = (document.activeElement)?.id
                if( activeElement === "manualPrice" ){
                    let elem = document.getElementById("quantityProduct")
                    if( Boolean(elem) ){
                        elem.focus()
                    }
                    if( !Boolean(salePrice) ){
                        setFieldValue("typePrice", "list")
                        setFieldValue("salePrice", ((product?.prices??[])[pricePosition]?.sellPrice??0))
                    }
                }else if(activeElement === "quantityProduct"){
                    let elem = document.getElementById("manualPrice")
                    if( Boolean(elem) ){
                        elem.focus()
                    }
                }
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, formValues])

    useEffect(() => {
        if( Boolean(product?._id) ){
            window.addEventListener("keydown", keyboardShortcuts)
        }else{
            window.removeEventListener("keydown", keyboardShortcuts)
        }
        return () => {
            window.removeEventListener("keydown", keyboardShortcuts)
        }
    },[keyboardShortcuts, product])

    return {
        handleChangePrice,
        handleChangeQuantity,
        loadProductData,
        setTriggerEraser
    }
}