import Autocomplete from "@component/Autocomplete"
import Form from "@component/Form"
import NumericFormat from "@component/NumericFormat"
import PatternFormat from "@component/PatternFormat"
import { Button, Checkbox, DatePicker, FormControlLabel, Grid } from "@component/UIComponents"
import { useURLParams } from "@hook/useURLParams"
import dayjs from "dayjs"
import { Fragment } from "react"
import { useEffect, useState } from "react"

const AdvancedFilters = ({ options=[] }) => {
    const { addQuery, delQueries, getQuery } = useURLParams()
    const [filters, setFilters] = useState({})

    const handleFilters = (field, value) => {
        let currentFilters = {...filters}
        if( Boolean(value) ){
            currentFilters[field] = value
        }else{
            delete currentFilters[field]
        }
        setFilters(currentFilters)
    }
    const handleSubmit = () => {
        let currentFilters = {...filters}
        let filtersKey = Object.keys(currentFilters)
        for( let n of filtersKey ){
            if( Array.isArray(currentFilters[n]) ){
                currentFilters[n] = currentFilters[n].map((b) => {
                    return Boolean((b?.value??"")) ? (b?.value??"") : (b?.name??"")
                }).join("-")
            }
            if( typeof currentFilters[n] === "object" && Object.keys(currentFilters[n]).length >= 1 ){
                currentFilters[n] = (currentFilters[n]?.value??"")
            }
            if( !Boolean(currentFilters[n]) ){
                delete currentFilters[n]
            }

            if(n === "omitDateRange" && currentFilters[n] === true ){
                delete currentFilters.sd
                delete currentFilters.ed
            }
        }

        const advancedFilters = options.map((n) => (n?.name??""))
        delQueries([...advancedFilters])
        addQuery({...currentFilters})
    }
    const clearFilters = () => {
        const advancedFilters = options.map((n) => (n?.name??""))
        delQueries([...advancedFilters])
        setFilters({})
    }

    useEffect(() => {
        const transformArray = ["invoiceStatus", "paymentTypes", "showOnly", "see", "others"]
        const transformObject = ["groupBy", "purchaseStatus", "showOnly", "see", "others"]
        const advancedFilters = options.map((n) => (n?.name??""))

        for( let n of advancedFilters ){
            let value = getQuery(n)
            if( !Boolean(filters[n]) && Boolean(value) ){
                if( transformArray.some(x => (x === n)) && (options.find(x => x.name === n)?.componentProps?.multiple??false) === true ){
                    value = value.split("-")
                    value = options.filter(s => s.name === n).reduce((acc, params) => ([...acc, ...(params?.componentProps?.options??[])]), []).filter((m) => value.some((z) => z === (m?.value??m?.name??"")))
                }

                if( transformObject.some(x => x === n) && (options.find(x => x.name === n)?.componentProps?.multiple??false) === false ){
                    value = options.filter(s => s.name === n).reduce((acc, params) => ([...acc, ...(params?.componentProps?.options??[])]), []).filter((m) => [value].some((z) => z === (m?.value??m?.name??"")))
                    value = value[0]??null
                }

                setFilters((prevValue) => ({...prevValue, [n]: value }))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    return(
        <>
            <Form onSubmit={handleSubmit} >
                <Grid container spacing={1} >
                    {options.map((item, index) => {
                        return(
                            <Fragment key={index}  >
                                { Boolean((item?.show??false)) &&
                                    <Grid {...(item?.gridProps??{})} >
                                            <>
                                                { item.component === "patternFormat" && <PatternFormat value={(filters[(item?.name??"")]??"")} onChange={(value) => handleFilters((item?.name??""), value)} {...(item?.componentProps??{})} /> }
                                                { item.component === "numericFormat" && <NumericFormat value={(filters[(item?.name??"")]??"")} onChange={(value) => handleFilters((item?.name??""), value)} {...(item?.componentProps??{})} /> }
                                                { item.component === "autocomplete" && <Autocomplete value={ Boolean(((item?.componentProps??{})?.multiple??"")) ? (filters[(item?.name??"")] || []) : (filters[(item?.name??"")]??null) } onChange={(elem, value) => handleFilters((item?.name??""), value)} {...(item?.componentProps??{})} /> }
                                                { item.component === "datePicker" && <DatePicker value={dayjs(filters[(item?.name??"")]??"")} onChange={(value) => handleFilters((item?.name??""), dayjs(value).format("MM-DD-YYYY"))} {...(item?.componentProps??{})} /> }
                                                { item.component === "checkbox" && <>
                                                    <FormControlLabel
                                                        label={((item?.componentProps??{})?.label??"")}
                                                        control={<Checkbox onChange={({ target }) => handleFilters((item?.name??""), target.checked)} checked={((filters[(item?.name??"")]??"") === "true" || (filters[(item?.name??"")]??"") === true) ? true : false} />}
                                                    />
                                                </>}
                                            </>
                                    </Grid>
                                }
                            </Fragment>
                        )
                    })}
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <Button color="error" onClick={clearFilters} > Limpiar filtros </Button>
                        <Button type="submit" > Filtrar </Button>
                    </Grid>
                </Grid>
            </Form>
        </>
    )
}

export default AdvancedFilters