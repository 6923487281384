import { useState } from "react"
import { Box, Chip, Divider, TextField } from "./UIComponents"
import { useCallback } from "react"
import { useRef } from "react"
import { Toast } from "@util/swal"
import { useEffect } from "react"
import { regex } from "@util/regularExpressions"

const TagInput = ({ label="Etiquetas", fullWidth=true, getLabel=undefined, value=undefined, onChange=()=>null, blanks=false }) => {
    const [inputValue, setInputValue] = useState("")
    const [tags, setTags] = useState([])
    const inputRef = useRef(null)

    const loadDefaultData = useCallback(() => {
        if( Boolean(value) && Array.isArray(value) && (tags.length <= 0) ){
            let currentDefaultTags = [...value]
            currentDefaultTags = currentDefaultTags.map((n, index) => ({...n, _id: (index+1)}))
            setTags(currentDefaultTags)
        }
    }, [value, tags])

    const addNewTag = useCallback((val) => {
        let currentTags = [...tags]
        let currentVal = (blanks === false) ? regex({ action: "replace", string: val, validation: "blanks", flag: "" }) : blanks
        let existTag = currentTags.some((n) => n.name === currentVal)

        if( existTag ){
            Toast.fire({
                icon: "warning",
                text: `El valor ${currentVal} ya se encuentra registrado en la lista.`
            })
            return
        }

        currentTags.push({ name: currentVal })
        currentTags = currentTags.map((n, index) => ({...n, _id: (index+1)}))
        setTags(currentTags)
        setInputValue("")
        onChange(currentTags)
    }, [tags, onChange, blanks])

    const handleKeyDown = useCallback((event) => {
        const { code } = event
        if( code === "NumpadEnter" || code === "Enter" ){
            if( inputValue.trim() !== "" ){
                event.preventDefault()
                event.stopPropagation()
                addNewTag(inputValue)
            }
        }
    }, [addNewTag, inputValue])

    const deleteTag = useCallback((index) => {
        let currentTags = [...tags]
        currentTags.splice(index, 1)
        setTags(currentTags)
        inputRef.current.focus()
        onChange(currentTags)
    }, [tags, onChange])

    useEffect(() => {
        loadDefaultData()
    }, [loadDefaultData])

    return(
        <>
            <TextField
                inputRef={inputRef}
                onKeyDown={handleKeyDown}
                value={inputValue}
                label={label}
                fullWidth={fullWidth}
                onChange={({target}) => setInputValue((target?.value??""))}
            />
            {tags.length >= 1 &&
                <>
                    <Box my={1} display={'flex'} gap={1} flexWrap={'wrap'} justifyContent={'center'} >
                        {tags.map((item, index) => {
                            return(
                                <Chip
                                    key={index}
                                    label={Boolean(getLabel) ? getLabel(item) : (item?.name??"")}
                                    color="primary"
                                    size="small"
                                    onDelete={() => deleteTag(index)}
                                />
                            )
                        })}
                    </Box>
                    <Divider sx={{ mb: 1 }} />
                </>
            }
        </>
    )
}

export default TagInput