import NumericFormat from '@component/NumericFormat'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@component/UIComponents'
import Dropzone from '@component/Dropzone'
import TaxesFinder from '@feature/Business/components/TaxesFinder'
import PackagingFinder from '@feature/Inventory/Packaging/components/PackagingFinder'
import ProviderFinder from '@feature/Providers/components/ProviderFinder'
import { useGetProduct, useProductFormData, useProductOptions, useRegisterProduct } from '../hooks/useProducts'
import ProductFinder from '../components/ProductFinder'
import PriceListForm from '../components/PriceListForm'
import CategoriesFinder from '@feature/Inventory/Categories/components/CategoriesFinder'
import Form from '@component/Form'
import { convertFilesToBase64 } from '@util/helpers'
import Autocomplete from '@component/Autocomplete'
import TagInput from '@component/TagInput'

const ProductForm = ({ open=false, onClose=()=>null, productId=null, defaultValues=null, onAfterSaving=()=>null }) => {
    const { product } = useGetProduct({ productId }, { skip : !Boolean(productId) })
    const { values, setFieldValue, updatePrices, validateValues, resetValues } = useProductFormData({ defaultValues: Boolean(defaultValues) ? defaultValues : product, openModal: open })
    const { name="", activePOS=true, activePurchase=true, productParent=null, category=null, packaging=null, provider=null, stock=0, skuCode="", tax=null, cost=0, prices=[], images=[], purchaseCodes=[] } = values
    const { registerProduct, isLoading } = useRegisterProduct()
    const { fifthOptions } = useProductOptions()
    const handleSubmit = () => {
        let currentValues = {...values}
        let currentImages = (currentValues?.images??[])
        convertFilesToBase64(currentImages)
        .then((img) => {
            currentValues.images = img.map((n, index) => ({name: (currentImages[index]?.name??""), base: n}))
            const { type } = validateValues(currentValues)
            if(type === "errors") return
            registerProduct(currentValues, {
                closeForm: () => {
                    resetValues()
                    onClose()
                    onAfterSaving()
                }
            })
        })
        .catch((err) => {
            console.log(err)
        })
    }
    return(
        <>
            <Dialog fullWidth PaperProps={{ elevation: 0 }} transitionDuration={0} open={open} onClose={onClose} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    value={name}
                                    onChange={({target}) => setFieldValue("name", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <ProductFinder
                                    value={productParent}
                                    placeholder=''
                                    inputVariant="outlined"
                                    fullWidth
                                    label="Compuesto"
                                    variant={'single'}
                                    typeProduct={'single'}
                                    onChange={(_, value) => setFieldValue("productParent", value)}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <CategoriesFinder
                                    value={category}
                                    fullWidth
                                    label="Categoría"
                                    onChange={(_, value) => setFieldValue("category", value)}
                                />
                            </Grid>
                            <Grid xs={6} >
                                <PackagingFinder
                                    value={packaging}
                                    onChange={(_, value) => setFieldValue("packaging", value)}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <ProviderFinder
                                    placeholder={""}
                                    label={"Proveedor"}
                                    value={provider}
                                    onChange={(_, value) => setFieldValue("provider", value)}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <NumericFormat
                                    disabled={Boolean(productParent)}
                                    prefix={""}
                                    suffix=""
                                    thousandSeparator=" "
                                    fullWidth
                                    label="Stock"
                                    value={stock}
                                    onChange={({ floatValue }) => setFieldValue("stock", (floatValue??0))}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <TextField
                                    fullWidth
                                    label="SKU"
                                    value={skuCode}
                                    onChange={({target}) => setFieldValue("skuCode", (target?.value??""))}
                                />
                            </Grid>
                            <Grid xs={4} >
                                <TaxesFinder
                                    filters={{ name: "IVA" }}
                                    value={tax}
                                    onChange={(_, value) => setFieldValue("tax", value)}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <TagInput
                                    label='Códigos de compra'
                                    value={ (purchaseCodes??[]).map((n) => ({name: n})) }
                                    onChange={(n) => setFieldValue("purchaseCodes", ((n??[]).map((x) => (x?.name??""))??[]))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Autocomplete
                                    multiple
                                    label="Habilitar en"
                                    options={fifthOptions}
                                    value={ fifthOptions.filter((n) => ((activePOS === true) ? n.value === "pointOfSale" : undefined) || ((activePurchase === true) ? n.value === "purchase" : undefined) ) }
                                    onChange={(_, value) => {
                                        let currentActivePOS = Boolean((value.find((n) => n?.value === "pointOfSale")))
                                        let currentActivePurchase = Boolean((value.find((n) => n?.value === "purchase")))
                                        setFieldValue("activePOS", currentActivePOS)
                                        setFieldValue("activePurchase", currentActivePurchase)
                                    }}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <NumericFormat
                                    disabled={Boolean(productParent)}
                                    fullWidth
                                    label="Costo"
                                    value={cost}
                                    onChange={({ floatValue }) => setFieldValue("cost", floatValue)}
                                />
                            </Grid>
                            <Grid xs={12} >
                                {prices.map((item, index) => {
                                    return(
                                        <PriceListForm key={index} cost={cost} {...item} onChange={(price) => updatePrices(price, index)} />
                                    )
                                })}
                            </Grid>
                            <Grid xs={12} >
                                <Dropzone
                                    onChange={(currentImages) => setFieldValue("images", currentImages)}
                                    defaultImages={images}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' loading={isLoading} >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default ProductForm