import { AddCardRoundedIcon, ArchiveRoundedIcon, ArticleRoundedIcon, DateRangeRoundedIcon, DeliveryDiningRoundedIcon, EditIconRounded, LocalPrintshopRoundedIcon, PlaylistRemoveRoundedIcon, ReceiptLongRoundedIcon, SendRoundedIcon, SimCardDownloadRoundedIcon } from "@component/UIComponents"
import { useValidatePermission } from "@hook/useValidatePermission"
import { useGetCopiesOfTheInvoiceQuery } from "@query/Sales"
import { preventAction } from "@util/helpers"
import { routes } from "@util/routes"
import { useCallback } from "react"
import { exportComponentAsPNG } from "react-component-export-image"
import { useNavigate } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import { useArchiveSalesInvoice } from "../components/UpdateCreditExpirationDate/hooks/useArchiveSalesInvoice"

export const useInvoiceMenu = (params={}) => {
    const navigate = useNavigate()
    const { sales } = routes
    const { validateArrayByPermissionOrder } = useValidatePermission()
    const { invoice={}, invoiceRef=null } = params
    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
    });
    const downloadInvoiceImage = useCallback(() => exportComponentAsPNG(invoiceRef, { fileName: (invoice?.consecutive??"") }), [invoiceRef, invoice])
    const { archiveSalesInvoice } = useArchiveSalesInvoice()

    const invoiceMenu = useCallback((props={}, config={}) => {
        const { balance=0, _id=null, invoiceType="", typePayment="", consecutive="", isArchived=false, mergeInvoice=false } = (props??{})
        const { onSendDelivery=()=>null, onMergeInvoice=()=>null, includingMenus=[], onPartialCreditNote=()=>null, onUpdateExpirationDate=()=>null, onShareSalesInvoice=()=>null, onAddPaymentInvoice=()=>null, onUpdateInvoiceInformation=()=>null, editInvoiceButtonProps={}, printInvoiceButtonProps={}, downloadInvoiceButtonProps={}, addPaymentButtonProps={}, } = config

        let menus = [
            {
                name: "editInvoice",
                title: "Editar factura ",
                onClick: () => navigate(sales({ saleId: _id })),
                icon: <EditIconRounded />,
                buttonProps: editInvoiceButtonProps,
            },
            {
                name: "partialCreditNote",
                title: "Nota crédito",
                onClick: () => onPartialCreditNote(),
                icon: <PlaylistRemoveRoundedIcon />,
                buttonProps: editInvoiceButtonProps,
            },
            {
                name: "printInvoice",
                title: "Imprimir factura",
                onClick: () => handlePrint(),
                icon: <LocalPrintshopRoundedIcon />,
                buttonProps: printInvoiceButtonProps,
            },
            {
                name: "downloadInvoice",
                title: "Descargar factura",
                onClick: () => downloadInvoiceImage(),
                icon: <SimCardDownloadRoundedIcon />,
                buttonProps: downloadInvoiceButtonProps,
            },
            {
                name: "addPayment",
                title: "Añadir abono",
                onClick: () => onAddPaymentInvoice(_id),
                icon: <AddCardRoundedIcon />,
                buttonProps: {
                    ...addPaymentButtonProps,
                    disabled: (balance <= 0) || (addPaymentButtonProps?.disabled??undefined),
                }
            },
            {
                name: "invoiceInformation",
                title: "Información de la factura",
                onClick: () => onUpdateInvoiceInformation(_id),
                icon: <ArticleRoundedIcon />
            },
            {
                name: "sendDelivery",
                title: "Enviar por domicilio",
                onClick: () => onSendDelivery(_id),
                icon: <DeliveryDiningRoundedIcon />
            },
            {
                name: "sendSale",
                title: "Enviar venta",
                onClick: () => onShareSalesInvoice(),
                icon: <SendRoundedIcon />
            },
            ...((typePayment === "credit") ? [{
                title: "Actualizar fecha de vencimiento del crédito",
                onClick: () => onUpdateExpirationDate(),
                icon: <DateRangeRoundedIcon />
            }] : []),
            {
                name: "archiveSalesInvoice",
                title: isArchived ? "Recuperar factura" : "Archivar factura",
                onClick: () => preventAction({
                    text: isArchived ? `¿Desea recuperar la factura de venta #FAC${consecutive}?` : `¿Desea archivar la factura de venta #FAC${consecutive}?`,
                    onSubmit: () => archiveSalesInvoice({ saleRef: _id })
                }),
                icon: <ArchiveRoundedIcon />
            },
        ]
        // menus = validateArrayByPermissionOrder(["edit_sales_invoice", "print_sales_invoice", "download_copy_of_sales_invoice", ["add_credit_to_cash_sale", "add_credit_to_consignment_sale" , "add_credit_to_advance_sale"], ["record_delivery_information"]], menus)

        if( invoiceType === "price" ){
            menus = menus.filter((b) => !["addPayment", "sendSale", "invoiceInformation"].some((x) => b.name === x))
        }

        if( typePayment === "undefined" && ((invoiceType??"") === "sale") ){
            menus = menus.filter((b) => ["editInvoice", "archiveSalesInvoice"].some((x) => b.name === x))
        }

        if( isArchived === true ){
            menus = menus.filter((b) => ["archiveSalesInvoice"].some((x) => b.name === x))
        }

        if( (includingMenus?.length??0) >= 1 ){
            menus = menus.filter((b) => includingMenus.some((x) => b.name === x))
            if( includingMenus.some((b) => b === "mergeInvoice") ){
                menus.push({
                    name: "mergeInvoice",
                    title: mergeInvoice ? "Nota crédito" : "Fusionar",
                    onClick: () => onMergeInvoice(),
                    icon: <ReceiptLongRoundedIcon />
                },)
            }
        }

        return menus
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlePrint, downloadInvoiceImage, validateArrayByPermissionOrder, navigate, invoice])

    return {
        invoiceMenu
    }
}

export const useGetCopiesOfTheInvoice = (filters={}, config={}) => {
    const { data, isFetching } = useGetCopiesOfTheInvoiceQuery(filters, config)
    const saleCopies = (data?.payload??[])
    return {
        saleCopies,
        isFetching
    }
}