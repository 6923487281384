import { useArchiveSalesInvoiceMutation } from "@query/Sales"
import { asynchronousResponseHandler } from "@util/helpers"

export const useArchiveSalesInvoice = () => {
    const [ archiveSalesInvoiceMutation, { isLoading } ] = useArchiveSalesInvoiceMutation()
    const archiveSalesInvoice = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null } = callback
                const resp = await archiveSalesInvoiceMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: (resp?.payload?.isArchived??false) ? "La factura se archivo exitosamente." : "La factura se recupero exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archiveSalesInvoice,
        isLoadingArchiveSalesInvoiceMutation: isLoading
    }
}