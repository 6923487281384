import SuperDataGrid from '@component/SuperDataGrid'
import { Dialog, DialogContent } from '@component/UIComponents'
import { useGetMerchandiseTransported, useHauliersMenu } from '../hooks/useHauliers'
import { usePanel } from '@hook/usePanel'
import { currencyFormat } from '@util/currencyFormat'
import ActionsList from '@component/ActionsList'
import Invoice from '@feature/PurchaseReport/Purchases/components/Invoice'
import { useState } from 'react'

const TransportedMerchandise = ({open=false, onClose=()=>null, refId="", }) => {
    const [showWindows, setShowWindows] = useState({ showPurchaseInvoice: "" })
    const { getDateFormats } = usePanel()
    const { merchandiseTransported, isFetching } = useGetMerchandiseTransported({ref: refId}, {skip: !Boolean(refId)})
    const { thirdMenu } = useHauliersMenu()

    const Buttons = (props) => <ActionsList
        variant='mobileMenu'
        options={thirdMenu({
            onShowPurchaseInvoice: () => setShowWindows(prevState => ({...prevState, showPurchaseInvoice: (props?._id??"")}))
        })}
    />

    const columns = [
        {
            maxWidth: 130,
            minWidth: 130,
            field: "user",
            headerName: "Registrado por",
            renderCell: ({row}) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}`
        },
        {
            maxWidth: 150,
            minWidth: 150,
            field: "provider",
            headerName: "Proveedor",
            renderCell: ({row}) => `${(row?.provider?.name??"")}`
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "invoiceNumber",
            headerName: "#FAC",
            renderCell: ({row}) => `${(row?.invoiceNumber??"")}`
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "consecutive",
            headerName: "#C",
            renderCell: ({row}) => `${(row?.consecutive??"")}`,
        },
        {
            maxWidth: 160,
            minWidth: 160,
            field: "billingDate",
            headerName: "Fecha de facturación",
            renderCell: ({row}) => getDateFormats((row?.billingDate??"")).ddmmyyyy,
        },
        {
            maxWidth: 130,
            minWidth: 130,
            field: "whoReceives",
            headerName: "Recibido por"
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "freight",
            headerName: "Flete",
            renderCell: ({row}) => currencyFormat((row?.freight??0)),
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "total",
            headerName: "Total",
            renderCell: ({row}) => currencyFormat((row?.total??0)),
        },
        {
            maxWidth: 50,
            minWidth: 50,
            field: " ",
            headerName: " ",
            renderCell: ({row}) => <Buttons {...row} />,
        },
    ]

    return(
        <>
            <Dialog maxWidth="md" open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} fullWidth >
                <DialogContent>
                    <SuperDataGrid
                        title={`Mercancía transportada por ${(merchandiseTransported?.name??"")}`}
                        columns={columns}
                        loading={isFetching}
                        rows={(merchandiseTransported?.purchases??[])}
                        filterBy={(param) => `${(param?.provider?.name??"")} ${(param?.user?.name??"")} ${(param?.user?.lastName??"")} ${(param?.whoReceives??"")} ${(param?.invoiceNumber??"")} ${(param?.consecutive??"")} ${(param?.total??"")} ${(param?.freight??"")}`}
                    />
                </DialogContent>
            </Dialog>
            <Invoice
                open={Boolean(showWindows.showPurchaseInvoice)}
                onClose={() => setShowWindows(prevState => ({...prevState, showPurchaseInvoice: ""}))}
                purchaseId={showWindows.showPurchaseInvoice}
            />
        </>
    )
}

export default TransportedMerchandise