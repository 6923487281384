import { Typography } from "@component/UIComponents"

const Title = ({ title }) => {
    return(
        <>
            <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} sx={{ margin: 0, pt: 1 }} >
                {title}
            </Typography>
        </>
    )
}

export default Title