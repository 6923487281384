import ActionsList from "@component/ActionsList"
import { useCashierMenu } from "../hooks/useCashierPOS"
import { CurrencyExchangeRoundedIcon } from "@component/UIComponents"
import { currencyFormat } from "@util/currencyFormat"

const CashierActions = ({ variantListButton="", refUser=null, balance=0 }) => {
    const { cashShares } = useCashierMenu()
    return(
        <>
            <ActionsList
                title={`USAR DINERO - ${currencyFormat(balance)}`}
                variant={variantListButton}
                options={cashShares({variantListButton, fixedBox: (refUser??null), boxBalance: balance })}
                componentProps={{
                    menuMobile: {
                        props: {
                            icon: <CurrencyExchangeRoundedIcon sx={{ color: "#FFFFFF", fontSize: ".875rem" }} />,
                        }
                    }
                }}
            />
        </>
    )
}

export default CashierActions