import { api } from "../splitApi";
import { NotificationsTag } from "../tags";

const Endpoints = api.injectEndpoints({
    endpoints: (build) => ({
        // registerAdvanceToSupplier: build.mutation({
        //     query(body) {
        //         return { ...mutationConfig, url: "/register/advance/to/supplier", body };
        //     },
        //     invalidatesTags: ProviderTag
        // }),
        getNotifications: build.query({
            query: (params=undefined) => ({
                url: `/get/notifications`,
                params
            }),
            providesTags: NotificationsTag
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetNotificationsQuery,
} = Endpoints;