import Form from "@component/Form"
import PatternFormat from "@component/PatternFormat"
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from "@component/UIComponents"
import { useEmployeesFormData, useGetEmployee, useRegisterEmployee } from "../hooks/useEmployees"
import { useCallback, useEffect } from "react"

const EmployeeForm = ({ open=false, onClose=()=>null, employeeId=null }) => {
    const { employee } = useGetEmployee({ employeeId }, {skip: !Boolean(employeeId)})
    const { values, setFieldValue, validateValues, resetValues } = useEmployeesFormData({ defaultValues: employee })
    const { name="", lastName="", email="", numberPhone="" } = values
    const { registerEmployee, isLoading } = useRegisterEmployee()

    const resetForm = useCallback(() => {
        if( !open ) resetValues()
    }, [open, resetValues])

    const handleSubmit = () => {
        const { type } = validateValues(values)
        if(type === "errors") return
        registerEmployee(values, {
            closeForm: () => {
                resetValues()
                onClose()
            }
        })
    }

    useEffect(()=>{
        resetForm()
    },[resetForm])

    return(
        <>
        <Dialog maxWidth="xs" fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} open={open} onClose={onClose} >
            <Form onSubmit={handleSubmit} >
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <TextField
                                value={name}
                                label="Nombres"
                                fullWidth
                                onChange={({target}) => setFieldValue("name", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                value={lastName}
                                label="Apellidos"
                                fullWidth
                                onChange={({target}) => setFieldValue("lastName", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <TextField
                                type="email"
                                value={email}
                                label="Correo electrónico"
                                fullWidth
                                onChange={({target}) => setFieldValue("email", (target?.value??""))}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <PatternFormat
                                value={numberPhone}
                                label="Numero de teléfono"
                                format="+57 ### ### ####"
                                fullWidth
                                onChange={(value) => setFieldValue("numberPhone", (value??""))}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" loading={isLoading} >
                        Guardar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
        </>
    )
}

export default EmployeeForm