import AutocompleteCreable from "@component/AutocompleteCreable"
import { useGetHauliers } from "../hooks/useHauliers"

const HauliersFinder = ({ fullWidth=undefined, disabled=undefined, size=undefined, onChange=undefined, value=null, filters={} }) => {
    const { hauliers, isFetching } = useGetHauliers(filters)
    return(
        <>
            <AutocompleteCreable
                disabled={disabled}
                onChange={onChange}
                value={value}
                fullWidth={fullWidth}
                size={size}
                label="Transportista"
                options={hauliers}
                loading={isFetching}
            />
        </>
    )
}

export default HauliersFinder