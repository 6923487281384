import NumericFormat from '@component/NumericFormat'
import { Box, Grid,  } from '@component/UIComponents'
import CitiesFinder from '@feature/Business/components/CitiesFinder'
import BranchFinder from '@feature/Customers/components/BranchFinder'
import BusinessTypeFinder from '@feature/Customers/components/BusinessTypeFinder'
import CustomerFinder from '@feature/Customers/components/CustomerFinder'
import NeighborhoodFinder from '@feature/Customers/components/NeighborhoodFinder'
import ZoneFinder from '@feature/Customers/components/ZoneFinder'
import Autocomplete from '@component/Autocomplete'
import QuickAccessDates from '@component/QuickAccessDates'
import DateFilter from '@component/Date'
import { transformArrayIntoString } from '@util/helpers'
import { useSalesOptions } from '../hooks/useSalesOptions'
import { useCreditsOptions } from '@feature/Reports/Credits/hooks/useCreditsOptions'
import { useMemo } from 'react'

const Filters = ({ filters={}, setFiltersData=()=>null, variant="sale" }) => {
    const { typesOfCredit:typesOfCreditList } = useCreditsOptions()
    const { invoiceTypeList, groupSalesBy, groupTracking } = useSalesOptions()
    const { groupSales=null, invoiceType=null, groupBy=null, branch="", businessType="", city="", customer="", endDate="", identificationNumber="", invoiceNumber="", neighborhood="", startDate="", typesOfCredit="", zone="", } = filters
    const transformArray = (value) => transformArrayIntoString(value, { mapFunction: (n) => (n?.name??""), separator: "," })
    const handleChangeDate = (n) => {
        if( Object.keys(n).length >= 1 ){
            Object.keys(n).forEach((key) => {
                setFiltersData(key, n[key])
            })
        }
    }
    const hideOptions = useMemo(() => {
        if( variant === "sale" ){
            return []
        }else if( variant === "credit" ){
            return ["sale", "price"]
        }else{
            return []
        }
    }, [variant])

    const validateObjectValue = ({ list=[], value=null }) => {
        const n = list.find((x) => ((x?.value??"") === (value?.value??"")))
        if( Boolean(n) ){
            return value
        }else{
            return null
        }
    }

    return(
        <>
            <Box>
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <QuickAccessDates
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleChangeDate}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <DateFilter
                            size='small'
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleChangeDate}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <NumericFormat
                            size="small"
                            value={invoiceNumber}
                            placeholder="Numero de factura"
                            fullWidth
                            prefix="#FAC "
                            thousandSeparator=""
                            onChange={({ floatValue }) => setFiltersData("invoiceNumber", floatValue)}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <NumericFormat
                            size="small"
                            value={identificationNumber}
                            placeholder="N.I. Cliente"
                            fullWidth
                            prefix="#N.I: "
                            thousandSeparator=""
                            onChange={({ floatValue }) => setFiltersData("identificationNumber", floatValue)}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <CustomerFinder
                            size="small"
                            multiple
                            queryValue={customer}
                            onChange={(_, value) => setFiltersData("customer", transformArray(value))}
                        />
                    </Grid>

                    <Grid xs={6} >
                        <BranchFinder
                            size={"small"}
                            multiple
                            queryValue={branch}
                            onChange={(_, value) => setFiltersData("branch", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <BusinessTypeFinder
                            size={"small"}
                            multiple
                            queryValue={businessType}
                            onChange={(_, value) => setFiltersData("businessType", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <NeighborhoodFinder
                            size="small"
                            multiple
                            queryValue={neighborhood}
                            onChange={(_, value) => setFiltersData("neighborhood", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={6} >
                        <ZoneFinder
                            size="small"
                            multiple
                            queryValue={zone}
                            onChange={(_, value) => setFiltersData("zone", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <CitiesFinder
                            size="small"
                            multiple
                            queryValue={city}
                            onChange={(_, value) => setFiltersData("city", transformArray(value))}
                        />
                    </Grid>
                    <Grid xs={12} >
                        <Autocomplete
                            size="small"
                            value={validateObjectValue({ list: invoiceTypeList({ hideOptions }), value: invoiceType })}
                            label="Tipo de factura"
                            options={invoiceTypeList({ hideOptions })}
                            onChange={(_, value) => setFiltersData("invoiceType", (value?.value??""))}
                        />
                    </Grid>
                    {(variant === "credit") &&
                        <Grid xs={12} >
                            <Autocomplete
                                size="small"
                                multiple
                                queryValue={typesOfCredit}
                                label="Tipo de crédito"
                                options={typesOfCreditList()}
                                onChange={(_, value) => setFiltersData("typesOfCredit", transformArray(value??[]))}
                            />
                        </Grid>
                    }
                    <Grid xs={12} >
                        <Autocomplete
                            size="small"
                            value={groupBy}
                            label="Agrupar por"
                            options={groupSalesBy()}
                            onChange={(_, value) => setFiltersData("groupBy", (value?.value??""))}
                        />
                    </Grid>
                    {(variant === "sale") &&
                        <Grid xs={12} >
                            <Autocomplete
                                size="small"
                                value={groupSales}
                                label="Agrupar ventas"
                                options={groupTracking()}
                                onChange={(_, value) => setFiltersData("groupSales", (value?.value??""))}
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}
export default Filters