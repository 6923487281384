import ActionsList from "@component/ActionsList"
import SkeletonInvoiceActionList from "./SkeletonInvoiceActionList"
import { ReceiptLongRoundedIcon } from "@component/UIComponents"
import { useInvoiceMenu } from "../hooks/useInvoice"
import SalePaymentWindow from "@feature/Sales/components/SalePaymentWindow"
import { useState } from "react"
import UpdateCreditExpirationDate from "./UpdateCreditExpirationDate"
import PartialCreditNote from "./PartialCreditNote"
import useControllerCodeToShareSalesInvoice from "./CodeToShareSalesInvoice/hooks/useControllerCodeToShareSalesInvoice"
import CustomerForm from "@feature/Customers/CustomerForm"
import FormAddDeliveryInformation from "@feature/Deliveries/components/FormAddDeliveryInformation"

const InvoiceActions = ({ loading, invoice=null, invoiceRef=null, blockPreviousInvoices=false, saleRef="" }) => {
    const { validateCustomerBeforeSharingInvoice, closeCustomerEditingForm, updateCustomer } = useControllerCodeToShareSalesInvoice()
    const { invoiceMenu } = useInvoiceMenu({ invoice, invoiceRef })
    const [selectedInvoiceToUpdateInformation, setSelectedInvoiceToUpdateInformation] = useState(null)
    const [invoiceSelectedToAddPayment, setInvoiceSelectedToAddPayment] = useState(null)
    const [invoiceSelectedForPartialCreditNoteRegistration, setInvoiceSelectedForPartialCreditNoteRegistration] = useState(null)
    const [ showWindowToUpdateExpirationDate, setShowWindowToUpdateExpirationDate ] = useState(false)
    const [ showAddDeliveryForm, setShowAddDeliveryForm ] = useState(null)

    return(
        <>
            { (!loading) ?
                <ActionsList
                    mobileMenuButtonText='Opciones de la factura'
                    mobileMenuIconButton={<ReceiptLongRoundedIcon />}
                    mobileMenuVariant='button'
                    variant='mobileMenu'
                    options={invoiceMenu(invoice, {
                        editInvoiceButtonProps: { disabled: blockPreviousInvoices },
                        addPaymentButtonProps: {
                            disabled: (((invoice?.balance??0) === 0) || blockPreviousInvoices)
                        },
                        onAddPaymentInvoice: (invoiceId) => setInvoiceSelectedToAddPayment(invoiceId),
                        onUpdateInvoiceInformation: (invoiceId) => setSelectedInvoiceToUpdateInformation(invoiceId),
                        onShareSalesInvoice: () => validateCustomerBeforeSharingInvoice({ customerId: (invoice?.customer?._id??null), saleRef }),
                        onUpdateExpirationDate: () => setShowWindowToUpdateExpirationDate(true),
                        onPartialCreditNote: () => setInvoiceSelectedForPartialCreditNoteRegistration(invoice),
                        onSendDelivery: (invoiceId) => setShowAddDeliveryForm(invoiceId)
                    })}
                />
                :
                <SkeletonInvoiceActionList />
            }
            <SalePaymentWindow
                balanceValue={(invoice?.balance??0)}
                updateInvoiceInformation
                openProductInformationWindow={Boolean(selectedInvoiceToUpdateInformation)}
                addNewPayment
                omitPayments={["credit"]}
                open={Boolean(invoiceSelectedToAddPayment)}
                onClose={()=> setInvoiceSelectedToAddPayment(null) }
                onCloseInvoiceInformation={()=>setSelectedInvoiceToUpdateInformation(null)}
                invoiceId={invoiceSelectedToAddPayment || selectedInvoiceToUpdateInformation}
            />
            <UpdateCreditExpirationDate dueDate={(invoice?.creditExpirationDate??"")} saleRef={saleRef} open={showWindowToUpdateExpirationDate} onClose={() => setShowWindowToUpdateExpirationDate(false)} />
            <PartialCreditNote invoice={invoiceSelectedForPartialCreditNoteRegistration} products={(invoice?.products??[])} open={Boolean(invoiceSelectedForPartialCreditNoteRegistration)} onClose={() => setInvoiceSelectedForPartialCreditNoteRegistration(null)} />
            <CustomerForm open={Boolean(updateCustomer?._id)} onClose={closeCustomerEditingForm} defaultValues={updateCustomer} actionWhenClosingFormWithSuccessfulRegistration={() => setTimeout(() => validateCustomerBeforeSharingInvoice({ customerId: (invoice?.customer?._id??null) }), 100) } />
            <FormAddDeliveryInformation invoiceRef={showAddDeliveryForm} open={Boolean(showAddDeliveryForm)} onClose={() => setShowAddDeliveryForm(null)} />
        </>
    )
}

export default InvoiceActions