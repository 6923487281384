import LoadProgress from '@component/LoadProgress'
import { useNewUserAndBusinessRegistration } from '../hooks/useNewUserAndBusinessRegistration'
import { useCallback, useEffect } from 'react'
import { Box, Button, Grid } from '@component/UIComponents'
import { useNavigate } from 'react-router-dom'
import { routes } from '@util/routes'
import { getAppDomain, signOff } from '@util/helpers'
const { login, sales, inventory } = routes

const EndingRegistration = ({ payload=null, onGoBack=()=>null, clearData=()=>null }) => {
    const navigate = useNavigate()
    const { userName, newUserAndBusinessRegistration, isError } = useNewUserAndBusinessRegistration()

    const registerData = useCallback(() => {
        if( Boolean(payload?.user) && Boolean(payload?.business) && !Boolean(userName) ){
            newUserAndBusinessRegistration(payload, {
                clearForm: () => clearData()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload])

    useEffect(() => {
        registerData()
    }, [registerData])

    const GoBackButton = () => {
        if(isError){
            return (
                <Grid container >
                    <Grid xs={12} >
                        <Button onClick={onGoBack} > Regresar al formulario </Button>
                    </Grid>
                </Grid>
            )
        }else{
            return <></>
        }
    }

    const clearSession = useCallback(() => {
        navigate(login())
        setTimeout(() => {
            signOff()
        }, 50)
    }, [navigate])

    const forceNavigate = (path) => {
        const domain = getAppDomain()
        window.location.href = `${domain}${path}`
    }

    if( Boolean(userName) ){
        return(
            <>
                <Box fontWeight={'bold'} lineHeight={1} mb={3} >
                    Bienvenido <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {userName} </Box>, selecciona la acción con la que deseas comenzar.
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box>
                        <Button size='large' fullWidth variant='contained' disableElevation onClick={() => forceNavigate(sales({ saleId: "" }))} >
                            Realizar una venta
                        </Button>
                    </Box>
                    <Box>
                        <Button size='large' fullWidth variant='outlined' disableElevation onClick={() => forceNavigate(inventory("products"))} >
                            Crear un producto
                        </Button>
                    </Box>
                    <Box>
                        <Button size='large' fullWidth variant='text' disableElevation onClick={() => clearSession()} >
                            Volver al login
                        </Button>
                    </Box>
                </Box>
            </>
        )
    }else{
        return(
            <>
                <LoadProgress
                    text='Finalizando el registro...'
                />
                <GoBackButton />
            </>
        )
    }
}

export default EndingRegistration