import { useUpdateBusinessInformationMutation } from "@query/Business"
import { asynchronousResponseHandler } from "@util/helpers"

export const useUpdateBusinessInformation = () => {
    const [updateBusinessInformationMutation, { isLoading }] = useUpdateBusinessInformationMutation()

    const updateBusinessInformation = async (payload, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await updateBusinessInformationMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean((payload?._id)) ? "La información del negocio se actualizo exitosamente." : "El negocio se registro exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        updateBusinessInformation,
        isLoading
    }
}