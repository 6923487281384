import { formErrorList } from "@hook/useFormTools"
import { yup } from "@hook/useFormik"
import { useURLParams } from "@hook/useURLParams"
import { useArchivedProviderMutation, useGetProviderAdvanceHistoryQuery, useGetProviderQuery, useGetProvidersQuery, useRegisterAdvanceToSupplierMutation } from "@query/Providers"
import { asynchronousResponseHandler } from "@util/helpers"
import { useState } from "react"

export const useGetProvidersFilters = () => {
    const { getQueries } = useURLParams()
    const { showOnly=undefined } = getQueries(['showOnly'])
    return {
        showOnly,
        archived: showOnly === "archived" ? "true" : undefined
    }
}

export const useGetProviders = (params={}) => {
    const { data, isFetching } = useGetProvidersQuery(params)
    const { showOnly } = useGetProvidersFilters()
    let providers = (data?.payload??[])

    if( showOnly === "inAdvance" ) providers = providers.filter((n) => (n?.balance??0) >= 1)
    if( showOnly === "withoutAdvance" ) providers = providers.filter((n) => (n?.balance??0) <= 0)
    if( showOnly === "withRetention" ) providers = providers.filter((n) => (n?.retefuente??false) === true)
    if( showOnly === "notApplyRetention" ) providers = providers.filter((n) => (n?.retefuente??false) === false)

    return {
        providers,
        isFetching
    }
}

export const useGetProviderAdvanceHistory = (params={}, config={}) => {
    const { data, isFetching } = useGetProviderAdvanceHistoryQuery(params, config)
    let advances = (data?.payload??[])

    return {
        advances,
        isFetching
    }
}

export const useGetProvider = (params={}, config={}) => {
    const { data, isFetching } = useGetProviderQuery(params, config)
    let provider = (data?.payload??{})
    return {
        provider,
        isFetching
    }
}

export const useRegisterAdvanceToSupplier = () => {
    const [registerAdvanceToSupplierMutation, { isLoading }] = useRegisterAdvanceToSupplierMutation()

    const registerAdvanceToSupplier = async (payload={}, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, elementFocus=()=>null } = callback
                const resp = await registerAdvanceToSupplierMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: "El anticipo se registro satisfactoriamente.",
                    clearForm,
                    closeForm,
                    callback: () => {
                        elementFocus()
                    },
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerAdvanceToSupplier,
        isLoading
    }
}

export const useArchivedProvider = () => {
    const [ archivedProviderMutation, {isLoading} ] = useArchivedProviderMutation()
    const archivedProvider = async (payload=null, callback={}) => {
        try {
            if( (isLoading === false) ){
                const { clearForm=()=>null, closeForm=()=>null, } = callback
                const resp = await archivedProviderMutation(payload).unwrap()
                asynchronousResponseHandler(resp, {
                    successMessage: Boolean(resp?.payload?.isArchived??false) ?
                        "El proveedor se archivo exitosamente."
                        :
                        "El proveedor se recupero exitosamente.",
                    clearForm,
                    closeForm,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        archivedProvider,
        isLoading
    }
}

export const useGenerateAdvanceFormData = () => {
    const schema = yup.object().shape({
        providerId: yup.string().required("Ocurrió un problema al cargar el proveedor, cierre la ventana he intente nuevamente."),
        concept: yup.string().oneOf(['advanceProvider', 'returnedToBox'], 'Ocurrió un problema al definir el concepto, cierre la ventana he intente nuevamente.'),
    })
    let defaultValues = {
        providerId: "",
        concept: "advanceProvider",
        payments: [],
    }
    const [values, setValue] = useState({...defaultValues})

    const resetValues = () => setValue({...defaultValues})

    const validateValues = (params={}) => {
        try {
            schema.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            console.log(error)
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }

    const setFieldValue = (field, value) => setValue((prevState) => ({ ...prevState, [field]: value }))

    return { values, setFieldValue, resetValues, validateValues }
}
