import { Box, Card } from '@component/UIComponents'
import { useGetCreditsFromPreviousDays } from "@feature/Reports/Sales/hooks/useSales"
import { currencyFormat } from '@util/currencyFormat'

const CreditsFromPreviousDays = () => {
    const { total, invoicesFound } = useGetCreditsFromPreviousDays()
    return(
        <>
            <Card elevation={0} variant='outlined' sx={{ padding: 2, height: 110 }} >
                <Box lineHeight={1.2} fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} >
                    Abonos a créditos de días anteriores :
                    <Box fontSize={'.775rem'} >
                        { invoicesFound <= 0 && `No hay abonos registrados`}
                        { invoicesFound === 1 && `${invoicesFound} abono registrado`}
                        { invoicesFound >= 2 && `${invoicesFound} abonos registrados`}
                    </Box>
                </Box>
                <Box fontSize={'1.3rem'} fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }} >
                    {currencyFormat(total)}
                </Box>
            </Card>
        </>
    )
}

export default CreditsFromPreviousDays