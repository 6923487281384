import { Box, Button, Chip, DataGrid, Dialog, DialogActions, DialogContent, Grid, Typography, styled } from '@component/UIComponents'
import { useCreditNoteOptions } from './hooks/useCreditNoteOptions'
import ActionsList from '@component/ActionsList'
import ProductPreSelection from '@feature/Sales/components/ProductPreSelection'
import { useState } from 'react'
import { currencyFormat } from '@util/currencyFormat'
import { useCreditNoteController } from './hooks/useCreditNoteController'
import DetailsWindow from '@component/DetailsWindow'
import Form from '@component/Form'

const DataGridStyled = styled(DataGrid)(() => ({
    '& .MuiDataGrid-columnHeaders': {
        display: "none"
    },
    '& .MuiDataGrid-root': {
        border: "none"
    },
    '& .MuiDataGrid-cell': {
        border: 'none',
    },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-row:hover .MuiDataGrid-cell:focus': {
        outline: 'none',
        border: 'none',
    },
}))

const PartialCreditNote = ({ open=false, onClose=()=>null, invoice=null, products=[] }) => {
    const { affectedProducts, addAffectedProductToTheList, getColorIdentity, deleteProductFromTheInvoice, getRemovedProduct, removeAffectedProduct, creditNoteDispatchHandler, dialogs, dialogWindowManager, recordCreditNote } = useCreditNoteController()
    const [selectedProduct, setSelectedProduct] = useState(null)
    const { primaryMenu, secondaryMenu } = useCreditNoteOptions()
    const columns1 = [
        {
            flex: 1,
            minWidth: 200,
            field: "name",
            headerName: "",
            renderCell: ({ row }) => {
                return(
                    <>
                        <Box display={'flex'} flexDirection={'column'} lineHeight={1} width={'100%'} bgcolor={getColorIdentity((row?.productId??""))} p={1} >
                            <Box fontWeight={'bold'} >
                                {(row?.name??"")}
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} >
                                <Box display={'flex'} gap={1} >
                                    <Box>
                                        x{(row?.quantity??0)}
                                    </Box>
                                    <Box>
                                        {currencyFormat((row?.salePrice??0))}
                                    </Box>
                                </Box>
                                <Box>
                                    {currencyFormat((row?.quantity??0)*(row?.salePrice??0))}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )
            }
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "action",
            renderCell: ({ row }) => {
                return(
                    <>
                        { Boolean(getRemovedProduct((row?.productId??""))) ?
                            <Chip
                                label="Removido"
                                size='small'
                                color='error'
                                variant='outlined'
                            />
                            :
                            <>
                                <ActionsList variant='iconButton' options={primaryMenu({
                                    onEdit: () => setSelectedProduct(row),
                                    onDelete: () => deleteProductFromTheInvoice(row)
                                })} />
                            </>
                        }
                    </>
                )
            }
        }
    ]
    const columns2 = [
        {
            flex: 1,
            minWidth: 200,
            field: "name",
            headerName: "",
            renderCell: ({ row }) => {
                return(
                    <>
                        <Box display={'flex'} flexDirection={'column'} lineHeight={1} width={'100%'} bgcolor={getColorIdentity((row?.productId??""))} p={1} >
                            <Box fontWeight={'bold'} >
                                {(row?.name??"")}
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} >
                                <Box display={'flex'} gap={1} >
                                    <Box>
                                        x{(row?.quantity??0)}
                                    </Box>
                                    <Box>
                                        {currencyFormat((row?.salePrice??0))}
                                    </Box>
                                </Box>
                                <Box>
                                    {currencyFormat((row?.quantity??0)*(row?.salePrice??0))}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )
            }
        },
        {
            maxWidth: 50,
            minWidth: 50,
            field: "action",
            renderCell: ({ row }) => {
                return(
                    <>
                        <ActionsList variant='iconButton' options={secondaryMenu({
                            onDelete: () => removeAffectedProduct((row?.productId??""))
                        })} />
                    </>
                )
            }
        }
    ]
    const title = (title="") => <Typography fontWeight={'bold'} textTransform={'uppercase'} > {title} </Typography>

    return(
        <>
            <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose} PaperProps={{ elevation: 0 }} transitionDuration={0} >
                <Form onSubmit={() => creditNoteDispatchHandler(products)} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                {title("Lista de productos:")}
                                <DataGridStyled
                                    hideFooter
                                    getRowId={(param) => (param?._id??"") }
                                    columns={columns1}
                                    rows={products}
                                    rowHeight={40}
                                />
                            </Grid>
                            <Grid xs={6} >
                                {title("Productos afectados:")}
                                <DataGridStyled
                                    hideFooter
                                    getRowId={(param) => (param?.productId??"") }
                                    columns={columns2}
                                    rows={affectedProducts}
                                    rowHeight={40}
                                    localeText={{
                                        noRowsLabel: "No hay productos afectados"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' >
                            Generar nota crédito parcial
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
            <ProductPreSelection
                variant='creditNote'
                value={selectedProduct}
                getProduct={(selectedProduct?.productId??null)}
                onClose={() => setSelectedProduct(null)}
                maxQuantity={(selectedProduct?.quantity??0)}
                onChange={(val) => {
                    addAffectedProductToTheList(val)
                    setSelectedProduct(null)
                }}
            />
            <DetailsWindow
                open={dialogs.displayModalToAddADescriptionToTheCreditNote}
                title='Detalles de la nota crédito'
                onClose={() => dialogWindowManager("displayModalToAddADescriptionToTheCreditNote", false)}
                onSubmit={(val) => recordCreditNote({ invoiceId: (invoice?._id??null), allProducts: products, description: val, invoice })}
            />
        </>
    )
}

export default PartialCreditNote