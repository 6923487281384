import { createCookie, getCookie } from "@util/helpers"
import { Toast } from "@util/swal"
import dayjs from "dayjs"
import { useMemo, useCallback } from "react"

export const useAutomaticSavingPurchaseInvoices = () => {
    const storedPurchasesCookie = getCookie("storedPurchases", { convertJSONstringify: true })

    const storedPurchases = useMemo(() => {
        if( Boolean(storedPurchasesCookie) && Array.isArray(storedPurchasesCookie) && storedPurchasesCookie.length >= 1 ){
            let r = [...storedPurchasesCookie]
            return r
        }else{
            return []
        }
    }, [storedPurchasesCookie])

    const temporarilySavePurchase = useCallback((billInformation, props={}) => {
        const { clearForm=()=>null } = props
        const { products=[], provider=null } = (billInformation??{})

        if( Boolean(provider?._id) && products.length >= 1 ){
            const storedPurchasesLength = (storedPurchases.length)
            storedPurchases.push({
                _id: (storedPurchases.length+1),
                value: billInformation,
                createdAt: dayjs().toDate(),
                providerName: (provider?.name??"")
            })
            createCookie({ name: "storedPurchases", value: storedPurchases, convertJSONstringify: true })
            const val = getCookie("storedPurchases", { convertJSONstringify: true })
            if( (val?.length??0) ===  (storedPurchasesLength+1) ){
                Toast.fire({
                    icon: "success",
                    text: "Se almaceno temporalmente la compra con éxito.",
                })
                clearForm()
            }
        }
    }, [storedPurchases])

    const retrieveTemporarilyStoredPurchase = useCallback((index) => {
        let currentStoredPurchases = getCookie("storedPurchases", { convertJSONstringify: true })
        currentStoredPurchases = Boolean(currentStoredPurchases) && Array.isArray(currentStoredPurchases) && (currentStoredPurchases.length >= 1) ? currentStoredPurchases : []
        const selectedPurchase = currentStoredPurchases[index]
        currentStoredPurchases.splice(index, 1)
        createCookie({ name: "storedPurchases", value: currentStoredPurchases, convertJSONstringify: true })
        return (selectedPurchase?.value??null)
    }, [])

    return {
        storedPurchases,
        temporarilySavePurchase,
        retrieveTemporarilyStoredPurchase
    }
}