import { Box, DataGrid, Dialog, DialogContent, Grid, IconButton, PostAddRoundedIcon, Tooltip, styled } from '@component/UIComponents'
import PurchaseFinder from '../Purchases/components/PurchaseFinder'
import { usePaymentSchedule } from './hooks/usePaymentSchedule'
import PurchaseInformationRow from './components/PurchaseInformationRow'
import SupplierInformationRow from './components/SupplierInformationRow'
import ScheduleTotalRow from './components/ScheduleTotalRow'
import PurchaseSchedulingFilter from './components/PurchaseSchedulingFilter'
import ActionsList from '@component/ActionsList'
import { usePaymentScheduleMenu } from './hooks/usePaymentScheduleMenu'
import { useRef } from 'react'
import ProgrammingTotal from './components/ProgrammingTotal'
import { useEffect } from 'react'


const DataGridStyled = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
        display: "none"
    },
    '& .MuiDataGrid-cell--withRenderer': {
        fontSize: '.875rem'
    }
}))

const PaymentSchedule = ({ purchaseFilters={}, open=false, onClose=()=>null }) => {
    const { addNewPurchase, purchases, rows, addAllPurchases, setPurchases } = usePaymentSchedule()
    const programmingRef = useRef(null)
    const { secondaryMenu } = usePaymentScheduleMenu({ programmingRef })
    
    const columns = [
        {
            flex: 1,
            field: "providerName",
            renderCell: ({ row }) => {
                if( Boolean(row?.providerName) && (row?.name??"") !== "totalizeSupplierProgramming" ){
                    return <SupplierInformationRow {...row} />
                }else if( !Boolean(row?.providerName) && ((row?.name??"") !== "totalizeSupplierProgramming") && ((row?.name??"") !== "totalPaymentSchedule") ){
                    return <PurchaseInformationRow {...row} />
                }else if( !Boolean(row?.providerName) && (row?.name??"") === "totalizeSupplierProgramming" ){
                    return <ScheduleTotalRow {...row} />
                }else if( !Boolean(row?.providerName) && ((row?.name??"") === "totalPaymentSchedule") ){
                    return <ProgrammingTotal {...row} />
                }
            }
        }
    ]
    useEffect(() => {
        if( !open ){
            setPurchases([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    return(
        <>
            <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth transitionDuration={0} PaperProps={{ elevation: 0 }} >
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid xs={5} sx={{ display: "flex", alignItems: "center" }} >
                            <PurchaseSchedulingFilter />
                        </Grid>
                        <Grid xs={7} >
                            <ActionsList
                                options={secondaryMenu()}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <PurchaseFinder
                                filters={purchaseFilters}
                                onChange={(_, p) => addNewPurchase(p)}
                                ignoreOptions={purchases.map((n) => n._id)}
                                onTheRight={(ls) => {
                                    return(
                                        <Box>
                                            <Tooltip title="Añadir todas las compras de la lista" >
                                                <IconButton color='primary' onClick={() => addAllPurchases(ls)} >
                                                    <PostAddRoundedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid xs={6} >
                        </Grid>

                        <Grid xs={12} >
                            <Box maxHeight={'65vh'} overflow={'scroll'} >
                                <Box ref={programmingRef} p={2} >
                                    <Box border={2} p={1} >
                                        <DataGridStyled
                                            localeText={{
                                                noRowsLabel: "No hay selección para la programación."
                                            }}
                                            rowHeight={30}
                                            hideFooter
                                            autoHeight
                                            getRowId={(param) => (param?._id??"")}
                                            columns={columns}
                                            rows={rows}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default PaymentSchedule