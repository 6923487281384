import { useGetCloseOfDayQuery } from "@query/Business"

export const useGetCloseOfDay = (filters={}, config={}) => {
    const { data, isFetching } = useGetCloseOfDayQuery(filters, config)
    const closeOfDay = (data?.payload??null)

    return {
        closeOfDay,
        isFetching,
    }
}