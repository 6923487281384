import { Box, Typography } from '@component/UIComponents'
import SingleDataGrid from "@component/SingleDataGrid"
import { usePanel } from "@hook/usePanel"
import { currencyFormat } from "@util/currencyFormat"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useGetCustomerAdvanceHistory } from '@feature/Customers/hooks/useCustomers'

const AdvancePaymentHistory = ({_id, name}) => {
    const { advances, isFetching } = useGetCustomerAdvanceHistory({ customerId: _id, limit: 5 }, {skip: !Boolean(_id)})
    const { t } = useTranslation()
    const { getDateFormats } = usePanel()
    const columns = useMemo(() => {
        return [
            {minWidth: 150, maxWidth: 150, field: "user", headerName: "Registrado por", renderCell: ({ row }) => `${(row?.user?.name??"")} ${(row?.user?.lastName??"")}`},
            {minWidth: 150, maxWidth: 150, field: "box", headerName: "Desde", renderCell: ({row}) => `${ (row?.concept??"") === "returnedToBox" ? t((row?.box??"")) : name }`},
            {minWidth: 150, maxWidth: 150, field: "concept", headerName: "Para", renderCell: ({ row }) => `${ (row?.concept??"") === "advanceCustomer" ? t((row?.box??"")) : name }`},
            {minWidth: 160, maxWidth: 160, field: "amount", headerName: "Monto", renderCell: ({row}) => {
                return(
                    <Box component={'span'} sx={{ color: (theme) => (row?.concept??"") === "advanceCustomer" ? theme.palette.success.main : theme.palette.error.main }} >
                        {`${currencyFormat(Math.abs((row?.amount??0)))}`}
                    </Box>
                )
            }},
            {minWidth: 160, maxWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({row}) => `${getDateFormats((row?.createdAt??"")).ddmmyyyyhhmm}`},
        ]
    }, [getDateFormats, t, name])

    return(
        <>
            <Box>
                <Typography p={1} fontWeight={'bold'} fontSize={'.875rem'} > Historial de anticipos de {(name??"")} </Typography>
                <SingleDataGrid
                    localeText={{
                        noRowsLabel: "No hay anticipos para mostrar"
                    }}
                    columns={columns}
                    rows={advances}
                    isFetching={isFetching}
                />
            </Box>
        </>
    )
}

export default AdvancePaymentHistory