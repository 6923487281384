import { useCallback, useEffect, useMemo, useState } from "react"
import { routes } from '@util/routes'
import { useLocation, useNavigate } from "react-router-dom"
import { useURLParams } from "./useURLParams"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Swal, Toast } from "@util/swal"
import { yup } from "./useFormik"
import { formErrorList } from "./useFormTools"
const { login, singIn } = routes

export const usePanel = (props={}) => {
    const { appTitle="" } = props
    const { t, i18n } = useTranslation()
    const { getQuery, addQuery, getAllQueries } = useURLParams()
    const sd = (getQuery("sd")??null)
    const ed = (getQuery("ed")??null)
    const userName = (name="", lastName="") => {
        let currentName = name
        let currentLastName = lastName

        return {
            fullName: `${currentName} ${currentLastName}` ,
            firstName: (currentName.split(" ")[0]??""),
            secondName: (currentName.split(" ")[1]??""),
            firstLastName: (currentLastName.split(" ")[0]??""),
            secondLastName: (currentLastName.split(" ")[1]??""),
            firstNameAndLastName: `${(currentName.split(" ")[0]??"")} ${(currentLastName.split(" ")[0]??"")}`,
            secondNameAndLastName: `${(currentName.split(" ")[1]??"")} ${(currentLastName.split(" ")[1]??"")}`,
        }

    }

    const addDefaultStartDate = useCallback(() => {
        if( !Boolean(sd) && !Boolean(ed) ){
            addQuery({sd: dayjs().format("MM-DD-YYYY"), ed: dayjs().format("MM-DD-YYYY")})
        }
    }, [sd, ed, addQuery])

    const getStartDateAndEndDate = useMemo(() => {
        let startDate = null
        let endDate = null
        startDate = sd
        endDate = ed
        if(!Boolean(sd) && !Boolean(ed)){
            startDate = dayjs().format("MM-DD-YYYY")
            endDate = dayjs().format("MM-DD-YYYY")
        }
        return { startDate, endDate }
    }, [sd, ed])

    const getDateFormats = (date=undefined, config={}) => {

        return {
            mmddyyyy: dayjs(date, config).format("MM-DD-YYYY"),
            hhmm: dayjs(date, config).format("HH:mm A"),
            hhmmss: dayjs(date, config).format("HH:mm:ss A"),
            ddmmyyyy: dayjs(date, config).format("DD-MM-YYYY"),
            mmddyyyyhhmm: dayjs(date, config).format("MM-DD-YYYY HH:mm A"),
            ddmmyyyyhhmm: dayjs(date, config).format("DD-MM-YYYY HH:mm A"),
            mmddyyyyhhmmss: dayjs(date, config).format("MM-DD-YYYY HH:mm:ss A"),
            missingDays: dayjs(date, config).add(1, "day").startOf("day").diff(dayjs().startOf("day").toDate(), 'day'),
            isToday: (dayjs(date, config).format("DD-MM-YYYY") === dayjs().format("DD-MM-YYYY")),
            fullDate: ` ${t(`day_${dayjs(date, config).day()}`)} ${dayjs(date, config).format("DD")} de ${t(`month_${dayjs(date, config).month()}`)} del ${dayjs(date, config).format("YYYY")}`,

        }
    }

    const groupArrangement = useCallback(({ results=[], groupOuting=()=>"", firstExit=()=>({}), secondExit=()=>null }) => {
        let group = []
        for( let n of results ){
            const groupIndex = group.findIndex((b) => ((b?.groupName??"") === groupOuting(n) ) )
            if(groupIndex >= 0){
                secondExit(group, groupIndex, n)
            }else{
                group.push(firstExit(n))
            }
        }
        return group
    }, [])

    const getAdvancedFiltersOn = (arrangement=[]) => {
        let params = arrangement?.reduce((acc, param) => {
            let currentValue = {...acc}
            const value = getQuery(param)
            if( Boolean(value) ){
                currentValue = {...currentValue, [param]: value}
            }
            return currentValue
        },{})
        return params
    }

    const updateStatusWithoutRepeating = (currentState, newState, seState) => {
        if( JSON.stringify(currentState) !== JSON.stringify(newState) ){
            seState(newState)
        }
    }

    const callSwalFormikErrors = ({ errors, touched, type="toast" }) => {
        let touchedsKey = Object.keys(touched)
        let errorsKey = Object.keys(errors)
        let isError = errorsKey.map((n) => touchedsKey.some(b => b === n))

        if( isError.some((n) => n === true) ){
            for( let n of errorsKey ){
                if( type === "toast" ){
                    Toast.fire({
                        icon: "warning",
                        text: errors[n]
                    })
                }
                if( type === "swal" ){
                    Swal.fire({
                        icon: "warning",
                        text: errors[n]
                    })
                }
            }
        }
    }

    const groupArrayByOptionList = useCallback(({ availableGroups=[], groupBy="", list=[], groupAction=()=>null }) => {
        let resp = []
        const currentAvailableGroups = [...availableGroups]
        const groupIndex = currentAvailableGroups.findIndex((n) => n === groupBy)
        if( groupIndex >= 0 ){
            const group = currentAvailableGroups[groupIndex]
            resp = groupArrangement({
                results: list,
                groupOuting:(n) => groupAction(n, group),
                firstExit: (n) => ({
                    groupName: groupAction(n, group),
                    results: [n]
                }),
                secondExit: (group, exitIndex, n) => {
                    group[exitIndex].results.push(n)
                }
            })
            resp = resp.filter((n) => Boolean(n?.groupName))
        }
        return resp
    },[groupArrangement])

    const getTranslationListKey = useCallback((val="") => {
        const key = Object.keys(i18n.store.data[i18n.language].translation).find(
            (key) => t(key) === val
        );
        return Boolean(key) ? key : ""
    }, [i18n, t])

    const componentTitle = useCallback((defaultTitle="") => {
        const title = appTitle
        let currentTitle = document.title
        const decomposedTitle = currentTitle.split("-")
        const titleAlreadyExists = decomposedTitle.some((n) => n === ` ${title}`)
        if( Boolean(title) && !titleAlreadyExists ){
            currentTitle = `${currentTitle} - ${title}`
        }
        document.title = Boolean(defaultTitle) ? defaultTitle : currentTitle
    }, [appTitle])

    const getActiveFilters = useCallback(({ currentFilters=[] }) => {
        let resp = {}
        const allQueries = getAllQueries()
        currentFilters.forEach((key) => {
            const val = allQueries[key]
            if( Boolean(val) ){
                resp[key] = val
            }
        })
        return resp
    }, [getAllQueries])

    useEffect(() => {
        componentTitle()
        return () => {
            let currentTitle = document.title
            const decomposedTitle = currentTitle.split("-").map((n) => n.trim())
            const originalTitle = decomposedTitle[0]
            componentTitle(originalTitle)
        }
    }, [componentTitle])

    return {
        getActiveFilters,
        componentTitle,
        userName,
        addDefaultStartDate,
        getStartDateAndEndDate,
        getDateFormats,
        groupArrangement,
        getAdvancedFiltersOn,
        updateStatusWithoutRepeating,
        callSwalFormikErrors,
        groupArrayByOptionList,
        getTranslationListKey
    }
}

export const useAccessRedirect = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    let path = pathname.split("/")
    path = (path[1]??"")
    const redirectToHome = useMemo(() => (["login"]), [])

    const accessRedirect = useCallback(() => {
        const tk = localStorage.getItem("access_token")
        if( Boolean(tk) ){
            if( redirectToHome.some(n => (n === path)) ){
                const redirectByDefault = (localStorage.getItem("redirectByDefault")??"/")
                navigate(redirectByDefault)
            }
        }else{
            if( pathname !== login() && pathname !== singIn() ){
                navigate(login())
            }
        }
    }, [navigate, redirectToHome, path, pathname])

    return { accessRedirect }
}

export const useManageFormData = (params={ defaultValues: undefined, schema: undefined, initialValues: {}, defaultIdentifier: "_id", openModal: undefined }) => {
    const { defaultValues=undefined, schema=undefined, initialValues={}, defaultIdentifier="_id", openModal=undefined } = params
    const model = yup.object().shape(schema)

    let val = useMemo(() => ({...initialValues}), [initialValues])

    const [values, setValue] = useState({...val})

    const resetValues = useCallback(() => setValue({...val}), [val])

    const validateValues = useCallback((params={}) => {
        try {
            model.validateSync(params, { abortEarly: false })
            return {
                type: "OK",
                errors: []
            }
        } catch (error) {
            console.log(error)
            formErrorList((error?.errors??[]))
            return {
                type: "errors",
                errors: (error?.errors??[])
            }
        }
    }, [model])

    const setFieldValue = useCallback((field, value) => setValue((prevState) => ({ ...prevState, [field]: value })), [])

    const loadDefaultValues = useCallback(() => {
        if( Boolean((defaultValues?.[defaultIdentifier]??null)) || ((defaultValues?.new??null) === true) ){
            setValue(defaultValues)
        }
    }, [defaultValues, defaultIdentifier])

    const resetFormValue = useCallback(() => {
        if( openModal === false ){
            resetValues()
        }
    }, [openModal, resetValues])

    useEffect(() => {
        loadDefaultValues()
    }, [loadDefaultValues])

    useEffect(() => {
        resetFormValue()
    },[resetFormValue])

    return { values, setFieldValue, resetValues, validateValues }
}